import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Table,
  Media,
  Form,
  Card,
  Accordion,
  Alert,
} from "react-bootstrap";
import DatePicker from "react-datetime";
import moment from "moment";
import "react-datetime/css/react-datetime.css";

const masterPageTemplate = {
  name: "",
  description: "",
  showCode: "",
  isDefault: false,
  customerOrganizationID: 0,
  hasPDFTemplate: false,
};

function MasterTemplateSetup(props) {
  const [templates, setTemplates] = useState([]);
  const [editIDs, setEditIDs] = useState([]);
  const [newTemplate, setNewTemplate] = useState(masterPageTemplate);
  const editValues = useRef([]);

  const newNameRef = useRef();
  const newDescriptionRef = useRef();

  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [fileMessage, setFileMessage] = useState("Select a pdf file");
  const [uploadSuccess, setUploadSuccess] = useState("");
  const [successMessage, setSuccessMessage] = useState(null);
  const [errMsg, setErrMsg] = useState(null);
  const [uploadTemplateName, setUploadTemplateName] = useState(null);
  const uploadRef = useRef();

  const getTemplate = (e, name) => {
    fetch(global.config.settings.wsURL + "admin/GetMasterPDFTemplate", {
      method: "GET",
      headers: {
        accept: "*/*",
        authorization: props.user.token,
        showCode: props.showCode,
        name: name,
      },
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.blob();
      })
      .then((data) => {
        if (data) {
          const blob = new Blob([data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = name + ".pdf";
          link.click();
        } else {
          alert("No master template defined.");
        }
      });
  };

  const submitHandler = (e, name) => {
    e.preventDefault(); //prevent the form from submitting

    const formData = new FormData();

    formData.append("File", selectedFile);

    fetch(global.config.settings.wsURL + "admin/SetMasterTemplate", {
      method: "POST",
      headers: {
        authorization: props.user.token,
        showCode: props.showCode,
        templateName: name,
      },
      body: formData,
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (data.status === "success") {
            setUploadSuccess("Imported successfully.");
            setFileMessage("Select a pdf file");
            setSelectedFile(null);
            setFileMessage(null);
            setIsFilePicked(false);
            setUploadTemplateName("");
          }
          setFileMessage("Select a pdf file");
          getTemplates();
        }
      });
  };

  const changeHandler = (event) => {
    if (event.target.files[0]) {
      const name = event.target.files[0].name.toLowerCase();

      if (name.includes(".pdf") === false) {
        setSelectedFile(null);
        setFileMessage(null);
        setIsFilePicked(false);

        alert("Invalid file type.  Imported files must be in PDF format.");
        return;
      }
      setSelectedFile(event.target.files[0]);
      setFileMessage(event.target.files[0].name);
      setIsFilePicked(true);
    }
  };

  function getTemplates() {
    //Get sessions
    fetch(global.config.settings.wsURL + "admin/GetMasterTemplates", {
      method: "GET",
      headers: {
        accept: "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          setTemplates(data);
        }
      })
      .catch((error) => {
        props.setGlobalError(
          "There was a problem accessing the Internet. Please check your connection and try again."
        );

        console.error("Error:", error);
      });
  }

  function nameChange(e, templateName) {
    editValues.current.map((val) => {
      if (val.name === templateName) {
        val.name = e.target.value;
      }
    });
  }
  function descriptionChange(e, templateName) {
    editValues.current.map((val) => {
      if (val.name === templateName) {
        val.description = e.target.value;
      }
    });
  }
  function defaultChange(e, templateName) {
    editValues.current.map((val) => {
      if (val.name === templateName) {
        val.isDefault = e.target.checked;
      }
    });
  }

  function editTemplate(e, templateName) {
    e.preventDefault();
    let newArray = [...editIDs];
    newArray.push(templateName);

    setEditIDs(newArray);
    templates.map((template) => {
      if (template.name === templateName) {
        editValues.current.push(JSON.parse(JSON.stringify(template)));
      }
    });
  }
  function postTemplate(template) {
    if (template.name == null) {
      alert("Please enter a name for this session.");
      return;
    }
    if (template.description == null) {
      alert("Please enter a description for this session.");
      return;
    }

    fetch(global.config.settings.wsURL + "admin/UpdateMasterTemplate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },
      body: JSON.stringify(template),
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (data.status === "success") {
            setSuccessMessage(data.message);
            setErrMsg(null);
          } else {
            setErrMsg(data.message);
            setSuccessMessage(null);
          }
          getTemplates();
        }
      })
      .catch((error) => {
        props.setGlobalError(
          "There was a problem accessing the Internet. Please check your connection and try again."
        );

        console.error("Error:", error);
      });
  }
  function saveTemplate(e, templateName) {
    e.preventDefault();
    editValues.current.map((template) => {
      if (template.name == templateName) {
        postTemplate(JSON.parse(JSON.stringify(template)));
        let origTemplates = JSON.parse(JSON.stringify(templates));
        //get index for this

        templates.map((template, cnt) => {
          if (template.name == templateName) {
            origTemplates[cnt] = JSON.parse(JSON.stringify(template));
          }
        });

        let newEditValues = [];
        editValues.current.map((editVal) => {
          if (editVal.name != templateName) {
            newEditValues.push(JSON.parse(JSON.stringify(editVal)));
          }
        });
        editValues.current = newEditValues;

        let newArray = [...editIDs];
        delete newArray[newArray.indexOf(templateName)];

        setEditIDs(newArray);

        setTemplates(origTemplates);
      }
    });
  }

  function deleteTemplate(e, templateName) {
    e.preventDefault();
    var conf = window.confirm(
      "Are you sure you want to delete " + templateName + "?"
    );
    if (!conf) {
      return;
    }
    fetch(global.config.settings.wsURL + "admin/DeleteMasterTemplate", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
        name: templateName,
      },
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (data.status === "success") {
            setSuccessMessage(data.message);
            setErrMsg(null);
          } else {
            setErrMsg(data.message);
            setSuccessMessage(null);
          }
        }
      })
      .catch((error) => {
        props.setGlobalError(
          "There was a problem accessing the Internet. Please check your connection and try again."
        );

        console.error("Error:", error);
      });

    let origTemplates = [];

    //get index for this

    templates.map((template) => {
      if (template.name != templateName) {
        origTemplates.push(JSON.parse(JSON.stringify(template)));
      }
    });

    let newEditValues = [];
    editValues.current.map((editVal) => {
      if (editVal.name != templateName) {
        newEditValues.push(JSON.parse(JSON.stringify(editVal)));
      }
    });
    editValues.current = newEditValues;

    let newArray = [...editIDs];
    delete newArray[newArray.indexOf(templateName)];
    setEditIDs(newArray);

    setTemplates(origTemplates);
  }

  function cancelEdit(e, templateName) {
    let newArray = [...editIDs];
    delete newArray[newArray.indexOf(templateName)];
    setEditIDs(newArray);
  }
  useEffect(() => {
    getTemplates();
  }, []);

  function newTemplateChange(e, key) {
    let templateHolder = JSON.parse(JSON.stringify(newTemplate));
    templateHolder[key] = e.target.value;

    setNewTemplate(templateHolder);
  }

  function addNewTemplate() {
    let template = JSON.parse(JSON.stringify(newTemplate));
    if (template.name == null) {
      alert("Please enter a name for this template.");
      return;
    }
    if (template.description == null) {
      alert("Please enter a description for this template.");
      return;
    }

    fetch(global.config.settings.wsURL + "admin/UpdateMasterTemplate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },
      body: JSON.stringify(template),
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (data.status === "success") {
            setSuccessMessage(data.message);
            setErrMsg(null);
          } else {
            setErrMsg(data.message);
            setSuccessMessage(null);
          }
          if (data.status === "success") {
            newNameRef.current.value = "";
            newDescriptionRef.current.value = "";

            let temp = JSON.parse(JSON.stringify(masterPageTemplate));

            setNewTemplate(temp);
            getTemplates();
          }
        }
      })
      .catch((error) => {
        props.setGlobalError(
          "There was a problem accessing the Internet. Please check your connection and try again."
        );

        console.error("Error:", error);
      });
  }

  return (
    <>
      <Container style={{ paddingTop: 20, paddingBottom: 20 }} fluid>
        <Row>
          <Col>
            {errMsg && <Alert variant={"danger"}>{errMsg}</Alert>}
            {successMessage && (
              <Alert variant={"success"}>{successMessage}</Alert>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Add new master template</Accordion.Header>
                <Accordion.Body>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Default</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <Form.Group controlId={"newNameTextArea"}>
                            <Form.Control
                              ref={newNameRef}
                              onChange={(e) => newTemplateChange(e, "name")}
                              as="textarea"
                              rows={3}
                            />
                          </Form.Group>
                        </td>

                        <td>
                          <Form.Group controlId={"newDescriptionTextArea"}>
                            <Form.Control
                              ref={newDescriptionRef}
                              onChange={(e) =>
                                newTemplateChange(e, "description")
                              }
                              as="textarea"
                              rows={11}
                            />
                          </Form.Group>
                        </td>

                        <td>
                          <Button
                            variant="success"
                            style={{ width: "100%" }}
                            onClick={(e) => addNewTemplate()}
                          >
                            Add
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Default</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {templates.map((template) => {
            return (
              <tr key={template.name}>
                <td width="5%">{template.name}</td>

                {editIDs.includes(template.name) ? (
                  <td width="25%">
                    <Form.Group
                      controlId={template.name + "-DescriptionTextArea"}
                    >
                      <Form.Control
                        onChange={(e) => descriptionChange(e, template.name)}
                        as="textarea"
                        defaultValue={template.description}
                        rows={3}
                      />
                    </Form.Group>
                  </td>
                ) : (
                  <td width="25%">{template.description}</td>
                )}
                {editIDs.includes(template.name) ? (
                  <td width="25%">
                    <Form.Group>
                      <Form.Check
                        type="checkbox"
                        defaultChecked={template.isDefault}
                        onChange={(e) => defaultChange(e, template.name)}
                        label={"Is Default?"}
                      />
                    </Form.Group>
                  </td>
                ) : (
                  <td width="25%">
                    {template.isDefault == true ? "Yes" : "No"}
                  </td>
                )}
                {template.hasPDFTemplate ? (
                  <td width="10%">
                    <Button
                      variant="success"
                      style={{ width: "100%" }}
                      onClick={(e) => getTemplate(e, template.name)}
                    >
                      View Template
                    </Button>
                  </td>
                ) : (
                  <td width="10%">
                    <Button
                      variant="success"
                      style={{ width: "100%" }}
                      disabled
                    >
                      No Template
                    </Button>
                  </td>
                )}
                {uploadTemplateName == template.name ? (
                  <td width="20%">
                    <Container
                      style={{
                        width: "100%",
                        marginLeft: "0px",
                        marginRight: "0px",
                        paddingLeft: "0px",
                      }}
                    >
                      <Row>
                        <Col>
                          <Form>
                            <Form.Control
                              type="file"
                              onChange={(e) => changeHandler(e, template.name)}
                              isValid
                            />
                            <Form.Label data-browse="Browse">
                              {fileMessage}
                            </Form.Label>
                            <Form.Control.Feedback type="valid">
                              {uploadSuccess}
                            </Form.Control.Feedback>

                            <Form.Group>
                              {selectedFile && (
                                <>
                                  <Button
                                    variant="info"
                                    style={{
                                      marginTop: "5px",
                                      marginBottom: "5px",
                                    }}
                                    onClick={(e) =>
                                      submitHandler(e, template.name)
                                    }
                                  >
                                    Upload
                                  </Button>
                                  <p>File name: {selectedFile.name}</p>
                                  <p>File type: {selectedFile.type}</p>
                                  <p>Size in bytes: {selectedFile.size}</p>
                                </>
                              )}
                            </Form.Group>
                          </Form>
                        </Col>
                      </Row>
                    </Container>{" "}
                  </td>
                ) : (
                  <td width="10%">
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        setUploadTemplateName(template.name);
                      }}
                    >
                      Upload Template
                    </Button>
                  </td>
                )}

                {editIDs.includes(template.name) ? (
                  <>
                    <td width="5%">
                      <Button
                        variant="success"
                        style={{ width: "100%" }}
                        onClick={(e) => saveTemplate(e, template.name)}
                      >
                        Save
                      </Button>
                    </td>
                    <td width="5%">
                      <Button
                        block
                        onClick={(e) => cancelEdit(e, template.name)}
                      >
                        Cancel
                      </Button>
                    </td>
                  </>
                ) : (
                  <>
                    <td width="5%">
                      <Button
                        variant="success"
                        style={{ width: "100%" }}
                        onClick={(e) => editTemplate(e, template.name)}
                      >
                        Edit
                      </Button>
                    </td>
                    <td width="5%">
                      <Button
                        block
                        onClick={(e) => deleteTemplate(e, template.name)}
                      >
                        Delete
                      </Button>
                    </td>
                  </>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

export default React.memo(MasterTemplateSetup);
