import React, { useState } from "react";
import { Button, Container, Col, Row, Alert, Modal } from "react-bootstrap";
import ReactDataGrid from "react-data-grid";

function RemoveCheckinModal(props) {
  const handleDelete = (e) => {
    e.preventDefault();
    console.log(props.attendeesToRemoveCheckins);

    fetch(global.config.settings.wsURL + "admin/RmoveCheckins", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },
      body: JSON.stringify(props.attendeesToRemoveCheckins),
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          props.setAttendeesToRemoveCheckins([]);
          props.getAttendees();
        }
      })
      .catch((error) => {
        props.setGlobalError(
          "There was a problem accessing the Internet. Please check your connection and try again."
        );

        console.error("Error:", error);
      });

    //props.setRefreshAllBadges(true);
  };
  const handleClose = () => {
    props.setAttendeesToRemoveCheckins([]);
  };
  const defaultColumnProperties = {
    height: 50,

    resizable: true,
  };

  const columns = [
    {
      key: "badgeNumber",
      name: "ID",
      width: 160,
      frozen: true,
    },
    { key: "firstName", name: "First Name" },
    { key: "lastName", name: "Last Name" },
    { key: "companyName", name: "Company" },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  const EmptyRowsRenderer = () => {
    return (
      <div style={{ textAlign: "center" }}>
        Nothing to show{" "}
        <span lang="en" title="no data">
          No attendees
        </span>
      </div>
    );
  };

  const [errorMsg, setErrMsg] = useState(null);
  return (
    <Modal onHide={handleClose} show={true}>
      <Modal.Header>Confirm Check-in Removal </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col>
              <ReactDataGrid
                emptyRowsRenderer={EmptyRowsRenderer}
                columns={columns}
                rows={props.attendeesToRemoveCheckins}
                rowHeight={50}
                minHeight={600}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                variant="warning"
                style={{ width: "100%", marginTop: "5px" }}
                onClick={() => {
                  props.setAttendeesToRemoveCheckins([]);
                }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                style={{ width: "100%", marginTop: "35px" }}
                variant="danger"
                onClick={(e) => handleDelete(e)}
                block
              >
                Confirm check-in removal (cannot be undone)
              </Button>
            </Col>
          </Row>
          {errorMsg != null && (
            <Row>
              <Col>
                <Alert
                  key="Alert"
                  style={{ width: "100%" }}
                  size="md"
                  variant="danger"
                >
                  {" "}
                  {errorMsg}{" "}
                </Alert>
              </Col>
            </Row>
          )}
        </Container>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

export default React.memo(RemoveCheckinModal);
