import React, { useContext } from "react";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import {
  AccordionContext,
  useAccordionButton,
  Container,
  Col,
  Row,
  Button,
} from "react-bootstrap";

export function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h4>{children}</h4>
          </Col>
          <Col></Col>
          <Col style={{ textAlign: "right" }}>
            <Button
              type="button"
              bsPrefix="btn-omni"
              onClick={decoratedOnClick}
            >
              {isCurrentEventKey ? <MdExpandLess /> : <MdExpandMore />}
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}
