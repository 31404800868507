import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import styles from "./reports.module.css";
import Select, { components } from "react-select";
import ReactDataGrid, { SelectColumn } from "react-data-grid";
import moment from "moment";

function DailyCheckin(props) {
  const [open, setOpen] = useState(false);
  const [dateOptions, setDateOptions] = useState([]);
  const [selectedDateOptions, setSelectedDateOptions] = useState([]);
  const [dailyCheckins, setDailyCheckins] = useState({ labels: [], data: [] });
  const [filteredAttendeeList, setFilteredAttendeeList] = useState([]);
  const [attendeeList, setAttendeeList] = useState([]);
  const [selectedRows, setSelectedRows] = useState(new Set(React.Key));
  const searchTextRef = useRef();
  const dateSelectRef = useRef();
  const [statusSearch, setStatusSearch] = useState("notscannedout");
  const statusSearchRef = useRef(statusSearch);
  const selectedDateOptionsRef = useRef(selectedDateOptions);
  function getAllAttendeeData(e) {
    e.preventDefault();

    fetch(global.config.settings.wsURL + "Admin/GetAllDailyCheckinData", {
      method: "GET",
      headers: {
        accept: "*/*",

        authorization: props.user.token,
        showCode: props.showCode,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `DailyCheckins.xlsx`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        props.setGlobalError(
          "There was a problem accessing the Internet. Please check your connection and try again."
        );

        console.error("Error:", error);
      });
  }

  function rowKeyGetter(row) {
    return row.badgeNumber + " " + row.verifiedDate;
  }
  const EmptyRowsRenderer = () => {
    const message = "No attendees to show";

    return (
      <div style={{ textAlign: "center" }}>
        <span lang="en" title="no data">
          No matching attendees
        </span>
      </div>
    );
  };
  useEffect(() => {
    let newDays = [];

    dailyCheckins.labels.map((daily) => {
      newDays.push({
        value: daily,
        label: daily,
      });
    });

    setDateOptions(newDays);
    if (selectedDateOptions.length === 0) {
      setSelectedDateOptions(newDays);
    }
  }, [dailyCheckins]);
  function onChange(value, { action, removedValue }) {
    switch (action) {
      case "remove-value":
        var options = [];
        setSelectedDateOptions(value);
      case "pop-value":
        if (removedValue.isFixed) {
          return;
        }
        break;
      case "select-option":
        setSelectedDateOptions(value);
      case "clear":
        //console.log('inside of clear')
        //setSelectedDateOptions([]);
        //value = colourOptions.filter(v => v.isFixed);
        break;
    }
    let sortValues = [];
    //value.map((val) => sortValues.push(val.value));
  }

  function getData(e) {
    filterAttendees();
  }

  const defaultColumnProperties = {
    height: 50,

    resizable: true,
  };

  const scanIndateFormatter = (rowData) => {
    let verDate = Date.parse(rowData.row.verifiedDate);

    if (verDate) {
      if (
        moment(rowData.row.verifiedDate).format("MM-DD-YYYY h:mm:ss a") ===
        "01-01-0001 12:00:00 am"
      ) {
        //.net returns min date time
        return <></>;
      }
      return moment(rowData.row.verifiedDate).format("MM-DD-YYYY h:mm:ss a");
    } else {
      return <></>;
    }
  };

  const scanOutDateFormatter = (rowData) => {
    let verDate = Date.parse(rowData.row.dateAdded);

    if (verDate) {
      if (
        moment(rowData.row.dateAdded).format("MM-DD-YYYY h:mm:ss a") ===
        "01-01-0001 12:00:00 am"
      ) {
        //.net returns min date time
        return <></>;
      }
      return moment(rowData.row.dateAdded).format("MM-DD-YYYY h:mm:ss a");
    } else {
      return <></>;
    }
  };

  const columns = [
    {
      key: "badgeNumber",
      name: "ID",
      width: 160,
      frozen: true,
    },
    { key: "firstName", name: "First Name" },
    { key: "lastName", name: "Last Name" },
    { key: "email", name: "Email" },
    {
      key: "verifiedDate",
      name: "Scanned In",
      formatter: scanIndateFormatter,
      width: 170,
    },
    {
      key: "dateAdded",
      name: "Scanned Out",
      formatter: scanOutDateFormatter,
      width: 170,
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  function getReport(refreshDateOptions) {
    fetch(global.config.settings.wsURL + "admin/GetDailyCheckins", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          var newDays = [];
          let daysList = [];
          data.map((item) => {
            if (
              daysList.includes(
                moment(item.verifiedDate).format("MM/DD/YYYY")
              ) == false
            ) {
              daysList.push(moment(item.verifiedDate).format("MM/DD/YYYY"));
              newDays.push({
                value: item.verifiedDate,
                label: moment(item.verifiedDate).format("MM/DD/YYYY"),
              });
            }
          });
          var currDate = moment().format("MMMM Do YYYY");
          setDateOptions(newDays);
          //setSelectedDateOptions(newDays);
          //console.log(refreshDateOptions);
          if (refreshDateOptions) {
            newDays.map((day) => {
              if (moment(day.value).format("MMMM Do YYYY") === currDate) {
                setSelectedDateOptions([day]);
              }
            });
          }

          setAttendeeList(JSON.parse(JSON.stringify(data)));
          setFilteredAttendeeList(JSON.parse(JSON.stringify(data)));
          /*
              setDailyCheckins({
                  labels: labels,
                  data: values
              });
              */
        }
      })
      .catch((error) => {
        props.setGlobalError(
          "There was a problem accessing the Internet. Please check your connection and try again."
        );

        console.error("Error:", error);
      });
  }

  useEffect(() => {
    getReport(true);
    var sess = setInterval(() => getReport(false), 7000);

    return function cleanupInterval() {
      clearInterval(sess);
    };
  }, []);

  const [clickedDataset, setClickedDataset] = useState("");
  const [clickedElement, setClickedElement] = useState("");
  const [clickedElements, setClickedElements] = useState("");
  const getDatasetAtEvent = (dataset) => {
    if (!dataset.length) return;
    //const datasetIndex = dataset[0].datasetIndex;
    //setClickedDataset(template.datasets[datasetIndex].label);
  };

  const getElementAtEvent = (element) => {
    if (!element.length) return;

    const { _datasetIndex, _index } = element[0];
    setClickedDataset(_datasetIndex);

    setSelectedDateOptions([
      {
        value: dailyCheckins.labels[_index],
        label: dailyCheckins.labels[_index],
      },
    ]);
    setClickedElement(
      `${dailyCheckins.labels[_index]} - ${dailyCheckins.data[_index]}`
    );
  };

  const filterAttendees = () => {
    let list = JSON.parse(JSON.stringify(attendeeList));
    let newFilteredList = [];

    var selDateOptionsCopy = JSON.parse(JSON.stringify(selectedDateOptions));
    if (selDateOptionsCopy.length == 0) {
      selDateOptionsCopy.push({ value: "01/01/0001", label: "01/01/0001" });
    }

    selDateOptionsCopy.map((date) => {
      let selDate = date.label;
      if (selDate === "" || selDate == null) {
        selDate = "01/01/0001";
      }

      list.map((attendee) => {
        let verDate = Date.parse(attendee.verifiedDate);
        let compareDate = moment(verDate).format("MM/DD/YYYY");

        let add = true;

        if (statusSearchRef.current == "notscannedout") {
          if (
            moment(attendee.dateAdded).format("MM-DD-YYYY h:mm:ss a") !=
              "01-01-0001 12:00:00 am" ||
            moment(attendee.verifiedDate).format("MM-DD-YYYY h:mm:ss a") ==
              "01-01-0001 12:00:00 am"
          ) {
            add = false;
          }
        }
        if (statusSearchRef.current == "scannedout") {
          if (
            moment(attendee.dateAdded).format("MM-DD-YYYY h:mm:ss a") ===
            "01-01-0001 12:00:00 am"
          ) {
            add = false;
          }
        }
        if (statusSearchRef.current == "noshow") {
          if (attendee.dateAdded != attendee.verifiedDate) {
            //We don't want anyone scanned today
            add = false;
          } else {
            compareDate = selDate; //no scan date, let's trigger the  add
          }
        }

        if (compareDate == selDate && add == true) {
          attendee.attendeeString = (
            attendee.firstName.toLowerCase() + attendee.lastName.toLowerCase()
          ).replace(new RegExp("[^a-zA-Z0-9]", "g"), "");

          newFilteredList.push(attendee);
        }
      });
    });
    if (statusSearchRef.current == "all") {
      let notExists = true;

      list.map((attendee) => {
        var c = newFilteredList.find(
          (item) => item.badgeNumber == attendee.badgeNumber
        );

        if (!c) {
          attendee.attendeeString = (
            attendee.firstName.toLowerCase() + attendee.lastName.toLowerCase()
          ).replace(new RegExp("[^a-zA-Z0-9]", "g"), "");
          attendee.verifiedDate = "";
          attendee.dateAdded = "";
          newFilteredList.push(attendee);
        }
      });
    }
    let searchString = searchTextRef.current.value;
    if (searchString.length > 0) {
      let thisString = searchString
        .toLowerCase()
        .replace(new RegExp("[^a-zA-Z0-9]", "g"), "");

      newFilteredList = newFilteredList.filter(function (n) {
        return n.attendeeString.includes(thisString);
      });
    }
    newFilteredList.sort((a, b) => {
      return a["lastName"].localeCompare(b["lastName"]);
    });
    setFilteredAttendeeList(newFilteredList);
  };
  useEffect(() => {
    filterAttendees();
  }, [attendeeList, selectedDateOptions, statusSearch]);

  const getElementsAtEvent = (elements) => {
    if (!elements.length) return;

    setClickedElements(elements.length);
  };
  return (
    <Container style={{ maxWidth: "100%" }}>
      <Row>
        <Col className={styles.graphHeader}>
          <h3>Daily checkins</h3>
        </Col>
      </Row>
      <Row>
        <Col></Col>
        <Col>
          <Button
            onClick={(e) => getAllAttendeeData(e)}
            style={{ width: "100%" }}
            bsPrefix="btn-omni"
            bs
            size="md"
          >
            Download all check-in data
          </Button>
        </Col>
        <Col></Col>
      </Row>
      <Row>
        <Col>
          <Container>
            <Row>
              <Col>
                <label htmlFor="DayList">Filter by days</label>
              </Col>
            </Row>

            <Row>
              <Col>
                {dateOptions.length > 0 && (
                  <Select
                    ref={dateSelectRef}
                    defaultValue={[...dateOptions]}
                    id={"DayList"}
                    isMulti
                    onChange={onChange}
                    value={selectedDateOptions}
                    options={dateOptions}
                    clearable={false}
                  />
                )}
              </Col>
            </Row>

            <Row>
              <Col md={3} style={{ textAlign: "left" }}>
                <label htmlFor="SearchBar">Filter List</label>
                <Form.Control
                  placeholder="Search by name"
                  aria-label="Search by name"
                  autoComplete="off"
                  spellCheck="false"
                  id="SearchBar"
                  aria-controls="AttendeeList"
                  ref={searchTextRef}
                  onChange={(e) => getData(e)}
                />
              </Col>
              <Col md={3} style={{ textAlign: "left" }}>
                <label htmlFor="StatusFilter">Status</label>{" "}
                <StatusFilter
                  id="StatusFilter"
                  setStatusSearch={setStatusSearch}
                  statusSearchRef={statusSearchRef}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <ReactDataGrid
                  emptyRowsRenderer={EmptyRowsRenderer}
                  columns={columns}
                  rows={filteredAttendeeList}
                  rowHeight={50}
                  minHeight={600}
                  selectedRows={selectedRows}
                  onSelectedRowsChange={setSelectedRows}
                  rowKeyGetter={rowKeyGetter}
                />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Row></Row>
    </Container>
  );
}

export default DailyCheckin;

const StatusFilter = React.memo((props) => {
  const options = [
    {
      value: "noshow",
      label: "No-show",
    },
    {
      value: "all",
      label: "Any",
    },
    {
      value: "scannedout",
      label: "Scanned In and Out",
    },
    {
      value: "notscannedout",
      label: "Scanned In But Not Out",
    },
  ];
  function onChange(value) {
    props.setStatusSearch(value.value);
    props.statusSearchRef.current = value.value;
  }
  return (
    <Select defaultValue={[options[3]]} onChange={onChange} options={options} />
  );
});
