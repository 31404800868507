import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Button, Container, Row, Col, Form } from "react-bootstrap";

function FieldSetup(props) {
  const [displayFields, setDisplayFields] = useState({});

  useEffect(() => {
    fetch(global.config.settings.wsURL + "admin/GetShowDisplayFields", {
      method: "GET",
      headers: {
        accept: "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }

        return response.json();
      })
      .then((data) => {
        if (data) {
          setDisplayFields(data);
        }
      });
  }, []);

  function updateFields(e) {
    let holdDisplayFields = JSON.parse(JSON.stringify(displayFields));
    holdDisplayFields[e.target.id] = parseInt(e.target.value);
    setDisplayFields(holdDisplayFields);
  }

  function handleSave(e) {
    e.preventDefault();
    console.log(displayFields);
    fetch(global.config.settings.wsURL + "admin/UpdateShowDisplayFields", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },
      body: JSON.stringify(displayFields),
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          alert(data.message);
          props.setRefreshFields(true);
        }
      });
  }

  return (
    <Container>
      <Row>
        <Col md={4}>
          <Container>
            {" "}
            <Row>
              <Col>
                <Form.Group as={Col} controlId="address1">
                  <Form.Label>Address 1</Form.Label>
                  <Form.Control
                    onChange={(e) => updateFields(e)}
                    value={displayFields.address1}
                    as="select"
                  >
                    <option label={"Enabled"}>1</option>
                    <option label={"Disabled"}>0</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group as={Col} controlId="exhibitorId">
                  <Form.Label>Exhibitor ID</Form.Label>
                  <Form.Control
                    onChange={(e) => updateFields(e)}
                    value={displayFields.exhibitorId}
                    as="select"
                  >
                    <option label={"Enabled"}>1</option>
                    <option label={"Disabled"}>0</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group as={Col} controlId="address2">
                  <Form.Label>Address 2</Form.Label>
                  <Form.Control
                    onChange={(e) => updateFields(e)}
                    value={displayFields.address2}
                    as="select"
                  >
                    <option label={"Enabled"}>1</option>
                    <option label={"Disabled"}>0</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group as={Col} controlId="city">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    onChange={(e) => updateFields(e)}
                    value={displayFields.city}
                    as="select"
                    defaultValue="Choose..."
                  >
                    <option label={"Enabled"}>1</option>
                    <option label={"Disabled"}>0</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group as={Col} controlId="stateProvince">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    onChange={(e) => updateFields(e)}
                    value={displayFields.stateProvince}
                    as="select"
                  >
                    <option label={"Enabled"}>1</option>
                    <option label={"Disabled"}>0</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group as={Col} controlId="postalCode">
                  <Form.Label>Postal code</Form.Label>
                  <Form.Control
                    onChange={(e) => updateFields(e)}
                    as="select"
                    value={displayFields.postalCode}
                  >
                    <option label={"Enabled"}>1</option>
                    <option label={"Disabled"}>0</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group as={Col} controlId="country">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    onChange={(e) => updateFields(e)}
                    as="select"
                    value={displayFields.country}
                  >
                    <option label={"Enabled"}>1</option>
                    <option label={"Disabled"}>0</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </Col>
        <Col md={4}>
          <Container>
            <Row>
              <Col>
                <Form.Group as={Col} controlId="companyName">
                  <Form.Label>Company name</Form.Label>
                  <Form.Control
                    onChange={(e) => updateFields(e)}
                    as="select"
                    value={displayFields.companyName}
                  >
                    <option label={"Enabled"}>1</option>
                    <option label={"Disabled"}>0</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group as={Col} controlId="companyAddress1">
                  <Form.Label>Company address 1</Form.Label>
                  <Form.Control
                    onChange={(e) => updateFields(e)}
                    as="select"
                    value={displayFields.companyAddress1}
                    defaultValue="Choose..."
                  >
                    <option label={"Enabled"}>1</option>
                    <option label={"Disabled"}>0</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group as={Col} controlId="companyAddress2">
                  <Form.Label>Company address 2</Form.Label>
                  <Form.Control
                    onChange={(e) => updateFields(e)}
                    value={displayFields.companyAddress2}
                    as="select"
                  >
                    <option label={"Enabled"}>1</option>
                    <option label={"Disabled"}>0</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group as={Col} controlId="companyCity">
                  <Form.Label>Company city</Form.Label>
                  <Form.Control
                    onChange={(e) => updateFields(e)}
                    as="select"
                    value={displayFields.companyCity}
                  >
                    <option label={"Enabled"}>1</option>
                    <option label={"Disabled"}>0</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Col}
                  value={displayFields.companyStateProvince}
                  controlId="companyStateProvince"
                >
                  <Form.Label>Company state</Form.Label>
                  <Form.Control
                    onChange={(e) => updateFields(e)}
                    as="select"
                    value={displayFields.companyStateProvince}
                  >
                    <option label={"Enabled"}>1</option>
                    <option label={"Disabled"}>0</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group as={Col} controlId="companyPostalCode">
                  <Form.Label>Company postal code</Form.Label>
                  <Form.Control
                    onChange={(e) => updateFields(e)}
                    as="select"
                    value={displayFields.companyPostalCode}
                  >
                    <option label={"Enabled"}>1</option>
                    <option label={"Disabled"}>0</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group as={Col} controlId="companyCountry">
                  <Form.Label>Company country</Form.Label>
                  <Form.Control
                    onChange={(e) => updateFields(e)}
                    as="select"
                    value={displayFields.companyCountry}
                  >
                    <option label={"Enabled"}>1</option>
                    <option label={"Disabled"}>0</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </Col>
        <Col md={4}>
          <Container>
            <Row>
              <Col>
                <Form.Group as={Col} controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    onChange={(e) => updateFields(e)}
                    value={displayFields.email}
                    as="select"
                  >
                    <option label={"Enabled"}>1</option>
                    <option label={"Disabled"}>0</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group as={Col} controlId="customerBadgeNumber">
                  <Form.Label>Customer Badge Number</Form.Label>
                  <Form.Control
                    onChange={(e) => updateFields(e)}
                    as="select"
                    value={displayFields.customerBadgeNumber}
                  >
                    <option label={"Enabled"}>1</option>
                    <option label={"Disabled"}>0</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group as={Col} controlId="firstName">
                  <Form.Label>First name</Form.Label>
                  <Form.Control
                    onChange={(e) => updateFields(e)}
                    as="select"
                    value={displayFields.firstName}
                  >
                    <option label={"Enabled"}>1</option>
                    <option label={"Disabled"}>0</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group as={Col} controlId="lastName">
                  <Form.Label>Last name</Form.Label>
                  <Form.Control
                    onChange={(e) => updateFields(e)}
                    as="select"
                    value={displayFields.lastName}
                  >
                    <option label={"Enabled"}>1</option>
                    <option label={"Disabled"}>0</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group as={Col} controlId="title">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    onChange={(e) => updateFields(e)}
                    as="select"
                    value={displayFields.title}
                  >
                    <option label={"Enabled"}>1</option>
                    <option label={"Disabled"}>0</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Button onClick={(e) => handleSave(e)} block>
                  Save
                </Button>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

export default React.memo(FieldSetup);
