import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  Table,
  Container,
  Row,
  Col,
  Image,
  Form,
} from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import { styles } from "./showList.module.css";
import CreateShowModal from "../createShowModal/createShowModal";
import CopyShowModal from "../copyShowModal/copyShowModal";
import CustomerSettingsModal from "../customerSettingsModal/customerSettingsModal";
import Logo from "../../assets/img/Logo.png";
import Styles from "./showList.module.css";
import ReactDataGrid, { SelectColumn } from "react-data-grid";

export default function ShowList(props) {
  const [showList, setShowList] = useState([]);
  const [filteredShowList, updateFilteredShowList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [showSettingsModal, setShowSettingsModal] = useState("");
  const [showAddShowModal, setShowAddShowModal] = useState(false);
  const [showCopyShowModal, setShowCopyShowModal] = useState(false);
  const [copyFromShowCode, setCopyFromShowCode] = useState(null);
  const [showActiveShows, setShowActiveShows] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (newPath) => {
    navigate(newPath);
  };

  const EmptyRowsRenderer = () => {
    const message = "No users to show";

    return (
      <div style={{ textAlign: "center" }}>
        Nothing to show{" "}
        <span lang="en" title="no data">
          No Shows. Click the Add Event button to get started.
        </span>
      </div>
    );
  };

  useEffect(() => {
    fetch(global.config.settings.wsURL + "admin/GetShowsByUserAndGroup", {
      method: "GET",
      headers: {
        accept: "application/json",
        authorization: props.user.token,
      },
    })
      .then(function (response) {
        if (!response.ok) {
          if (response.status == "401") {
            logout(null);
          }
          console.log("Status " + response.status);
          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          setShowList(JSON.parse(JSON.stringify(data)));
          if (showActiveShows === "") {
            setShowActiveShows("active");
          }

          updateFilteredShowList(JSON.parse(JSON.stringify(data)));
        }
      })
      .catch((error) => {
        props.setGlobalError(
          "There was a problem accessing the Internet. Please check your connection and try again."
        );

        console.error("Error:", error);
      });
  }, [showAddShowModal]);
  const logout = (e) => {
    if (e != null) {
      e.preventDefault();
    }
    localStorage.setItem("adsfdfsasdfdfsa", null);
    localStorage.setItem("adsfdfsasdfdfsashowcode", null);
    props.setUser(null);
    window.location.href = "/" + props.user.customerOrganizationID;
  };

  const copyShowFormatter = (rowData) => {
    return (
      <>
        {rowData.row.username != "Admin" && (
          <Button
            variant="primary"
            aria-label={"Copy show named " + rowData.row.showCode}
            bsPrefix="btn-omni"
            onClick={(e) => {
              e.preventDefault();
              alert("i am here");

              setCopyFromShowCode(rowData.row.showCode);
              setShowCopyShowModal(true);
            }}
            block
          >
            Copy
          </Button>
        )}
      </>
    );
  };
  const viewFormatter = (rowData) => {
    return (
      <>
        {rowData.row.username != "Admin" && (
          <Button
            aria-label={"View show " + rowData.row.showCode}
            bsPrefix="btn-omni"
            variant="primary"
            size="sm"
            onClick={(e) => {
              e.preventDefault();
              props.setShowName(rowData.row.name);
              props.setShowCode(rowData.row.showCode);

              let path = "/home/";
              handleSubmit(path);
            }}
            block
          >
            View Now
          </Button>
        )}
      </>
    );
  };

  useEffect(() => {
    let newShowList = [];

    showList.map((show) => {
      if (
        (showActiveShows === "active" && show.isTestShow === false) ||
        (showActiveShows === "inactive" && show.isTestShow === true)
      ) {
        let showString = show.name
          .toLowerCase()
          .replace(new RegExp("[^a-zA-Z0-9]", "g"), "");
        if (showString.includes(searchText)) {
          newShowList.push(show);
        }
      }
    });

    updateFilteredShowList(JSON.parse(JSON.stringify(newShowList)));
  }, [searchText, showActiveShows]);

  const defaultColumnProperties = {
    height: 50,

    resizable: true,
  };

  const columns = [
    { key: "ViewShow", name: "", width: 140, formatter: viewFormatter },

    { key: "name", name: "Name" },
    { key: "showCode", name: "Code", width: 140 },

    { key: "copyShow", name: "", width: 140, formatter: copyShowFormatter },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  return (
    <>
      {showAddShowModal && (
        <CreateShowModal
          setGlobalError={props.setGlobalError}
          setShowAddShowModal={setShowAddShowModal}
          showAddShowModal={showAddShowModal}
          user={props.user}
        />
      )}
      {showCopyShowModal && (
        <>
          <CopyShowModal
            setGlobalError={props.setGlobalError}
            user={props.user}
            showCopyShowModal={showCopyShowModal}
            setShowCopyShowModal={setShowCopyShowModal}
            setCopyFromShowCode={setCopyFromShowCode}
            copyFromShowCode={copyFromShowCode}
          />
        </>
      )}
      {showSettingsModal && (
        <>
          <CustomerSettingsModal
            setGlobalError={props.setGlobalError}
            user={props.user}
            showSettingsModal={showSettingsModal}
            setShowSettingsModal={setShowSettingsModal}
          />
        </>
      )}
      <Container>
        <Row style={{ padding: "20px" }}>
          <Col></Col>
          <Col>
            <Image aria-label={"Logo"} src={Logo} />
          </Col>
          <Col style={{ textAlign: "right", paddingTop: "60px" }}>
            <Button onClick={logout} bsPrefix="btn-omni" size="md" active>
              Logout
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormControl
              placeholder="Search by show name"
              aria-label="Search by show name"
              onChange={(e) => {
                let thisString = e.target.value
                  .toLowerCase()
                  .replace(new RegExp("[^a-zA-Z0-9]", "g"), "");
                setSearchText(thisString);
              }}
            />
          </Col>
          <Col>
            <Form.Group controlId="isHidden">
              <Form.Control
                onChange={(e) => {
                  setShowActiveShows(e.target.value);
                }}
                value={showActiveShows}
                as="select"
              >
                <option label={"Show Active Events"}>active</option>
                <option label={"Show Inactive Events"}>inactive</option>
              </Form.Control>
            </Form.Group>
          </Col>

          <Col style={{ textAlign: "right" }}>
            <Button
              bsPrefix="btn-omni"
              onClick={(e) => {
                e.preventDefault();
                setShowAddShowModal(true);
              }}
            >
              Add Event
            </Button>
            <Button
              bsPrefix="btn-omni"
              onClick={(e) => {
                e.preventDefault();
                setShowSettingsModal(true);
              }}
            >
              Company Settings
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                let path = "/users/";

                handleSubmit(path);
              }}
              size="md"
              bsPrefix="btn-omni"
              active
            >
              Manage Users
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <ReactDataGrid
              emptyRowsRenderer={EmptyRowsRenderer}
              columns={columns}
              rows={filteredShowList}
              rowHeight={50}
              minHeight={600}
              className="fill-grid"
              style={{
                "-webkit-overflow-scrolling": "auto",
                overflowX: "scroll",
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col></Col>
        </Row>
      </Container>
    </>
  );
}
