import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  Container,
  Row,
  Col,
  Modal,
  Tabs,
  Tab,
} from "react-bootstrap";
import styles from "./emails.module.css";
import EmailConfirmations from "./emailConfirmations";
import EmailTemplates from "./emailTemplates";
function Emails(props) {
  const handleClose = () => props.setShowModal(false);

  const [key, setKey] = useState("emailTemplates");

  return (
    <Modal
      centered
      contentClassName={styles.modal}
      dialogClassName={styles.modal}
      onHide={handleClose}
      show={props.showModal}
    >
      <Modal.Header closeButton>
        <h3>Emails</h3>
      </Modal.Header>

      <Modal.Body className={styles.modalBodyStyle}>
        <Tabs id="controlled-tab" activeKey={key} onSelect={(k) => setKey(k)}>
          <Tab
            style={{ height: "100%", border: "50px" }}
            eventKey="emailTemplates"
            title="Template"
          >
            {key === "emailTemplates" && (
              <EmailTemplates
                setGlobalError={props.setGlobalError}
                user={props.user}
                customerID={props.customerID}
                showCode={props.showCode}
              />
            )}
          </Tab>
          <Tab eventKey="emailConfirmations" title="Email Confirmations">
            {key === "emailConfirmations" && (
              <EmailConfirmations
                setGlobalError={props.setGlobalError}
                user={props.user}
                customerID={props.customerID}
                showCode={props.showCode}
              />
            )}
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button
          bsPrefix="btn-omni"
          onClick={() => {
            props.setShowModal(false);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default React.memo(Emails);
