import React, { useState, useEffect } from "react";
import { Button, Container, Row, Col, Form } from "react-bootstrap";

function SmtpSettings(props) {
  const [serverName, setServerName] = useState("");
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [sendInterval, setSendInterval] = useState("");
  const [fromEmailAddress, setFromEmailAddress] = useState("");
  function clearSettings() {
    fetch(global.config.settings.wsURL + "admin/ClearEmailSettings", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);
          alert(response.message);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          alert(data.message);
          setServerName("");
          setServerName("");
          setUsername("");
          setFromEmailAddress("");
          setSendInterval("");
        }
      });
  }
  function saveSettings(e) {
    if (serverName == "") {
      alert("Smtp Server Name is Required");
      return;
    }
    if (userName == "") {
      alert("Smtp User Name is Required");
      return;
    }
    if (password == "") {
      alert("Smtp Password  is Required");
      return;
    }
    if (sendInterval == "") {
      alert("Send Interval is Required");
      return;
    }
    if (fromEmailAddress == "") {
      alert("Send From Email Address is Required");
      return;
    }
    const interval = parseInt(sendInterval);
    if (!interval) {
      alert("Smtp Send Interval Must be a number. ");
    }
    const postData = {
      showCode: props.showCode,
      customerOrganizationID: 0,
      smtpServer: serverName,
      smtpPassword: password,
      smtpUserName: userName,
      smtpPort: 587,
      fromEmailAddress: fromEmailAddress,
      sendInterval: interval,
    };
    fetch(global.config.settings.wsURL + "admin/UpdateEmailSettings", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },
      body: JSON.stringify(postData),
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);
          alert(response.message);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          alert(data.message);
        }
      });
  }
  useEffect(() => {
    getSettings();
  }, []);
  function getSettings() {
    fetch(global.config.settings.wsURL + "admin/GetEmailSettings", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },
    })
      .then((response) => {
        if (!response.ok) {
          console.log("Status " + response.status);
          alert(response.message);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        setServerName(data.smtpServer);
        setServerName(data.smtpServer);
        setUsername(data.smtpUserName);
        setFromEmailAddress(data.fromEmailAddress);
        setSendInterval(data.sendInterval);
      });
  }
  return (
    <Container>
      <Row>
        <Col>
          <Container>
            <Row>
              <Col>
                <Form.Group controlId="smtpServer">
                  <Form.Label>SMTP Server</Form.Label>
                  <Form.Control
                    value={serverName}
                    onChange={(e) => setServerName(e.target.value)}
                    type="text"
                  />
                  <Form.Text className="text-muted">SMTP Server</Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="smtpServerPort">
                  <Form.Label>SMTP Server Port (Always 587)</Form.Label>
                  <Form.Control disabled type="text" placeHolder={"587"} />
                  <Form.Text className="text-muted">
                    SMTP Server Port(Always 587)
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="smtpUserName">
                  <Form.Label>SMTP User Name</Form.Label>
                  <Form.Control
                    value={userName}
                    onChange={(e) => setUsername(e.target.value)}
                    type="text"
                  />
                  <Form.Text className="text-muted">SMTP User Name</Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="smtpPassword">
                  <Form.Label>SMTP Password</Form.Label>
                  <Form.Control
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="text"
                  />
                  <Form.Text className="text-muted">SMTP Password</Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="smtpFromEmailAddress">
                  <Form.Label>Email Address to Send From</Form.Label>
                  <Form.Control
                    value={fromEmailAddress}
                    onChange={(e) => setFromEmailAddress(e.target.value)}
                    type="text"
                  />
                  <Form.Text className="text-muted">
                    This is the email address that the message will appear to
                    have been sent from
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="sendInterval">
                  <Form.Label>Send Interval(in seconds)</Form.Label>
                  <Form.Control
                    value={sendInterval}
                    onChange={(e) => setSendInterval(e.target.value)}
                    type="text"
                  />
                  <Form.Text className="text-muted">
                    This is the interval between sending each individual email.
                    When sending from an email service such as SendGrid or
                    MailGun, this field can be 0. Other email services such as
                    Google or Outlook will require a 4 second wait to prevent
                    the account from being disabled.
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  variant="info"
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                  onClick={(e) => saveSettings(e)}
                >
                  {" "}
                  Save Settings
                </Button>
              </Col>
              <Col></Col>
              <Col>
                <Button
                  variant="danger"
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                  onClick={(e) => {
                    if (
                      window.confirm(
                        "This will clear all user defined settings and revert them back to OmniEvent defaults.  Proceed?"
                      )
                    ) {
                      clearSettings();
                    }
                  }}
                >
                  {" "}
                  Clear Settings
                </Button>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

export default React.memo(SmtpSettings);
