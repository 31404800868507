import React, { useState } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Alert,
  Form,
  FormControl,
} from "react-bootstrap";
import ReactDataGrid, { SelectColumn } from "react-data-grid";

function ViewCustomers(props) {
  const viewFormatter = (rowData) => {
    return (
      <>
        <Button
          aria-label={"Sign-in " + rowData.row.showCode}
          bsPrefix="btn-omni"
          variant="primary"
          size="sm"
          onClick={(e) => {
            logout(e, rowData.row.id);
          }}
          block
        >
          Sign-in Now
        </Button>
      </>
    );
  };
  const logout = (e, custId) => {
    if (e != null) {
      e.preventDefault();
    }
    localStorage.setItem("adsfdfsasdfdfsa", null);
    localStorage.setItem("adsfdfsasdfdfsashowcode", null);
    props.setUser(null);
    window.location.href = "/" + custId;
  };
  const GetShows = (e) => {
    e.preventDefault();
    setShowError(false);
    if (accessCode == "") {
      setShowError(true);
      setAlertText("Enter an access code");
      return;
    }
    fetch(global.config.settings.wsURL + "admin/GetAllCustomers", {
      method: "GET",
      headers: {
        authorization: accessCode,
        "Content-Type": "application/json",
        accept: "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          console.log(response);
          setAlertText(
            "Ooops.  There was an Error.  Check your information and resubmit."
          );
          setShowError(true);
          return null;
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setCustomerList(data);
        updateFilteredCustomerList(JSON.parse(JSON.stringify(data)));
      })
      .catch((error) => {
        setAlertText(
          "Ooops.  There was an Error.  Check your connection resubmit."
        );
        setShowError(true);
        console.error("Error:", error);
      });
  };

  const [showError, setShowError] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [accessCode, setAccessCode] = useState("");
  const [customerList, setCustomerList] = useState(null);
  const [filteredCustomerList, updateFilteredCustomerList] = useState([]);
  const EmptyRowsRenderer = () => {
    const message = "No attendees to show";

    return (
      <div style={{ textAlign: "center" }}>
        <span lang="en" title="no data">
          No records
        </span>
      </div>
    );
  };

  const columns = [
    {
      key: "id",
      name: "ID",
      width: 160,
      frozen: true,
    },
    { key: "organizationName", name: "Name" },
    { key: "viewCustomer", name: "", width: 140, formatter: viewFormatter },
  ];
  return (
    <Container>
      {showError === true && (
        <Row>
          <Col></Col>
          <Col>
            <Alert
              key="Alert"
              variant="info"
              style={{ width: "100%" }}
              aria-label="Alert Message"
              size="md"
            >
              {" "}
              {alertText}{" "}
            </Alert>
          </Col>
          <Col></Col>
        </Row>
      )}
      {customerList === null && (
        <>
          <Row>
            <Col></Col>
            <Col>
              <Form.Group>
                <Form.Label>Access Code</Form.Label>
                <Form.Control
                  value={accessCode}
                  type="Password"
                  placeholder="Access Code"
                  aria-label="Access Code"
                  onChange={(e) => setAccessCode(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col></Col>
          </Row>
          <Row>
            <Col>
              <Button
                id={"GetAll"}
                onClick={(e) => {
                  GetShows(e);
                }}
              >
                Get Customer List
              </Button>
            </Col>
          </Row>
        </>
      )}
      {customerList && (
        <>
          <Row>
            <Col>
              <FormControl
                placeholder="Search by customer name"
                aria-label="Search by customer name"
                autoComplete="off"
                spellCheck="false"
                onChange={(e) => {
                  let newCustomerist = [];
                  let thisString = e.target.value
                    .toLowerCase()
                    .replace(new RegExp("[^a-zA-Z0-9]", "g"), "");

                  customerList.map((customer) => {
                    let showString = customer.organizationName
                      .toLowerCase()
                      .replace(new RegExp("[^a-zA-Z0-9]", "g"), "");

                    if (showString.includes(thisString)) {
                      newCustomerist.push(customer);
                    }
                  });
                  updateFilteredCustomerList(
                    JSON.parse(JSON.stringify(newCustomerist))
                  );
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <ReactDataGrid
                emptyRowsRenderer={EmptyRowsRenderer}
                columns={columns}
                rows={filteredCustomerList}
                rowHeight={50}
                minHeight={600}
              />
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}

export default ViewCustomers;
