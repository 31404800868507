import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {  Button, Form, FormControl, Container, Row, Col, Table, Modal, Alert, Card } from 'react-bootstrap';
import ReactDataGrid, {SelectColumn } from 'react-data-grid'; 
import Styles from './users.module.css';
function GroupUserModal(props) {
    const [showModal, setShowModal] = useState(true);
    const [selectedRows, setSelectedRows] = useState(new Set(React.Key));
 
    const [userList, setUserList] = useState([]);

   
    const handleClose = () => {
        setShowModal(false); 
        props.setEditGroupUserID(null)
    }

    useEffect(() => {
        fetch(global.config.settings.wsURL + 'admin/GetUsers',
        {
            method: "GET",
            headers: {
                accept: "application/json",
                authorization: props.user.token,
                username: props.user.username
            }
        }
        )   
        .then(function (response) {
            if (!response.ok) {
                
                return null;
            }
            return response.json();
        })
        .then(data => {
            console.log(data);
            if (data) {  
                
                 setUserList(data);
                 getDefaultUsers();
            }
        }).catch(error => {
            props.setGlobalError("There was a problem accessing the Internet. Please check your connection and try again.");
            props.setEditGroupUserID(null);
            console.error('Error:', error);
          });
    },[])

  
    function getDefaultUsers() {
        
        props.userGroupList.map((group) => {
            if (group.name === props.editGroupUserID) {
                const thisSet = new Set([]);
                group.users.map((user) => {
                    thisSet.add(user.userName);
                })
            
                setSelectedRows(thisSet);
                 
            }
        })
    };

    function save(e) {
        e.preventDefault();
let bod = []

for (let userName of selectedRows.keys()) {
    bod.push({
        userGroupName: props.editGroupUserID,
        customerOrganizationID: props.user.customerOrganizationID,
        userName: userName

    });
  } 
        fetch(global.config.settings.wsURL + 'admin/SaveUsersToGroup',
        {
            method: "POST",
            headers: { 
                'Content-Type': 'application/json',
                authorization: props.user.token,
                showCode: props.showCode, 
                userGroupID: props.editGroupUserID
            },
            body: JSON.stringify(bod)
        }
    )
        .then(function (response) {
            if (!response.ok) {
                console.log("Status " + response.status);

                return null;
            }
            return response.json();
        })
        .then(data => { 
            if (data) {
              
                if (data.status != 'success') {
                    alert('There was an error saving these users.')
                } else {
                    props.setEditGroupUserID(null);
                }
            }
        }).catch(error => {
            props.setGlobalError("There was a problem accessing the Internet. Please check your connection and try again.");
            props.setEditGroupUserID(null);
            console.error('Error:', error);
          });
    }
    const defaultColumnProperties = {
        height: 50,
        
        resizable: true,
      };


    const columns = [
        SelectColumn,
    {
    key: 'username',
    name: 'User Name',
    width: 160, 
    frozen: true,
     

    }, 
    { key: 'firstName', name: 'First Name', },
        { key: 'lastName', name: 'Last Name', },
    
        ].map((c) => ({ ...c, ...defaultColumnProperties }));
    function rowKeyGetter(row) { 
        return row.username;
        }      
  const EmptyRowsRenderer = () => {
      const message = "No users to show";
  
      return (
          <div style={{ textAlign: 'center' }}>Nothing to show <span lang="en" title="no data">No users</span></div>
      );
    };
    return (
        <Modal contentClassName={Styles.modal}   dialogClassName={Styles.modal} onHide={handleClose} show={showModal}> 
        <Modal.Header closeButton>
Editing users of the {props.editGroupUserID} group


        </Modal.Header>
            
        <Modal.Body style={{padding: "0px"}}  >
            <Container>
            <Row><Col>  
                    <ReactDataGrid
                        emptyRowsRenderer={EmptyRowsRenderer}
                        columns={columns} 
                        selectedRows={selectedRows}
                        onSelectedRowsChange={setSelectedRows}
                        rowKeyGetter={rowKeyGetter}
                        rows={userList}
                        rowHeight={50}
                        minHeight={600} 

                />
            </Col></Row>
            </Container>
 
        </Modal.Body>
      
        <Modal.Footer style={{pading: "0px"}}>
        <Button
                    onClick={(e) => save(e) }
                >Save</Button>
             <Button
                    onClick={() => handleClose() }
                >Close</Button>
        </Modal.Footer>
        </Modal>

    )
}

export default GroupUserModal;