import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {  Button, Form, FormControl, Container, Row, Col, Table, Modal, Alert, Card } from 'react-bootstrap';
 
import Styles from './users.module.css';

function EditUser(props) {
    const handleClose = () => {
        setShowModal(false); 
        props.setEditUsername(null)
    }
    
    const [showModal, setShowModal] = useState(true);
    const [disableUsername, setDisableUsername] = useState(true);
    const [username, setUsername] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [alertMsg, setAlertMsg] = useState(null);

    function save(e) {
        e.preventDefault(); 
     


        if (props.editUsername == 'oqiroqofjeoqfejio') {
          var body = {
            customerOrganizationID: props.user.customerOrganizationID,
            username: username,
            password: password,
            lastLoginDate: '2020-01-01T00:00:00',
            lastIPAddress: '1.1.1.1',
            firstName: firstName,
            lastName: lastName,
            email: null,
            failedLoginAttempts: 0,
            isActive: true,
            isAccounting: false,
            isSwiftRegAdmin: false,
            regQueryDisplayFields: null,
            token: null,
            tokenExpires: '2020-01-01T00:00:00',
            UserGroups: null

          }
            fetch(global.config.settings.wsURL + 'admin/AddUser',
            {
                method: "POST",
                headers: { 
                    'Content-Type': 'application/json',
                    authorization: props.user.token,
                    showCode: props.showCode, 
                    
                },
                body: JSON.stringify(body)
            } 
            ).then(function (response) {
                 
                if (!response.ok) {
                    console.log("Status " + response.status);

                    return null;
                }
                return response.json();
            })
            .then(data => { 
                if (data) {
                
                    if (data.status != 'success') {
                        setAlertMsg(data.message);
                    } else {
                        props.setEditUsername(null);
                    }
                }
            })
            .catch(error => {
                props.setGlobalError("There was a problem accessing the Internet. Please check your connection and try again.");
                props.setEditUsername(null);
                console.error('Error:', error);
              });
        } else {
          
            var body;
            props.userList.map((user) => {
                if (user.username === props.editUsername) {
                    body=JSON.parse(JSON.stringify(user));
                    body.firstName=firstName;
                    body.lastName=lastName;
                }
            })
            fetch(global.config.settings.wsURL + 'admin/UpdateUser',
            {
                method: "POST",
                headers: { 
                    'Content-Type': 'application/json',
                    authorization: props.user.token,
                    showCode: props.showCode, 
                    
                },
                body: JSON.stringify(body)
            }
            )
            .then(function (response) {
                if (!response.ok) {
                    console.log("Status " + response.status);

                    return null;
                }
                return response.json();
            })
            .then(data => { 
                if (data) {
                
                    if (data.status != 'success') {
                        setAlertMsg(data.message);
                         
                    } else {
                        props.setEditUsername(null);
                    }
                }
            }).catch(error => {
                props.setGlobalError("There was a problem accessing the Internet. Please check your connection and try again.");
                props.setEditUsername(null);
                console.error('Error:', error);
              });
        }
    }

    useEffect(() => {
        if (props.editUsername == 'oqiroqofjeoqfejio') {
            setDisableUsername(false);
        }
        else {
            props.userList.map((user) => {
                if (user.username === props.editUsername) {
                    setUsername(user.username);
                    setFirstName(user.firstName);
                    setLastName(user.lastName);
                }
            })
        }
    },[])

    return(
        <Modal   onHide={handleClose} show={showModal}> 
        <Modal.Header closeButton>
            Editing User 
        </Modal.Header>
            
        <Modal.Body style={{padding: "0px"}}  >
            <Container>
                {alertMsg &&
                <Row><Col>
                <Alert   variant={"danger"}>
                    {alertMsg}
                </Alert>
                </Col></Row>}

                <Row>
                    <Col>
                        <Form.Group controlId={"username"}>
                            <Form.Label>Username</Form.Label>
                            <Form.Control disabled={disableUsername}  onChange={(e)=>setUsername(e.target.value)}
                                value={username} type="Text"    /> 
                            </Form.Group>
                    </Col>
                </Row> 
                <Row>
                    <Col>
                        <Form.Group controlId={"firstName"}>
                            <Form.Label>First name</Form.Label>
                            <Form.Control    onChange={(e)=>setFirstName(e.target.value)}
                                value={firstName} type="Text"    /> 
                            </Form.Group>
                    </Col>
                </Row> 
                <Row>
                    <Col>
                        <Form.Group controlId={"lastName"}>
                            <Form.Label>Last name</Form.Label>
                            <Form.Control   onChange={(e)=>setLastName(e.target.value)}
                                value={lastName} type="Text"    /> 
                            </Form.Group>
                    </Col>
                </Row> 
                { props.editUsername == 'oqiroqofjeoqfejio' && 
                <Row>
                <Col>
                    <Form.Group controlId={"password"}>
                        <Form.Label>Access code</Form.Label>
                        <Form.Control   onChange={(e)=>setPassword(e.target.value)}
                            value={password} type="Text"    /> 
                        </Form.Group>
                </Col>
                </Row>
                }
                </Container>
            </Modal.Body>
            <Modal.Footer style={{pading: "0px"}}>
        <Button
                    onClick={(e) => save(e) }
                >Save</Button>
             <Button
                    onClick={() => handleClose() }
                >Close</Button>
        </Modal.Footer>
        </Modal>
    )  

}

export default EditUser;