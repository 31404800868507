import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";

import "./App.css";
import Login from "./views/login/login";
import Home from "./views/home/home";
import ShowList from "./views/showList/showList";

import Users from "./views/users/users";
import { Button, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import ScanUtilitiesMain from "./views/scanUtilities/scanUtilitiesMain";
import DailyScanModal from "./views/dailyScanModal/dailyScanModal";
import DailyScanPC from "./views/dailyScanPC/dailyScanPC";
import SessionScanPC from "./views/sessionScanPC/sessionScanPC";
import SessionScanMobile from "./views/sessionScanMobile/sessionScanMobile";
import ViewCustomers from "./views/viewCustomers/viewCustomers";
import NewCustomer from "./views/NewCustomer/newCustomer";

function App() {
  const [user, setUser] = useState(null);
  const [showCode, setShowCode] = useState(null);
  const [showName, setShowName] = useState(null);
  const [holdAll, setHoldAll] = useState(true);
  const [globalError, setGlobalError] = useState(null);

  useEffect(() => {
    if (user) {
      localStorage.setItem("adsfdfsasdfdfsa", JSON.stringify(user));
      localStorage.setItem(
        "adsfdfsasdfdfsaCUstomerID",
        user.customerOrganizationID
      );
    }
  }, [user]);
  useEffect(() => {
    if (localStorage.getItem("adsfdfsasdfdfsa") != null) {
      setUser(JSON.parse(localStorage.getItem("adsfdfsasdfdfsa")));
    }
    setHoldAll(false);
  }, []);
  const LoginWrapper = ({ setGlobalError, setUser }) => {
    const { CustomerID } = useParams();
    return (
      <Login
        setGlobalError={setGlobalError}
        setUser={setUser}
        CustomerID={CustomerID}
      />
    );
  };
  function SessionScanMobileWrapper({ user, showCode }) {
    let { SessionID } = useParams();

    return (
      <SessionScanMobile
        user={user}
        showCode={showCode}
        sessionID={SessionID}
      />
    );
  }
  // define similar wrappers for other components that use match.params
  function SessionScanPCWrapper({ user, showCode }) {
    let { SessionID } = useParams();

    return (
      <SessionScanPC user={user} showCode={showCode} sessionID={SessionID} />
    );
  }
  useEffect(() => {
    if (showCode) {
      localStorage.setItem("adsfdfsasdfdfsashowcode", showCode);
    }
  }, [showCode]);

  useEffect(() => {
    if (localStorage.getItem("adsfdfsasdfdfsashowcode") != null) {
      setShowCode(localStorage.getItem("adsfdfsasdfdfsashowcode"));
    }
  }, []);

  return (
    <div className="App">
      {globalError != null && (
        <Alert variant={"danger"}>
          <h4>{globalError}</h4>

          <Button
            bsPrefix="btn-omni"
            onClick={(e) => {
              e.preventDefault();
              setGlobalError(null);
            }}
          >
            Try Again
          </Button>
        </Alert>
      )}
      {holdAll === false && globalError === null && (
        <Router>
          {user === null ? (
            <Routes>
              <Route
                path="/admin/NewCustomer"
                element={
                  <NewCustomer setUser={setUser} setShowName={setShowName} />
                }
              />
              <Route
                path="/admin/ViewCustomers"
                element={
                  <ViewCustomers setUser={setUser} setShowName={setShowName} />
                }
              />
              <Route
                path="/:CustomerID"
                element={
                  <LoginWrapper
                    setGlobalError={setGlobalError}
                    setUser={setUser}
                  />
                }
              />
            </Routes>
          ) : (
            <Routes>
              {/* similar changes for other routes */}
              <Route
                path="/home/ScanUtilitiesMain/SessionScanPC/:SessionID"
                element={
                  <SessionScanPCWrapper user={user} showCode={showCode} />
                }
              />
              <Route
                path="/NewCustomer"
                element={
                  <NewCustomer setUser={setUser} setShowName={setShowName} />
                }
              />
              <Route
                path="/ViewCustomers"
                element={
                  <ViewCustomers setUser={setUser} setShowName={setShowName} />
                }
              />
              <Route
                path="/ShowList"
                element={
                  <ShowList
                    setUser={setUser}
                    setShowName={setShowName}
                    setGlobalError={setGlobalError}
                    setShowCode={setShowCode}
                    user={user}
                  />
                }
              />
              <Route
                path="/home"
                element={
                  <Home
                    user={user}
                    setUser={setUser}
                    showName={showName}
                    setGlobalError={setGlobalError}
                    showCode={showCode}
                  />
                }
              />
              <Route
                path="/users"
                element={
                  <Users
                    setUser={setUser}
                    setShowCode={setShowCode}
                    setGlobalError={setGlobalError}
                    user={user}
                  />
                }
              />
              <Route
                path="/home/ScanUtilitiesMain/DailyScanPC"
                element={<DailyScanPC user={user} showCode={showCode} />}
              />
              <Route
                path="/home/ScanUtilitiesMain/DailyScan"
                element={<DailyScanModal user={user} showCode={showCode} />}
              />
              <Route
                path="/home/ScanUtilitiesMain/SessionScanPC/:SessionID"
                element={
                  <SessionScanPCWrapper user={user} showCode={showCode} />
                }
              />
              `{" "}
              <Route
                path="/home/ScanUtilitiesMain/SessionScanMobile/:SessionID"
                element={
                  <SessionScanMobileWrapper user={user} showCode={showCode} />
                }
              />
              `
              <Route
                path="/home/ScanUtilitiesMain"
                element={<ScanUtilitiesMain showCode={showCode} user={user} />}
              />
              <Route
                path="/:CustomerID"
                element={
                  <ShowList
                    setUser={setUser}
                    setShowCode={setShowCode}
                    setShowName={setShowName}
                    setGlobalError={setGlobalError}
                    user={user}
                  />
                }
              />
            </Routes>
          )}
        </Router>
      )}
    </div>
  );
}

export default App;
