import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {  Button, Form, FormControl, Container, Row, Col, Table, Modal, Alert, Card } from 'react-bootstrap';
 
import Styles from './users.module.css';

function ChangeAccessCodeModal(props) {
    const handleClose = () => {
        setShowModal(false); 
        props.setEditPasswordUsername(null)
    }
    
    const [showModal, setShowModal] = useState(true);
    const [disableUsername, setDisableUsername] = useState(true);
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');
    const [alertMsg, setAlertMsg] = useState(null);

    function save(e) {
        e.preventDefault(); 
     
        var body;
        props.userList.map((user) => {
            if (user.username === props.editPasswordUsername) {
                body=JSON.parse(JSON.stringify(user));
                body.password=password;
            
            }
        })
        fetch(global.config.settings.wsURL + 'admin/UpdateUser',
        {
            method: "POST",
            headers: { 
                'Content-Type': 'application/json',
                authorization: props.user.token,
                showCode: props.showCode, 
                
            },
            body: JSON.stringify(body)
        }
        )
        .then(function (response) {
            if (!response.ok) {
                console.log("Status " + response.status);

                return null;
            }
            return response.json();
        })
        .then(data => { 
            if (data) {
            
                if (data.status != 'success') {
                    setAlertMsg(data.message)
                } else {
                    props.setEditPasswordUsername(null);
                }
            }
        }).catch(error => {
            props.setGlobalError("There was a problem accessing the Internet. Please check your connection and try again.");
            props.setEditPasswordUsername(null);
            console.error('Error:', error);
          });

        
    }

    useEffect(() => {
        setUsername(props.editPasswordUsername); 
    },[])

    return(
        <Modal   onHide={handleClose} show={showModal}> 
        <Modal.Header closeButton>
            Editing User 
        </Modal.Header>
            
        <Modal.Body style={{padding: "0px"}}  >
            <Container>
            {alertMsg &&
                <Row><Col>
                <Alert   variant={"danger"}>
                    {alertMsg}
                </Alert>
                </Col></Row>}
                <Row>
                    <Col>
                        <Form.Group controlId={"username"}>
                            <Form.Label>Username</Form.Label>
                            <Form.Control disabled={true}  
                                value={username} type="Text"    /> 
                            </Form.Group>
                    </Col>
                </Row> 
 
                 
                <Row>
                <Col>
                    <Form.Group controlId={"password"}>
                        <Form.Label>Access code</Form.Label>
                        <Form.Control   onChange={(e)=>setPassword(e.target.value)}
                            value={password} type="Text"    /> 
                        </Form.Group>
                </Col>
                </Row>
   
                </Container>
            </Modal.Body>
            <Modal.Footer style={{pading: "0px"}}>
        <Button
                    onClick={(e) => save(e) }
                >Save</Button>
             <Button
                    onClick={() => handleClose() }
                >Close</Button>
        </Modal.Footer>
        </Modal>
    )  

}

export default ChangeAccessCodeModal;