import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image, Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/img/Logo.png";
import Select, { components } from "react-select";

function ScanUtilitiesMain(props) {
  const [sessions, setSessions] = useState(null);
  const [selectedSession, setSelectedSession] = useState(null);

  useEffect(() => {
    //Get sessions
    fetch(global.config.settings.wsURL + "admin/GetSessions", {
      method: "GET",
      headers: {
        accept: "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          let sess = [];
          data.map((item) => {
            sess.push({
              value: item.code,
              label: item.name,
            });
          });

          setSessions(sess);
        }
      })
      .catch((error) => {
        props.setGlobalError(
          "There was a problem accessing the Internet. Please check your connection and try again."
        );

        console.error("Error:", error);
      });
  }, []);

  const navigate = useNavigate();

  const handleSubmit = (newPath) => {
    navigate(newPath);
  };
  return (
    <Container>
      <Row>
        <Col md={3}></Col>
        <Col md={6}>
          <h1>{props.showCode} Scanning Utilities</h1>
        </Col>
        <Col md={3}></Col>
      </Row>

      <Row>
        <Col md={3}></Col>
        <Col md={6}>
          <Card className="text-left">
            <Card.Header>Daily Checkins</Card.Header>
            <Card.Body>
              <Card.Title>
                Use this section to gather daily attendance information without
                printing a badge.
              </Card.Title>
              <Card.Text>
                Scanning may be done using a smartphone or using a pc with an
                external barcode reader.
                <br />
                Note that using a smartphone requires the use of QR codes.
              </Card.Text>
              <Button
                variant="primary"
                onClick={(e) => {
                  let path = "./DailyScan/";
                  handleSubmit(path);
                }}
              >
                Scan using a smartphone
              </Button>
              <Card.Text>
                Additionaly, you may scan using a PC or iPad with a connected
                physical scanner
              </Card.Text>
              <Button
                variant="primary"
                onClick={(e) => {
                  let path = "./DailyScanPC/";
                  handleSubmit(path);
                }}
              >
                Scan using a PC or iPad
              </Button>
            </Card.Body>
            <Card.Footer className="text-muted"></Card.Footer>
          </Card>
        </Col>
        <Col md={3}></Col>
      </Row>
      <Row>
        <Col>&nbsp;</Col>
      </Row>

      {sessions != null && (
        <Row>
          <Col md={3}></Col>
          <Col md={6}>
            <Card className="text-left">
              <Card.Header>Session Scanning</Card.Header>
              <Card.Body>
                <Card.Title>
                  Use this section to scan attendees as they enter or leave a
                  session.
                </Card.Title>
                <Card.Text>
                  Session scanning may be done using a smartphone's camera or
                  using a pc with an external barcode reader.
                  <br />
                  Note that using a smartphone requires the use of QR codes.{" "}
                  <br />
                  In order to scan, you must first select a session to scan for
                  from the list below.
                </Card.Text>
                <Card.Text>
                  <Select
                    placeholder={"Select a session"}
                    onChange={(value) => setSelectedSession(value.value)}
                    options={sessions}
                  />
                </Card.Text>
                {selectedSession && (
                  <>
                    <Card.Text>
                      <Button
                        variant="primary"
                        onClick={(e) => {
                          let path = "./SessionScanMobile/" + selectedSession;
                          handleSubmit(path);
                        }}
                      >
                        Scan {selectedSession} using a smartphone
                      </Button>
                      <Card.Text>--OR--</Card.Text>
                    </Card.Text>
                    <Card.Text>
                      <Button
                        variant="primary"
                        onClick={(e) => {
                          let path = "./SessionScanPC/" + selectedSession;
                          handleSubmit(path);
                        }}
                      >
                        Scan {selectedSession} using a PC or iPad
                      </Button>
                    </Card.Text>
                  </>
                )}
              </Card.Body>
              <Card.Footer className="text-muted"></Card.Footer>
            </Card>
          </Col>
          <Col md={3}></Col>
        </Row>
      )}
    </Container>
  );
}

export default ScanUtilitiesMain;
