import React, { useState } from "react";
import { Container, Row, Col, Button, Form, Alert } from "react-bootstrap";
function NewCustomer(props) {
  const [password, setPassword] = useState("");
  const [newSitePassword, setNewSitePassword] = useState("");
  const [kioskCode, setKioskCode] = useState("");
  const [showError, setShowError] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [newCompanyName, setNewCompanyName] = useState("");
  const [showLogin, setShowLogin] = useState(false);
  const [newCustomerID, setnewCustomerID] = useState(null);

  const logout = (e, custId) => {
    if (e != null) {
      e.preventDefault();
    }
    localStorage.setItem("adsfdfsasdfdfsa", null);
    localStorage.setItem("adsfdfsasdfdfsashowcode", null);
    props.setUser(null);
    window.location.href = "/" + custId;
  };

  const addShow = (e) => {
    if (password == "" || newSitePassword == "" || newCompanyName == "") {
      setShowError(true);
      setAlertText("Fill out all fields");
      return;
    }
    if (e) {
      e.preventDefault();
    }
    const body = {
      iD: 0,
      organizationName: newCompanyName,
      kioskCode: kioskCode,
      address1: "",
      address2: "",
      city: "",
      state: "",
      province: "",
      postalCode: "",
      country: "",

      shows: [],
    };
    fetch(global.config.settings.wsURL + "Admin/AddCustomer", {
      method: "POST",
      headers: {
        accept: "application/json",
        authorization: password,
        password: newSitePassword,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (!response.ok) {
          setAlertText(
            "Ooops.  There was an Error.  Check your information and resubmit."
          );
          setShowError(true);
          return null;
        }
        return response.json();
      })
      .then((data) => {
        setAlertText(data.message);
        if (data.status === "success") {
          setShowLogin(true);
          setnewCustomerID(data.message);
          setAlertText(
            newCompanyName + " was added as customer number " + data.message
          );
        } else {
          setAlertText(data.message);
        }
        setShowError(true);
      })
      .catch((error) => {
        setAlertText(
          "There was a problem accessing the Internet. Please check your connection and try again."
        );
        setShowError(true);
        console.error("Error:", error);
      });
  };

  return (
    <Container>
      {showError === true && (
        <Row>
          <Col></Col>
          <Col>
            <Alert
              key="Alert"
              variant="info"
              style={{ width: "100%" }}
              aria-label="Alert Message"
              size="md"
            >
              {" "}
              {alertText}{" "}
            </Alert>
          </Col>
          <Col></Col>
        </Row>
      )}
      {showLogin === false && (
        <>
          <Row>
            <Col></Col>
            <Col>
              <Form.Group>
                <Form.Label>New Organization Name</Form.Label>
                <Form.Control
                  value={newCompanyName}
                  type="text"
                  placeholder="Organization Name"
                  aria-label="Organization Name"
                  onChange={(e) => setNewCompanyName(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col></Col>
          </Row>
          <Row>
            <Col></Col>
            <Col>
              <Form.Group>
                <Form.Label>Access code</Form.Label>
                <Form.Control
                  value={password}
                  type="password"
                  placeholder="Password"
                  aria-label="Password"
                  onChange={(e) => setPassword(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col></Col>
          </Row>
          <Row>
            <Col></Col>
            <Col>
              <Form.Group>
                <Form.Label>
                  Kiosk code (Used for accessing shows from the kiosk)
                </Form.Label>
                <Form.Control
                  value={kioskCode}
                  type="test"
                  placeholder="Kiosk Code"
                  aria-label="Kiosk Code"
                  onChange={(e) => setKioskCode(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col></Col>
          </Row>
          <Row>
            <Col></Col>
            <Col>
              <Form.Group>
                <Form.Label>New website password</Form.Label>
                <Form.Control
                  value={newSitePassword}
                  type="password"
                  placeholder="New website password"
                  aria-label="New website password"
                  onChange={(e) => setNewSitePassword(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col></Col>
          </Row>
          <Row>
            <Col></Col>
            <Col>
              <Button onClick={(e) => addShow(e)}>Create Show!</Button>
            </Col>
            <Col></Col>
          </Row>
        </>
      )}
      {showLogin === true && (
        <Row>
          <Col></Col>
          <Col>
            <Button onClick={(e) => logout(e, newCustomerID)}>Log In!</Button>
          </Col>
          <Col></Col>
        </Row>
      )}
    </Container>
  );
}
export default NewCustomer;
