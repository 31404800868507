import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Table,
  Form,
  Card,
  Accordion,
  Alert,
} from "react-bootstrap";

const registrationTypeTemplate = {
  accessCode: null,
  alternateFNameText: null,
  alternateLNameText: null,
  code: "",
  description: "",
  disabled: false,
  displaySeq: 0,
  imisValidateField: null,
  isExhType: false,
  isGuest: false,
  memberImportField: null,
  name: "",
  showRequireOnlyFirstReg: false,
  validateInIMIS: false,
  verificationErrorMessage: null,
  verificationMessage: null,
  hasPDFTemplate: false,
};

function RegistrationTypes(props) {
  const [editIDs, setEditIDs] = useState([]);
  const [registrationTypes, setRegistrationTypes] = useState([]);
  const [newRegistrationType, setNewRegistrationType] = useState(
    registrationTypeTemplate
  );

  const [uploadRegistrationTypeCode, setUploadRegistrationTypeCode] =
    useState("");
  const newCodeRef = useRef();
  const newNameRef = useRef();
  const newDescriptionRef = useRef();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [fileMessage, setFileMessage] = useState("Select a pdf template");
  const [uploadSuccess, setUploadSuccess] = useState("");
  const [successMessage, setSuccessMessage] = useState(null);
  const [errMsg, setErrMsg] = useState(null);

  const getTemplate = (e, code) => {
    fetch(global.config.settings.wsURL + "admin/GetPDFTemplate", {
      method: "GET",
      headers: {
        accept: "*/*",
        authorization: props.user.token,
        showCode: props.showCode,
        code: code,
      },
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.blob();
      })
      .then((data) => {
        if (data) {
          const blob = new Blob([data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = code + ".pdf";
          link.click();
        } else {
          alert("No badge template defined for this attendee.");
        }
      });
  };
  const submitHandler = (e, code) => {
    e.preventDefault(); //prevent the form from submitting

    const formData = new FormData();

    formData.append("File", selectedFile);

    fetch(global.config.settings.wsURL + "admin/SetTemplate", {
      method: "POST",
      headers: {
        authorization: props.user.token,
        showCode: props.showCode,
        registrationTypeCode: code,
      },
      body: formData,
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (data.status === "success") {
            setUploadSuccess("Imported successfully.");
            setFileMessage("Select a pdf file");
            setSelectedFile(null);
            setFileMessage(null);
            setIsFilePicked(false);
            setUploadRegistrationTypeCode("");
          }
          setFileMessage("Select a pdf file");
          getRegistrationType();
        }
      });
  };
  const changeHandler = (event, code) => {
    const name = event.target.files[0].name.toLowerCase();

    if (name.includes(".pdf") === false) {
      setSelectedFile(null);
      setFileMessage(null);
      setIsFilePicked(false);

      setErrMsg(
        "Invalid file type.  Imported files must be in the pdf format."
      );
      setSuccessMessage(null);
      return;
    } else setSelectedFile(event.target.files[0]);
    setFileMessage(event.target.files[0].name);
    setIsFilePicked(true);
    setSuccessMessage("Template was uploaded successfully.");
    setErrMsg(null);
  };

  const editValues = useRef([]);
  function saveRegistrationType(e, registrationTypeCode) {
    e.preventDefault();
    alert(registrationTypeCode);
  }
  function cancelEdit(e, registrationCode) {
    let newArray = [...editIDs];
    delete newArray[newArray.indexOf(registrationCode)];
    setEditIDs(newArray);
  }
  function nameChange(e, registrationTypeCode) {
    editValues.current.map((val) => {
      if (val.code === registrationTypeCode) {
        val.name = e.target.value;
      }
    });
  }
  function descriptionChange(e, registrationTypeCode) {
    editValues.current.map((val) => {
      if (val.code === registrationTypeCode) {
        val.description = e.target.value;
      }
    });
  }

  function getRegistrationType() {
    //Get sessions
    fetch(global.config.settings.wsURL + "admin/GetRegistrationTypes", {
      method: "GET",
      headers: {
        accept: "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          setRegistrationTypes(data);
        }
      });
  }
  function postRegistrationType(registrationType) {
    if (registrationType.code == null) {
      alert("Please enter a code for this badge type.");
      return;
    }
    if (registrationType.name == null) {
      alert("Please enter a name for this badge type.");
      return;
    }
    if (registrationType.description == null) {
      alert("Please enter a description for this badge type.");
      return;
    }

    fetch(global.config.settings.wsURL + "admin/UpdateRegistrationType", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },
      body: JSON.stringify(registrationType),
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);
          setErrMsg("Could not update " + response.status);
          setSuccessMessage(null);
          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (data.status === "success") {
            setSuccessMessage(data.message);
            setErrMsg(null);
          } else {
            let str = data.message.replace(/(\r\n|\n|\r)/g, "<br />");
            setErrMsg(str);
            setSuccessMessage(null);
          }
          props.setRefreshRegistrationTypes(true);
          getRegistrationType();
        }
      });
  }

  function saveRegistrationType(e, registrationTypeCode) {
    e.preventDefault();
    editValues.current.map((registrationType) => {
      if (registrationType.code == registrationTypeCode) {
        postRegistrationType(JSON.parse(JSON.stringify(registrationType)));
        let origRegistrationTypes = JSON.parse(
          JSON.stringify(registrationTypes)
        );

        //get index for this

        registrationTypes.map((rType, cnt) => {
          if (rType.code == registrationTypeCode) {
            origRegistrationTypes[cnt] = JSON.parse(
              JSON.stringify(registrationType)
            );
          }
        });

        let newEditValues = [];
        editValues.current.map((editVal) => {
          if (editVal.code != registrationTypeCode) {
            newEditValues.push(JSON.parse(JSON.stringify(editVal)));
          }
        });
        editValues.current = newEditValues;

        let newArray = [...editIDs];
        delete newArray[newArray.indexOf(registrationTypeCode)];

        setEditIDs(newArray);

        setRegistrationTypes(origRegistrationTypes);
      }
    });
  }

  function deleteRegistrationType(e, registrationTypeCode) {
    e.preventDefault();
    var conf = window.confirm(
      "Are you sure you want to delete " + registrationTypeCode + "?"
    );
    if (!conf) {
      return;
    }
    fetch(global.config.settings.wsURL + "admin/DeleteRegistrationType", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
        registrationTypeCode: registrationTypeCode,
      },
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);
          setErrMsg("Could not delete " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          props.setRefreshRegistrationTypes(true);
          if (data.status != "success") {
            let str = data.message.replace(/(\r\n|\n|\r)/g, "<br />");
            setErrMsg(str);
            setSuccessMessage(null);

            return;
          } else {
            setSuccessMessage("Registration type was successfully deleted.");
            setErrMsg(null);
            processDeletion(registrationTypeCode);
          }
        }
      });
  }

  function processDeletion(registrationTypeCode) {
    let origRegTypes = [];

    //get index for this

    registrationTypes.map((registrationType) => {
      if (registrationType.code != registrationTypeCode) {
        origRegTypes.push(JSON.parse(JSON.stringify(registrationType)));
      }
    });

    let newEditValues = [];
    editValues.current.map((editVal) => {
      if (editVal.code != registrationTypeCode) {
        newEditValues.push(JSON.parse(JSON.stringify(editVal)));
      }
    });
    editValues.current = newEditValues;

    let newArray = [...editIDs];
    delete newArray[newArray.indexOf(registrationTypeCode)];
    setEditIDs(newArray);

    setRegistrationTypes(origRegTypes);
  }
  function editRegistrationType(e, registrationTypeCode) {
    e.preventDefault();
    let newArray = [...editIDs];
    newArray.push(registrationTypeCode);

    setEditIDs(newArray);
    registrationTypes.map((registrationType) => {
      if (registrationType.code === registrationTypeCode) {
        editValues.current.push(JSON.parse(JSON.stringify(registrationType)));
      }
    });
  }
  function addNewRegistrationType() {
    let rType = JSON.parse(JSON.stringify(newRegistrationType));

    if (rType.code == null) {
      alert("Please enter a code for this badge type.");
      return;
    }
    if (rType.code == "") {
      alert("Please enter a code for this badge type.");
      return;
    }
    if (rType.name == null) {
      alert("Please enter a name for this badge type.");
      return;
    }
    if (rType.description == null) {
      alert("Please enter a description for this badge type.");
      return;
    }

    fetch(global.config.settings.wsURL + "admin/UpdateRegistrationType", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },
      body: JSON.stringify(rType),
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);
          setErrMsg("Could not delete " + response.status);
          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          props.setRefreshRegistrationTypes(true);
          if (data.status === "success") {
            newNameRef.current.value = "";
            newDescriptionRef.current.value = "";

            newCodeRef.current.value = "";
            let r = JSON.parse(JSON.stringify(registrationTypeTemplate));
            setSuccessMessage(data.message);
            setErrMsg(null);

            setNewRegistrationType(r);
          } else {
            let str = data.message.replace(/(\r\n|\n|\r)/g, "<br />");
            setErrMsg(str);
            setSuccessMessage(null);
          }
          getRegistrationType();
        }
      });
  }
  function newRegistrationTypeChange(e, key) {
    let newRegistrationTypeHolder = JSON.parse(
      JSON.stringify(newRegistrationType)
    );
    newRegistrationTypeHolder[key] = e.target.value;
    setNewRegistrationType(newRegistrationTypeHolder);
  }

  useEffect(() => {
    getRegistrationType();
  }, []);

  return (
    <>
      <Container style={{ paddingTop: 20, paddingBottom: 20 }} fluid>
        <Row>
          <Col>
            {errMsg && (
              <Alert variant={"danger"}>
                {" "}
                <div dangerouslySetInnerHTML={{ __html: errMsg }}></div>{" "}
              </Alert>
            )}
            {successMessage && (
              <Alert variant={"success"}>{successMessage}</Alert>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Add a single track</Accordion.Header>
                <Accordion.Body>
                  <Card.Body>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Code</th>
                          <th>Name</th>
                          <th>Description</th>

                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <td>
                          <Form.Group controlId={"NewCodeText"}>
                            <Form.Control
                              ref={newCodeRef}
                              onChange={(e) =>
                                newRegistrationTypeChange(e, "code")
                              }
                              type="text"
                            />
                            <Form.Text muted>
                              This is the badge type's unique ID. It must be
                              between 5 and 50 characters.
                            </Form.Text>
                          </Form.Group>
                        </td>
                        <td>
                          <Form.Group controlId={"newNameTextArea"}>
                            <Form.Control
                              ref={newNameRef}
                              onChange={(e) =>
                                newRegistrationTypeChange(e, "name")
                              }
                              as="textarea"
                              rows={2}
                            />
                            <Form.Text muted>
                              This is the badge type's name. It must be between
                              5 and 250 characters.
                            </Form.Text>
                          </Form.Group>
                        </td>

                        <td>
                          <Form.Group controlId={"newDescriptionTextArea"}>
                            <Form.Control
                              ref={newDescriptionRef}
                              onChange={(e) =>
                                newRegistrationTypeChange(e, "description")
                              }
                              as="textarea"
                              rows={5}
                            />
                            <Form.Text muted>
                              This is the badge type's description. It must be
                              between 5 and 1000 characters.
                            </Form.Text>
                          </Form.Group>
                        </td>

                        <td>
                          <Button
                            variant="success"
                            style={{ width: "100%" }}
                            onClick={(e) => addNewRegistrationType()}
                          >
                            Add
                          </Button>
                        </td>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Code</th>
            <th>Name</th>
            <th>Description</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {registrationTypes.map((registrationType) => {
            return (
              <tr key={registrationType.code}>
                <td width="5%">{registrationType.code}</td>

                {editIDs.includes(registrationType.code) ? (
                  <td width="15%">
                    <Form.Group
                      controlId={registrationType.code + "-NameTextArea"}
                    >
                      <Form.Control
                        onChange={(e) => nameChange(e, registrationType.code)}
                        as="textarea"
                        defaultValue={registrationType.name}
                        rows={3}
                      />
                    </Form.Group>
                  </td>
                ) : (
                  <td width="15%">{registrationType.name}</td>
                )}

                {editIDs.includes(registrationType.code) ? (
                  <td width="25%">
                    <Form.Group
                      controlId={registrationType.code + "-DescriptionTextArea"}
                    >
                      <Form.Control
                        onChange={(e) =>
                          descriptionChange(e, registrationType.code)
                        }
                        as="textarea"
                        defaultValue={registrationType.description}
                        rows={3}
                      />
                    </Form.Group>
                  </td>
                ) : (
                  <td width="25%">{registrationType.description}</td>
                )}

                {registrationType.hasPDFTemplate ? (
                  <td width="10%">
                    <Button
                      variant="success"
                      style={{ width: "100%" }}
                      onClick={(e) => getTemplate(e, registrationType.code)}
                    >
                      View Template
                    </Button>
                  </td>
                ) : (
                  <td width="10%">
                    <Button
                      variant="success"
                      style={{ width: "100%" }}
                      disabled
                      onClick={(e) =>
                        saveRegistrationType(e, registrationType.code)
                      }
                    >
                      No Template
                    </Button>
                  </td>
                )}
                {uploadRegistrationTypeCode == registrationType.code ? (
                  <td width="20%">
                    <Container
                      style={{
                        width: "100%",
                        marginLeft: "0px",
                        marginRight: "0px",
                        paddingLeft: "0px",
                      }}
                    >
                      <Row>
                        <Col>
                          <Form>
                            <Form.Control
                              type="file"
                              onChange={(e) =>
                                changeHandler(e, registrationType.code)
                              }
                              isValid
                            />
                            <Form.Label data-browse="Browse">
                              {fileMessage}
                            </Form.Label>
                            <Form.Control.Feedback type="valid">
                              {uploadSuccess}
                            </Form.Control.Feedback>

                            <Form.Group>
                              {selectedFile && (
                                <>
                                  <Button
                                    variant="info"
                                    style={{
                                      marginTop: "5px",
                                      marginBottom: "5px",
                                    }}
                                    onClick={(e) =>
                                      submitHandler(e, registrationType.code)
                                    }
                                  >
                                    Upload
                                  </Button>
                                  <p>File name: {selectedFile.name}</p>
                                  <p>File type: {selectedFile.type}</p>
                                  <p>Size in bytes: {selectedFile.size}</p>
                                </>
                              )}
                            </Form.Group>
                          </Form>
                        </Col>
                      </Row>
                    </Container>{" "}
                  </td>
                ) : (
                  <td width="10%">
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        setUploadRegistrationTypeCode(registrationType.code);
                      }}
                    >
                      Upload Template
                    </Button>
                  </td>
                )}

                {editIDs.includes(registrationType.code) ? (
                  <>
                    <td width="5%">
                      <Button
                        variant="success"
                        style={{ width: "100%" }}
                        onClick={(e) =>
                          saveRegistrationType(e, registrationType.code)
                        }
                      >
                        Save
                      </Button>
                    </td>
                    <td width="5%">
                      <Button
                        block
                        onClick={(e) => cancelEdit(e, registrationType.code)}
                      >
                        Cancel
                      </Button>
                    </td>
                  </>
                ) : (
                  <>
                    <td width="5%">
                      <Button
                        variant="success"
                        style={{ width: "100%" }}
                        onClick={(e) =>
                          editRegistrationType(e, registrationType.code)
                        }
                      >
                        Edit
                      </Button>
                    </td>
                    <td width="5%">
                      <Button
                        block
                        onClick={(e) =>
                          deleteRegistrationType(e, registrationType.code)
                        }
                      >
                        Delete
                      </Button>
                    </td>
                  </>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

export default React.memo(RegistrationTypes);
