import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Table,
  Form,
  Card,
  Accordion,
  Alert,
} from "react-bootstrap";
import DatePicker from "react-datetime";
import moment from "moment";
import "react-datetime/css/react-datetime.css";

const trackTemplate = {
  showCode: "",
  customerOrganizationID: "",
  code: "",
  name: "",
  description: null,
};
function SessionTrack(props) {
  const [tracks, setTracks] = useState([]);
  const [editIDs, setEditIDs] = useState([]);
  const [newTrack, setNewTrack] = useState(trackTemplate);
  const editValues = useRef([]);

  const newCodeRef = useRef();
  const newNameRef = useRef();
  const newDescriptionRef = useRef();

  const [successMessage, setSuccessMessage] = useState(null);
  const [errMsg, setErrMsg] = useState(null);

  function getTracks() {
    //Get sessions
    fetch(global.config.settings.wsURL + "admin/GetSessionTracks", {
      method: "GET",
      headers: {
        accept: "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          setTracks(data);
        }
      })
      .catch((error) => {
        props.setGlobalError(
          "There was a problem accessing the Internet. Please check your connection and try again."
        );

        console.error("Error:", error);
      });
  }

  function nameChange(e, trackCode) {
    editValues.current.map((val) => {
      if (val.code === trackCode) {
        val.name = e.target.value;
      }
    });
  }
  function descriptionChange(e, trackCode) {
    editValues.current.map((val) => {
      if (val.code === trackCode) {
        val.description = e.target.value;
      }
    });
  }
  function capacityChange(e, trackCode) {
    editValues.current.map((val) => {
      if (val.code === trackCode) {
        val.seatsLeft = Number(e.target.value);
      }
    });
  }

  function editTrack(e, trackCode) {
    e.preventDefault();
    let newArray = [...editIDs];
    newArray.push(trackCode);

    setEditIDs(newArray);
    tracks.map((track) => {
      if (tracks.code === trackCode) {
        editValues.current.push(JSON.parse(JSON.stringify(track)));
      }
    });
  }
  function postTrack(track) {
    if (track.code == null) {
      alert("Please enter a code for this track.");
      return;
    }
    if (track.name == null) {
      alert("Please enter a name for this track.");
      return;
    }
    if (track.description == null) {
      alert("Please enter a description for this track.");
      return;
    }

    fetch(global.config.settings.wsURL + "admin/UpdateTrack", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },
      body: JSON.stringify(track),
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (data.status === "success") {
            setSuccessMessage(data.message);
            setErrMsg(null);
          } else {
            setErrMsg(data.message);
            setSuccessMessage(null);
          }
          getTracks();
        }
      })
      .catch((error) => {
        props.setGlobalError(
          "There was a problem accessing the Internet. Please check your connection and try again."
        );

        console.error("Error:", error);
      });
  }
  function saveTrack(e, trackCode) {
    e.preventDefault();
    editValues.current.map((track) => {
      if (track.code == trackCode) {
        postTrack(JSON.parse(JSON.stringify(track)));
        let origTracks = JSON.parse(JSON.stringify(tracks));

        //get index for this

        tracks.map((track, cnt) => {
          if (track.code == trackCode) {
            origTracks[cnt] = JSON.parse(JSON.stringify(track));
          }
        });

        let newEditValues = [];
        editValues.current.map((editVal) => {
          if (editVal.code != trackCode) {
            newEditValues.push(JSON.parse(JSON.stringify(editVal)));
          }
        });
        editValues.current = newEditValues;

        let newArray = [...editIDs];
        delete newArray[newArray.indexOf(trackCode)];

        setEditIDs(newArray);

        setTracks(origTracks);
      }
    });
  }

  function deleteTrack(e, trackCode) {
    e.preventDefault();
    var conf = window.confirm(
      "Are you sure you want to delete " + trackCode + "?"
    );
    if (!conf) {
      return;
    }
    fetch(global.config.settings.wsURL + "admin/DeleteTrack", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
        trackCode: trackCode,
      },
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (data.status === "success") {
            setSuccessMessage(data.message);
            setErrMsg(null);
          } else {
            setErrMsg(data.message);
            setSuccessMessage(null);
          }
        }
      })
      .catch((error) => {
        props.setGlobalError(
          "There was a problem accessing the Internet. Please check your connection and try again."
        );

        console.error("Error:", error);
      });

    let origTracks = [];

    //get index for this

    tracks.map((track) => {
      if (track.code != trackCode) {
        origTracks.push(JSON.parse(JSON.stringify(track)));
      }
    });

    let newEditValues = [];
    editValues.current.map((editVal) => {
      if (editVal.code != trackCode) {
        newEditValues.push(JSON.parse(JSON.stringify(editVal)));
      }
    });
    editValues.current = newEditValues;

    let newArray = [...editIDs];
    delete newArray[newArray.indexOf(trackCode)];
    setEditIDs(newArray);

    setTracks(origTracks);
  }

  function cancelEdit(e, trackCode) {
    let newArray = [...editIDs];
    delete newArray[newArray.indexOf(trackCode)];
    setEditIDs(newArray);
  }
  useEffect(() => {
    getTracks();
  }, []);

  function newTrackChange(e, key) {
    let trackHolder = JSON.parse(JSON.stringify(newTrack));
    trackHolder[key] = e.target.value;
    setNewTrack(trackHolder);
  }

  function addNewTrack() {
    let track = JSON.parse(JSON.stringify(newTrack));

    fetch(global.config.settings.wsURL + "admin/UpdateTrack", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },
      body: JSON.stringify(track),
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (data.status === "success") {
            setSuccessMessage(data.message);
            setErrMsg(null);
          } else {
            setErrMsg(data.message);
            setSuccessMessage(null);
          }
          if (data.status === "success") {
            newNameRef.current.value = "";
            newDescriptionRef.current.value = "";

            newCodeRef.current.value = "";
            let sess = JSON.parse(JSON.stringify(trackTemplate));

            setNewTrack(sess);
            getTracks();
          }
        }
      })
      .catch((error) => {
        props.setGlobalError(
          "There was a problem accessing the Internet. Please check your connection and try again."
        );

        console.error("Error:", error);
      });
  }

  return (
    <>
      <Container style={{ paddingTop: 20, paddingBottom: 20 }} fluid>
        <Row>
          <Col>
            {errMsg && <Alert variant={"danger"}>{errMsg}</Alert>}
            {successMessage && (
              <Alert variant={"success"}>{successMessage}</Alert>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Add a single track</Accordion.Header>
                <Accordion.Body>
                  <Card.Body>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Code</th>
                          <th>Name</th>
                          <th>Description</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <td>
                          <Form.Group controlId={"NewCodeText"}>
                            <Form.Control
                              onChange={(e) => newTrackChange(e, "code")}
                              ref={newCodeRef}
                              type="text"
                            />
                          </Form.Group>
                        </td>
                        <td>
                          <Form.Group controlId={"newNameTextArea"}>
                            <Form.Control
                              ref={newNameRef}
                              onChange={(e) => newTrackChange(e, "name")}
                              as="textarea"
                              rows={3}
                            />
                          </Form.Group>
                        </td>

                        <td>
                          <Form.Group controlId={"newDescriptionTextArea"}>
                            <Form.Control
                              ref={newDescriptionRef}
                              onChange={(e) => newTrackChange(e, "description")}
                              as="textarea"
                              rows={11}
                            />
                          </Form.Group>
                        </td>

                        <td>
                          <Button
                            variant="success"
                            onClick={(e) => addNewTrack()}
                          >
                            Add
                          </Button>
                        </td>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Upload a list of tracks</Accordion.Header>
                <Accordion.Body>
                  <Card.Body>
                    <Container>
                      <h5>Import instructions</h5>
                      <p>Begin by downloading the excel template.</p>
                      <p>Be sure to save the file when downloading.</p>

                      <p>
                        For each session, create a new row in the excel file.
                        <br />
                        All fields are required. The fields are listed below:
                        <p>
                          <ul>
                            <li>
                              Code - this field uniquely identifies your
                              session. It must be less than 50 characters and
                              contain only letters and numbers. No spaces or
                              symbols
                            </li>
                            <li>
                              Name - the name to that will be displayed for this
                              session throughout the registration process. This
                              field may be up to 250 characters long
                            </li>
                            <li>
                              Description - this will be the long description
                              for the session. This field can support up to
                              2,000 characters
                            </li>
                            <li>
                              Begin date - this is the beginning date of your
                              session. It should be in the format of mm/dd/yyy.
                              example: 01/01/2020)
                            </li>
                            <li>
                              Begin time - this is the time that your session
                              will start. It should be in the format of hh:mm:ss
                              pm example: 01:20:00 pm
                            </li>
                            <li>
                              End date - this is the end date of your session.
                              It should be in the format of mm/dd/yyy. example:
                              01/01/2020)
                            </li>
                            <li>
                              End time - this is the time that your session will
                              end. It should be in the format of hh:mm:ss pm
                              example: 01:20:00 pm
                            </li>
                            <li>
                              Capacity - the maximum number of attendees that
                              will be allowed into the session
                            </li>
                          </ul>
                        </p>
                        <p>
                          Once the spreadsheet has been completed, you may
                          import the sessions by uploading the spreadsheet using
                          the 'upload' button below.
                          <br />
                          The system will alert you to any errors.
                        </p>
                      </p>
                      <Container
                        style={{
                          marginLeft: "0px",
                          marginRight: "0px",
                          paddingLeft: "0px",
                        }}
                      >
                        <Row>
                          <Col></Col>
                          <Col></Col>
                          <Col></Col>
                        </Row>
                      </Container>
                    </Container>
                  </Card.Body>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Code</th>
            <th>Name</th>
            <th>Description</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {tracks.map((track) => {
            return (
              <tr key={track.code}>
                <td width="5%">{track.code}</td>

                {editIDs.includes(track.code) ? (
                  <td width="15%">
                    <Form.Group controlId={track.code + "-NameTextArea"}>
                      <Form.Control
                        onChange={(e) => nameChange(e, track.code)}
                        as="textarea"
                        defaultValue={track.name}
                        rows={3}
                      />
                    </Form.Group>
                  </td>
                ) : (
                  <td width="15%">{track.name}</td>
                )}

                {editIDs.includes(track.code) ? (
                  <td width="25%">
                    <Form.Group controlId={track.code + "-DescriptionTextArea"}>
                      <Form.Control
                        onChange={(e) => descriptionChange(e, track.code)}
                        as="textarea"
                        defaultValue={track.description}
                        rows={3}
                      />
                    </Form.Group>
                  </td>
                ) : (
                  <td width="25%">{track.description}</td>
                )}

                {editIDs.includes(track.code) ? (
                  <>
                    <td width="5%">
                      <Button
                        variant="success"
                        style={{ width: "100%" }}
                        onClick={(e) => saveTrack(e, track.code)}
                      >
                        Save
                      </Button>
                    </td>
                    <td width="5%">
                      <Button block onClick={(e) => cancelEdit(e, track.code)}>
                        Cancel
                      </Button>
                    </td>
                  </>
                ) : (
                  <>
                    <td width="5%">
                      <Button
                        variant="success"
                        style={{ width: "100%" }}
                        onClick={(e) => editTrack(e, track.code)}
                      >
                        Edit
                      </Button>
                    </td>
                    <td width="5%">
                      <Button block onClick={(e) => deleteTrack(e, track.code)}>
                        Delete
                      </Button>
                    </td>
                  </>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

export default React.memo(SessionTrack);
