import React, { useState, useRef } from "react";
import {
  Button,
  Modal,
  Alert,
  Container,
  Row,
  Col,
  Card,
} from "react-bootstrap";

import { QrReader } from "react-qr-reader";
import "bootstrap/dist/css/bootstrap.min.css";
import BeepSound from "../../assets/media/Beep.mp3";
import ErrorSound from "../../assets/media/Error.mp3";
import useSound from "use-sound";
import moment from "moment";

function SessionScanMobile(props) {
  const [playBeep] = useSound(BeepSound);
  const [playError] = useSound(ErrorSound);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [showQRScanner, setShowQRScanner] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const handleClose = () => props.setShowModal(false);
  const handleOkVisible = () => {
    playBeep();
    setShowSuccess(true);

    setTimeout(function () {
      setShowSuccess(false);
      setShowQRScanner(true);
    }, 2000);
  };
  const handleErrorVisible = () => {
    setShowError(true);
    playError();

    setTimeout(function () {
      setShowError(false);
      setShowQRScanner(true);
    }, 2000);
  };
  const handleScan = (scannedData) => {
    if (scannedData) {
      console.log(scannedData);
      setShowQRScanner(false);
      fetch(global.config.settings.wsURL + "admin/SessionScan", {
        method: "POST",
        headers: {
          content: "application/json",
          customerOrganizationID: props.user.customerOrganizationID,
          showCode: props.showCode,
          badgeID: scannedData,
          sessionCode: props.sessionID,
          scanTime: moment().toISOString(true), //sends in pc time, not utc
        },
      })
        .then(function (response) {
          if (!response.ok) {
            setErrorMessage("Error: Attendee not found!");
            handleErrorVisible();
            console.log("Status " + response.status);
          } else {
            return response.json();
          }
        })
        .then((data) => {
          console.log(data);
          if (data) {
            if (data.status === "error") {
              setErrorMessage(data.message);
              handleErrorVisible();
            } else {
              setSuccessMessage(data.message);
              handleOkVisible();
            }
          }
        })
        .catch((error) => {
          console.log(error);
          setErrorMessage("Error connecting to the server.");
          handleErrorVisible();
        });
    }
  };
  const handleError = (err) => {
    console.error(err);
  };
  return (
    <Container>
      <Row>
        <Col md={3}></Col>
        <Col md={6}>
          <Card className="text-center">
            <Card.Header>
              <h3>Session Scanning for - {props.sessionID}</h3>
              <br />
              Used for attendees entering and exiting sessions. You must give
              access to phone's camera. If the phone has been locked or only a
              black image is showing, please refresh the page to re-enable the
              camera.
              <br />
              Center the attendee's barcode in the camera viewer below until
              until it is read.
            </Card.Header>
            <Card.Body>
              <Card.Title style={{ border: "1px" }}>
                {showQRScanner && (
                  <QrReader
                    delay={1000}
                    onError={handleError}
                    onScan={handleScan}
                    style={{ width: "100%", height: "20%" }}
                    facingMode={"environment"}
                  />
                )}
                <Alert show={showSuccess} variant={"success"}>
                  <h1>{successMessage}</h1>
                </Alert>
                <Alert show={showError} variant={"danger"}>
                  <h1>{errorMessage}</h1>
                </Alert>
              </Card.Title>
              <Card.Text className="text-center"></Card.Text>
            </Card.Body>
            <Card.Footer className="text-muted"></Card.Footer>
          </Card>
        </Col>
        <Col md={3}></Col>
      </Row>
    </Container>
  );
}

export default SessionScanMobile;
