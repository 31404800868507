import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Table,
  Form,
  Card,
  Accordion,
  Alert,
} from "react-bootstrap";
import DatePicker from "react-datetime";
import moment from "moment";
import "react-datetime/css/react-datetime.css";
import DownloadTemplate from "../../assets/Documents/SessionTemplate.xlsx";
const sessionTemplate = {
  creditHours: 0,
  seatsLeft: 0,
  seatsUsed: 0,
  signedUpNotCheckedIn: 0,
  scannedIn: 0,
  code: "",
  name: "",
  description: null,
  beginTime: null,
  endTime: null,
  roomNumber: null,
  logoImage: null,
  displayOrder: 999,
  selectionConferenceSessionRelationshipTypeID: 0,
  ignoreTimeConflict: false,
  enableWaitList: false,
  videoURL: null,
  videoCCEnglishURL: null,
  videoCCSpanishURL: null,
  enableFastForward: false,
  lengthInSeconds: null,
  requiredWatchLengthInSeconds: null,
  recordedStartTime: null,
  recordedEndTime: null,
  timeZone: null,
  secondsForVerification: null,
  enableQty: false,
};

function SessionSetup(props) {
  const [uploadCEName, setUploadCEName] = useState(null);
  const [uploadSessionName, setUploadSessionName] = useState(null);
  const [sessions, setSessions] = useState([]);
  const [editIDs, setEditIDs] = useState([]);
  const [newSession, setNewSession] = useState(sessionTemplate);
  const editValues = useRef([]);

  const newCodeRef = useRef();
  const newNameRef = useRef();
  const newDescriptionRef = useRef();
  const newBeginTimeRef = useRef();
  const newEndTimeRef = useRef();
  const newCapacityRef = useRef();
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [fileMessage, setFileMessage] = useState("Select an excel file");
  const [uploadSuccess, setUploadSuccess] = useState("");

  const [selectedTemplateFile, setSelectedTemplateFile] = useState();
  const [isTemplateFilePicked, setTemplateIsFilePicked] = useState(false);
  const [fileTemplateMessage, setTemplateFileMessage] =
    useState("Select a pdf file");
  const [selectedCETemplateFile, setCESelectedTemplateFile] = useState();
  const [isCETemplateFilePicked, setCETemplateIsFilePicked] = useState(false);
  const [fileCETemplateMessage, setCETemplateFileMessage] =
    useState("Select a pdf file");
  const [uploadTemplateSuccess, setUploadTemplateSuccess] = useState("");
  const [uploadCETemplateSuccess, setUploadCETemplateSuccess] = useState("");
  const [successMessage, setSuccessMessage] = useState(null);
  const [errMsg, setErrMsg] = useState(null);

  const uploadRef = useRef();

  const submitTemplateHandler = (e, name) => {
    e.preventDefault(); //prevent the form from submitting

    const formData = new FormData();

    formData.append("File", selectedTemplateFile);

    fetch(global.config.settings.wsURL + "admin/SetSessionTemplate", {
      method: "POST",
      headers: {
        authorization: props.user.token,
        showCode: props.showCode,
        sessionCode: uploadSessionName,
      },
      body: formData,
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (data.status === "success") {
            setUploadSuccess("Uploaded successfully.");
            //setTemplateFileMessage("Select a pdf file");
            setSelectedTemplateFile(null);
            setTemplateFileMessage(null);
            setTemplateIsFilePicked(false);
            setUploadSessionName("");
          }
          setTemplateFileMessage("Select a pdf file");
          //getTemplates();
        }
      });
  };
  const submitCETemplateHandler = (e, name) => {
    e.preventDefault(); //prevent the form from submitting

    const formData = new FormData();

    formData.append("File", selectedCETemplateFile);

    fetch(global.config.settings.wsURL + "admin/SetSessionCETemplate", {
      method: "POST",
      headers: {
        authorization: props.user.token,
        showCode: props.showCode,
        sessionCode: uploadCEName,
      },
      body: formData,
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (data.status === "success") {
            setUploadSuccess("Uploaded successfully.");
            //setTemplateFileMessage("Select a pdf file");
            setCESelectedTemplateFile(null);
            setCETemplateFileMessage(null);
            setCETemplateIsFilePicked(false);
            setUploadCEName("");
          }
          setCETemplateFileMessage("Select a pdf file");
          //getTemplates();
        }
      });
  };
  const changeHandler = (event) => {
    if (event.target.files[0]) {
      const name = event.target.files[0].name.toLowerCase();

      if (name.includes(".xlsx") === false) {
        setSelectedFile(null);
        setFileMessage(null);
        setIsFilePicked(false);

        alert(
          "Invalid file type.  Imported files must be in the Excel (xlsx) format."
        );
        return;
      }
      setSelectedFile(event.target.files[0]);
      setFileMessage(event.target.files[0].name);
      setIsFilePicked(true);
    }
  };
  const changeTemplateHandler = (event) => {
    if (event.target.files[0]) {
      const name = event.target.files[0].name.toLowerCase();

      if (name.includes(".pdf") === false) {
        setSelectedTemplateFile(null);
        setTemplateFileMessage(null);
        setTemplateIsFilePicked(false);

        alert("Invalid file type.  Imported files must be in PDF format.");
        return;
      }
      setSelectedTemplateFile(event.target.files[0]);
      setTemplateFileMessage(event.target.files[0].name);
      setTemplateIsFilePicked(true);
    }
  };
  const changeCETemplateHandler = (event) => {
    if (event.target.files[0]) {
      const name = event.target.files[0].name.toLowerCase();

      if (name.includes(".pdf") === false) {
        setCESelectedTemplateFile(null);
        setCETemplateFileMessage(null);
        setCETemplateIsFilePicked(false);

        alert("Invalid file type.  Imported files must be in PDF format.");
        return;
      }

      setCESelectedTemplateFile(event.target.files[0]);
      setCETemplateFileMessage(event.target.files[0].name);
      setCETemplateIsFilePicked(true);
    }
  };
  const submitHandler = (e) => {
    e.preventDefault(); //prevent the form from submitting

    const formData = new FormData();

    formData.append("File", selectedFile);

    fetch(global.config.settings.wsURL + "admin/ImportSessions", {
      method: "POST",
      headers: {
        authorization: props.user.token,
        showCode: props.showCode,
      },
      body: formData,
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          uploadRef.current.files = null;
          uploadRef.current.form.reset();
          alert(data.message);
          if (data.status === "success") {
            setUploadSuccess("Imported successfully.");
            setFileMessage("Select an excel file");
            setSelectedFile(null);
            //setFileMessage(null);
            setIsFilePicked(false);
            getSessions();
          }
          setFileMessage("Select an excel file");
        }
      })
      .catch((error) => {
        props.setGlobalError(
          "There was a problem accessing the Internet. Please check your connection and try again."
        );

        console.error("Error:", error);
      });
  };
  function getSessions() {
    //Get sessions
    fetch(global.config.settings.wsURL + "admin/GetSessions", {
      method: "GET",
      headers: {
        accept: "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          data.map((s) => {
            s.beginTime = moment(s.beginTime).format("MM/DD/YYYY h:mm:ss a");
            s.endTime = moment(s.endTime).format("MM/DD/YYYY h:mm:ss a");
          });
          data = data.sort((a, b) => a.displayOrder - b.displayOrder);
          setSessions(data);
        }
      })
      .catch((error) => {
        props.setGlobalError(
          "There was a problem accessing the Internet. Please check your connection and try again."
        );

        console.error("Error:", error);
      });
  }

  function nameChange(e, sessionCode) {
    editValues.current.map((val) => {
      if (val.code === sessionCode) {
        val.name = e.target.value;
      }
    });
  }
  function descriptionChange(e, sessionCode) {
    editValues.current.map((val) => {
      if (val.code === sessionCode) {
        val.description = e.target.value;
      }
    });
  }
  function capacityChange(e, sessionCode) {
    editValues.current.map((val) => {
      if (val.code === sessionCode) {
        val.seatsLeft = Number(e.target.value);
      }
    });
  }
  function displayOrderChange(e, sessionCode) {
    editValues.current.map((val) => {
      if (val.code === sessionCode) {
        val.displayOrder = Number(e.target.value);
      }
    });
  }

  function startTimeChange(value, sessionCode) {
    editValues.current.map((val) => {
      if (val.code === sessionCode) {
        val.beginTime = value;
      }
    });
  }
  function enableQtyChange(value, sessionCode) {
    editValues.current.map((val) => {
      if (val.code === sessionCode) {
        val.enableQty = value;
      }
    });
  }
  function endTimeChange(value, sessionCode) {
    editValues.current.map((val) => {
      if (val.code === sessionCode) {
        val.endTime = value;
      }
    });
  }
  function editSession(e, sessionCode) {
    e.preventDefault();
    let newArray = [...editIDs];
    newArray.push(sessionCode);

    setEditIDs(newArray);
    sessions.map((session) => {
      if (session.code === sessionCode) {
        editValues.current.push(JSON.parse(JSON.stringify(session)));
      }
    });
  }
  function postSession(session) {
    if (session.beginTime == null) {
      alert("Please enter a begin time.");
      return;
    }

    if (session.beginTime == null) {
      alert("Please enter an end time.");
      return;
    }
    if (session.beginTime == null) {
      alert("Please enter a begin time.");
      return;
    }
    if (session.code == null) {
      alert("Please enter a code for this session.");
      return;
    }
    if (session.name == null) {
      alert("Please enter a name for this session.");
      return;
    }
    if (session.description == null) {
      alert("Please enter a description for this session.");
      return;
    }
    if (session.seatsLeft == null) {
      alert("Please enter a capacity for this session.");
      return;
    }
    if (isNaN(session.seatsLeft)) {
      alert("Please enter a capacity for this session.");
      return false;
    } else {
      session.seatsLeft = parseInt(session.seatsLeft);
    }
    if (isNaN(session.displayOrder)) {
      alert("Please enter a valid display order for this session.");
      return false;
    } else {
      session.seatsLeft = parseInt(session.seatsLeft);
    }
    let offset = moment().utcOffset();
    const beginTime = moment(session.beginTime).toISOString(true);
    if (!beginTime) {
      alert("Please enter a valid begin time.");
      return;
    }
    session.beginTime =
      moment(session.beginTime).format("YYYY-MM-DDTHH:mm:ss") + ".000Z";

    const endTime = moment(session.endTime).toISOString(true);
    if (!endTime) {
      alert("Please enter a valid end time.");
      return;
    }

    session.endTime =
      moment(session.endTime).format("YYYY-MM-DDTHH:mm:ss") + ".000Z";

    fetch(global.config.settings.wsURL + "admin/UpdateSession", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },
      body: JSON.stringify(session),
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (data.status === "success") {
            setSuccessMessage(data.message);
            setErrMsg(null);
          } else {
            setErrMsg(data.message);
            setSuccessMessage(null);
          }
          getSessions();
        }
      })
      .catch((error) => {
        props.setGlobalError(
          "There was a problem accessing the Internet. Please check your connection and try again."
        );

        console.error("Error:", error);
      });
  }

  function saveSession(e, sessionCode) {
    e.preventDefault();
    editValues.current.map((session) => {
      if (session.code == sessionCode) {
        postSession(JSON.parse(JSON.stringify(session)));
        session.beginTime = moment(session.beginTime).format(
          "MM/DD/YYYY h:mm:ss a"
        );
        session.endTime = moment(session.endTime).format(
          "MM/DD/YYYY h:mm:ss a"
        );

        let origSessions = JSON.parse(JSON.stringify(sessions));

        //get index for this

        sessions.map((sess, cnt) => {
          if (sess.code == sessionCode) {
            origSessions[cnt] = JSON.parse(JSON.stringify(session));
          }
        });

        let newEditValues = [];
        editValues.current.map((editVal) => {
          if (editVal.code != sessionCode) {
            newEditValues.push(JSON.parse(JSON.stringify(editVal)));
          }
        });
        editValues.current = newEditValues;

        let newArray = [...editIDs];
        delete newArray[newArray.indexOf(sessionCode)];

        setEditIDs(newArray);

        setSessions(origSessions);
      }
    });
  }

  function deleteSession(e, sessionCode) {
    e.preventDefault();
    var conf = window.confirm(
      "Are you sure you want to delete " + sessionCode + "?"
    );
    if (!conf) {
      return;
    }
    fetch(global.config.settings.wsURL + "admin/DeleteSession", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
        sessionCode: sessionCode,
      },
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (data.status === "success") {
            setSuccessMessage(data.message);
            setErrMsg(null);
          } else {
            setErrMsg(data.message);
            setSuccessMessage(null);
          }
        }
      })
      .catch((error) => {
        props.setGlobalError(
          "There was a problem accessing the Internet. Please check your connection and try again."
        );

        console.error("Error:", error);
      });

    let origSessions = [];

    //get index for this

    sessions.map((sess) => {
      if (sess.code != sessionCode) {
        origSessions.push(JSON.parse(JSON.stringify(sess)));
      }
    });

    let newEditValues = [];
    editValues.current.map((editVal) => {
      if (editVal.code != sessionCode) {
        newEditValues.push(JSON.parse(JSON.stringify(editVal)));
      }
    });
    editValues.current = newEditValues;

    let newArray = [...editIDs];
    delete newArray[newArray.indexOf(sessionCode)];
    setEditIDs(newArray);

    setSessions(origSessions);
  }

  function cancelEdit(e, sessionCode) {
    let newArray = [...editIDs];
    delete newArray[newArray.indexOf(sessionCode)];
    setEditIDs(newArray);
  }
  useEffect(() => {
    getSessions();
  }, []);

  function newSessionChange(e, key) {
    let sessionHolder = JSON.parse(JSON.stringify(newSession));

    sessionHolder[key] = e.target.value;

    setNewSession(sessionHolder);
  }

  function addNewSession() {
    let session = JSON.parse(JSON.stringify(newSession));
    if (session.beginTime == null) {
      alert("Please enter a begin time.");
      return;
    }

    if (session.beginTime == null) {
      alert("Please enter an end time.");
      return;
    }
    if (session.beginTime == null) {
      alert("Please enter a begin time.");
      return;
    }
    if (session.code == null) {
      alert("Please enter a code for this session.");
      return;
    }
    if (session.name == null) {
      alert("Please enter a name for this session.");
      return;
    }
    if (session.description == null) {
      alert("Please enter a description for this session.");
      return;
    }
    if (session.seatsLeft == null) {
      alert("Please enter a capacity for this session.");
      return;
    }
    if (isNaN(session.seatsLeft)) {
      alert("Please enter a capacity for this session.");
      return false;
    } else {
      session.seatsLeft = parseInt(session.seatsLeft);
    }
    var time = moment.duration("05:00:00");
    var startTimeObj = moment(session.beginTime).subtract(time);
    const beginTime = startTimeObj.toISOString();
    if (!beginTime) {
      alert("Please enter a valid begin time.");
      return;
    }
    session.beginTime = beginTime;

    var endTimeObj = moment(session.endTime).subtract(time);

    const endTime = endTimeObj.toISOString();
    if (!endTime) {
      alert("Please enter a valid end time.");
      return;
    }
    session.endTime = endTime;
    fetch(global.config.settings.wsURL + "admin/UpdateSession", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },
      body: JSON.stringify(session),
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (data.status === "success") {
            setSuccessMessage(data.message);
            setErrMsg(null);
          } else {
            setErrMsg(data.message);
            setSuccessMessage(null);
          }
          if (data.status === "success") {
            newNameRef.current.value = "";
            newDescriptionRef.current.value = "";

            newBeginTimeRef.current.value = "";
            newEndTimeRef.current.value = "";
            newCapacityRef.current.value = "";
            newCodeRef.current.value = "";
            let sess = JSON.parse(JSON.stringify(sessionTemplate));
            sess.beginTime = session.beginTime;
            sess.endTime = session.endTime;
            setNewSession(sess);
            getSessions();
          }
        }
      })
      .catch((error) => {
        props.setGlobalError(
          "There was a problem accessing the Internet. Please check your connection and try again."
        );

        console.error("Error:", error);
      });
  }

  function newTimeChange(value, key) {
    let sessionHolder = JSON.parse(JSON.stringify(newSession));
    sessionHolder[key] = value;
    setNewSession(sessionHolder);
  }

  return (
    <>
      <Container style={{ paddingTop: 20, paddingBottom: 20 }} fluid>
        <Row>
          <Col>
            {errMsg && <Alert variant={"danger"}>{errMsg}</Alert>}
            {successMessage && (
              <Alert variant={"success"}>{successMessage}</Alert>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Add single session</Accordion.Header>
                <Accordion.Body>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Code</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Begin time</th>
                        <th>End time</th>
                        <th>Capacity</th>
                        <th>Qty Enabled</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <td>
                        <Form.Group controlId={"NewCodeText"}>
                          <Form.Control
                            onChange={(e) => newSessionChange(e, "code")}
                            ref={newCodeRef}
                            type="text"
                          />
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group controlId={"newNameTextArea"}>
                          <Form.Control
                            ref={newNameRef}
                            onChange={(e) => newSessionChange(e, "name")}
                            as="textarea"
                            rows={3}
                          />
                        </Form.Group>
                      </td>

                      <td>
                        <Form.Group controlId={"newDescriptionTextArea"}>
                          <Form.Control
                            ref={newDescriptionRef}
                            onChange={(e) => newSessionChange(e, "description")}
                            as="textarea"
                            rows={11}
                          />
                        </Form.Group>
                      </td>

                      <td>
                        <DatePicker
                          ref={newBeginTimeRef}
                          onChange={(val) => newTimeChange(val, "beginTime")}
                        />
                      </td>

                      <td>
                        <DatePicker
                          ref={newEndTimeRef}
                          onChange={(val) => newTimeChange(val, "endTime")}
                        />
                      </td>

                      <td>
                        <Form.Group controlId={"newCapacityTextArea"}>
                          <Form.Control
                            ref={newCapacityRef}
                            onChange={(e) => newSessionChange(e, "seatsLeft")}
                            type="text"
                          />
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group>
                          <Form.Check
                            type="checkbox"
                            defaultChecked={false}
                            onChange={(e) => {
                              let sessionHolder = JSON.parse(
                                JSON.stringify(newSession)
                              );

                              sessionHolder["enableQty"] = e.target.checked;

                              setNewSession(sessionHolder);
                            }}
                            label={"Enable Qty?"}
                          />
                        </Form.Group>
                      </td>
                      <td>
                        <Button
                          variant="success"
                          onClick={(e) => addNewSession()}
                        >
                          Add
                        </Button>
                      </td>
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Upload a list of sessions</Accordion.Header>
                <Accordion.Body>
                  <Container>
                    <h5>Import instructions</h5>
                    <p>Begin by downloading the excel template.</p>
                    <p>Be sure to save the file when downloading.</p>
                    <p>
                      <Button
                        download="SessionTemplate.xlsx"
                        href={DownloadTemplate}
                        variant="primary"
                        size="md"
                      >
                        Download template
                      </Button>
                    </p>
                    <p>
                      For each session, create a new row in the excel file.
                      <br />
                      All fields are required. The fields are listed below:
                      <p>
                        <ul>
                          <li>
                            Code - this field uniquely identifies your session.
                            It must be less than 50 characters and contain only
                            letters and numbers. No spaces or symbols
                          </li>
                          <li>
                            Name - the name to that will be displayed for this
                            session throughout the registration process. This
                            field may be up to 250 characters long
                          </li>
                          <li>
                            Description - this will be the long description for
                            the session. This field can support up to 2,000
                            characters
                          </li>
                          <li>
                            Begin date - this is the beginning date of your
                            session. It should be in the format of mm/dd/yyy.
                            example: 01/01/2020)
                          </li>
                          <li>
                            Begin time - this is the time that your session will
                            start. It should be in the format of hh:mm:ss pm
                            example: 01:20:00 pm
                          </li>
                          <li>
                            End date - this is the end date of your session. It
                            should be in the format of mm/dd/yyy. example:
                            01/01/2020)
                          </li>
                          <li>
                            End time - this is the time that your session will
                            end. It should be in the format of hh:mm:ss pm
                            example: 01:20:00 pm
                          </li>
                          <li>
                            Capacity - the maximum number of attendees that will
                            be allowed into the session
                          </li>
                          <li>
                            Order - the display order of the session ticket
                          </li>
                        </ul>
                      </p>
                      <p>
                        Once the spreadsheet has been completed, you may import
                        the sessions by uploading the spreadsheet using the
                        'upload' button below.
                        <br />
                        The system will alert you to any errors.
                      </p>
                    </p>
                    <Container
                      style={{
                        marginLeft: "0px",
                        marginRight: "0px",
                        paddingLeft: "0px",
                      }}
                    >
                      <Row>
                        <Col>
                          <Form>
                            <Form.Control
                              type="file"
                              onChange={changeHandler}
                            />
                            <Form.Label data-browse="Browse">
                              {fileMessage}
                            </Form.Label>
                            <Form.Control.Feedback type="valid">
                              {uploadSuccess}
                            </Form.Control.Feedback>

                            <Form.Group>
                              {selectedFile && (
                                <>
                                  <Button
                                    variant="info"
                                    style={{
                                      marginTop: "5px",
                                      marginBottom: "5px",
                                    }}
                                    onClick={(e) => submitHandler(e)}
                                  >
                                    Upload
                                  </Button>
                                  <p>File name: {selectedFile.name}</p>
                                  <p>File type: {selectedFile.type}</p>
                                  <p>Size in bytes: {selectedFile.size}</p>
                                </>
                              )}
                            </Form.Group>
                          </Form>
                        </Col>
                        <Col></Col>
                        <Col></Col>
                      </Row>
                    </Container>
                  </Container>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>

      <Table
        style={{
          fontSize: "12px",
        }}
        striped
        bordered
        hover
      >
        <thead>
          <tr>
            <th>Code</th>
            <th>Name</th>
            <th>Description</th>
            <th>Begin time</th>
            <th>End time</th>
            <th>Seats</th>
            <th>Order</th>
            <th>Qty</th>
            <th>Ticket Template</th>
            <th></th>
            <th>CE Template</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sessions.map((session) => {
            return (
              <tr key={session.code}>
                <td width="10%"> {session.code}</td>

                {editIDs.includes(session.code) ? (
                  <td>
                    <Form.Group controlId={session.code + "-NameTextArea"}>
                      <Form.Control
                        onChange={(e) => nameChange(e, session.code)}
                        as="textarea"
                        defaultValue={session.name}
                        rows={3}
                      />
                    </Form.Group>
                  </td>
                ) : (
                  <td width="10%">{session.name}</td>
                )}

                {editIDs.includes(session.code) ? (
                  <td>
                    <Form.Group
                      controlId={session.code + "-DescriptionTextArea"}
                    >
                      <Form.Control
                        onChange={(e) => descriptionChange(e, session.code)}
                        as="textarea"
                        defaultValue={session.description}
                        rows={3}
                      />
                    </Form.Group>
                  </td>
                ) : (
                  <td>{session.description}</td>
                )}

                {editIDs.includes(session.code) ? (
                  <td width={"15%"}>
                    <DatePicker
                      initialValue={session.beginTime}
                      onChange={(val) => startTimeChange(val, session.code)}
                    />
                  </td>
                ) : (
                  <td>{session.beginTime}</td>
                )}

                {editIDs.includes(session.code) ? (
                  <td width={"15%"}>
                    <DatePicker
                      initialValue={session.endTime}
                      onChange={(val) => endTimeChange(val, session.code)}
                    />
                  </td>
                ) : (
                  <td>{session.endTime}</td>
                )}

                {editIDs.includes(session.code) ? (
                  <td>
                    <Form.Group controlId={session.code + "-CapacityTextArea"}>
                      <Form.Control
                        onChange={(e) => capacityChange(e, session.code)}
                        type="text"
                        defaultValue={session.seatsLeft}
                      />
                    </Form.Group>
                  </td>
                ) : (
                  <td>{session.seatsLeft}</td>
                )}
                {editIDs.includes(session.code) ? (
                  <td>
                    <Form.Group controlId={session.code + "-DisplayOrder"}>
                      <Form.Control
                        onChange={(e) => displayOrderChange(e, session.code)}
                        type="text"
                        defaultValue={session.displayOrder}
                      />
                    </Form.Group>
                  </td>
                ) : (
                  <td>{session.displayOrder}</td>
                )}
                {editIDs.includes(session.code) ? (
                  <td>
                    <Form.Group>
                      <Form.Check
                        type="checkbox"
                        defaultChecked={session.enableQty}
                        onChange={(e) =>
                          enableQtyChange(e.target.checked, session.code)
                        }
                        label={"Enable Qty?"}
                      />
                    </Form.Group>
                  </td>
                ) : (
                  <>
                    <td width="5%">
                      {session.enableQty == true ? "Yes" : "No"}
                    </td>
                  </>
                )}
                {uploadSessionName == session.code ? (
                  <td width={"20%"}>
                    <Container
                      style={{
                        width: "100%",
                        marginLeft: "0px",
                        marginRight: "0px",
                        paddingLeft: "0px",
                      }}
                    >
                      <Row>
                        <Col>
                          <Form>
                            <Form.Control
                              type="file"
                              onChange={(e) =>
                                changeTemplateHandler(e, session.code)
                              }
                              isValid
                            />
                            <Form.Label data-browse="Browse">
                              {fileTemplateMessage}
                            </Form.Label>
                            <Form.Control.Feedback type="valid">
                              {uploadTemplateSuccess}
                            </Form.Control.Feedback>
                            <Form.Group>
                              {selectedTemplateFile && (
                                <>
                                  <Button
                                    variant="info"
                                    style={{
                                      marginTop: "5px",
                                      marginBottom: "5px",
                                    }}
                                    onClick={(e) =>
                                      submitTemplateHandler(e, session.Code)
                                    }
                                  >
                                    Upload
                                  </Button>
                                  <p>File name: {selectedTemplateFile.name}</p>
                                  <p>File type: {selectedTemplateFile.type}</p>
                                  <p>
                                    Size in bytes: {selectedTemplateFile.size}
                                  </p>
                                </>
                              )}
                            </Form.Group>
                          </Form>
                        </Col>
                      </Row>
                    </Container>{" "}
                  </td>
                ) : (
                  <td>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        setUploadSessionName(session.code);
                      }}
                    >
                      Upload Ticket
                    </Button>
                  </td>
                )}

                {editIDs.includes(session.code) ? (
                  <>
                    <td>
                      <Button
                        variant="success"
                        style={{ width: "100%" }}
                        onClick={(e) => saveSession(e, session.code)}
                      >
                        Save
                      </Button>
                    </td>
                    <td>
                      <Button
                        block
                        onClick={(e) => cancelEdit(e, session.code)}
                      >
                        Cancel
                      </Button>
                    </td>
                  </>
                ) : (
                  <>
                    <td>
                      <Button
                        variant="success"
                        style={{ width: "100%" }}
                        onClick={(e) => editSession(e, session.code)}
                      >
                        Edit
                      </Button>
                    </td>
                    {uploadCEName == session.code ? (
                      <td width={"20%"}>
                        <Container
                          style={{
                            width: "100%",
                            marginLeft: "0px",
                            marginRight: "0px",
                            paddingLeft: "0px",
                          }}
                        >
                          <Row>
                            <Col>
                              <Form>
                                <Form.Control
                                  type="file"
                                  onChange={(e) =>
                                    changeCETemplateHandler(e, session.code)
                                  }
                                  isValid
                                />
                                <Form.Label data-browse="Browse">
                                  {fileCETemplateMessage}
                                </Form.Label>
                                <Form.Control.Feedback type="valid">
                                  {uploadCETemplateSuccess}
                                </Form.Control.Feedback>

                                <Form.Group>
                                  {selectedCETemplateFile && (
                                    <>
                                      <Button
                                        variant="info"
                                        style={{
                                          marginTop: "5px",
                                          marginBottom: "5px",
                                        }}
                                        onClick={(e) =>
                                          submitCETemplateHandler(
                                            e,
                                            session.Code
                                          )
                                        }
                                      >
                                        Upload
                                      </Button>
                                      <p>
                                        File name: {selectedCETemplateFile.name}
                                      </p>
                                      <p>
                                        File type: {selectedCETemplateFile.type}
                                      </p>
                                      <p>
                                        Size in bytes:{" "}
                                        {selectedCETemplateFile.size}
                                      </p>
                                    </>
                                  )}
                                </Form.Group>
                              </Form>
                            </Col>
                          </Row>
                        </Container>{" "}
                      </td>
                    ) : (
                      <td>
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            setUploadCEName(session.code);
                          }}
                        >
                          Upload CE File
                        </Button>
                      </td>
                    )}
                    <td>
                      <Button
                        block
                        onClick={(e) => deleteSession(e, session.code)}
                      >
                        Delete
                      </Button>
                    </td>
                  </>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

export default React.memo(SessionSetup);
