import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {  Button, ButtonGroup, Container, Row, Col, Modal, Tabs, Tab, Form } from 'react-bootstrap'; 
import Styles from './setup.module.css';
import ShowSetup from '../showSetup/showSetup';
import FieldSetup from '../fieldSetup/fieldSetup';
import CustomFieldSetup from '../customFieldSetup/customFieldSetup';
import SessionSetup from '../sessionSetup/sessionSetup';
import RegistrationTypes from '../registrationTypes/registrationTypes';
import SessionTrack from '../sessionTrack/sessionTrack';
import MasterTemplateSetup from '../masterTemplateSetup/masterTemplateSetup';
import SMTPSettings from '../smtpSettings/smtpSettings';
function Setup(props) {
    const handleClose = () => props.setShowModal(false);
    
    const [key, setKey] = useState('general');
 

 

    return (
        <Modal centered contentClassName={Styles.modal}  dialogClassName={Styles.modal} onHide={handleClose} show={props.showModal}>
                     <Modal.Header setGlobalError={props.setGlobalError} closeButton><h3>Setup</h3></Modal.Header>
            
        <Modal.Body className={Styles.modalBodyStyle}>
        <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
    >
      <Tab eventKey="general" title="General">
          {key==="general" &&
          <ShowSetup  setGlobalError={props.setGlobalError} user={props.user} showCode={props.showCode} />
           }
      </Tab>
      <Tab eventKey="standardFields" title="Standard Fields">
          {key==="standardFields" &&
            <FieldSetup setGlobalError={props.setGlobalError} setRefreshFields={props.setRefreshFields} user={props.user} showCode={props.showCode} />
            }
      </Tab>

      <Tab eventKey="customFields" title="Custom Fields">
          {key==="customFields" &&
            <CustomFieldSetup setGlobalError={props.setGlobalError} setRefreshFields={props.setRefreshFields} user={props.user} showCode={props.showCode} />
            }
      </Tab>
      <Tab eventKey="sessionSetup" title="Session Setup">
          {key==="sessionSetup" &&
            <SessionSetup setGlobalError={props.setGlobalError} user={props.user} showCode={props.showCode} />
            }
      </Tab>
      <Tab eventKey="sessionTrackSetup" title="Session Tracks">
          {key==="sessionTrackSetup" &&
            <SessionTrack setGlobalError={props.setGlobalError} user={props.user} showCode={props.showCode} />
            }
      </Tab>
      <Tab eventKey="registrationTypes" title="Badge Type">
            {key==="registrationTypes" &&
              <RegistrationTypes setRefreshRegistrationTypes={props.setRefreshRegistrationTypes} setGlobalError={props.setGlobalError} user={props.user} showCode={props.showCode} />
            }
      </Tab>
      <Tab eventKey="masterTemplateSetup" title="Master Template">
            {key==="masterTemplateSetup" &&
              <MasterTemplateSetup setRefreshRegistrationTypes={props.setRefreshRegistrationTypes} setGlobalError={props.setGlobalError} user={props.user} showCode={props.showCode} />
            }
      </Tab>
      <Tab eventKey="smtpSettings" title="SmtpSettings">
            {key==="smtpSettings" &&
              <SMTPSettings user={props.user} showCode={props.showCode} />
            }
      </Tab>
    </Tabs>
 
        </Modal.Body>
        <Modal.Footer><Button
                    onClick={() => {  
                        props.setShowModal(false) 
                        
                    }}
                >Close</Button>
        </Modal.Footer>            
       </Modal>
    )
}

export default React.memo(Setup);