import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Button,
  Form,
  Container,
  Row,
  Col,
  Modal,
  Toast,
  Tabs,
  Tab,
} from "react-bootstrap";
import Styles from "./certificates.module.css";
import Select, { components } from "react-select";
import moment from "moment";
import ReactDataGrid, { SelectColumn } from "react-data-grid";

function EmailCertificateConfirmations(props) {
  const [attendeeList, setAttendeeList] = useState({
    attendees: [],
    filteredAttendees: [],
  });
  const [selectedRows, setSelectedRows] = useState(new Set(React.Key));
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [statusSearch, setStatusSearch] = useState("all");
  const [key, setKey] = useState("all");
  const [emailText, setEmailText] = useState("");
  const [refreshList, setRefreshList] = useState(false);
  const toggleRefreshList = () => setRefreshList(!refreshList);
  const statusSearchRef = useRef(statusSearch);
  const toggleShowToast = () => setShowToast(!showToast);
  const [isLoading, setLoading] = useState(false);
  const searchTextRef = useRef("");
  const previewFormatter = (rowData) => {
    return (
      <Button
        style={{ width: "99%" }}
        size="sm"
        bsPrefix="btn-omni"
        block
        onClick={(e) => {
          e.preventDefault();
          getEmailPreview(rowData.row.badgeNumber);
        }}
      >
        Preview
      </Button>
    );
  };
  function getData(e) {
    filterByText(attendeeList);
  }
  const resendFormatter = (rowData) => {
    return (
      <Button
        style={{ width: "99%" }}
        size="sm"
        bsPrefix="btn-omni"
        block
        onClick={(e) => {
          resendEmails(e, rowData.row.badgeNumber);
        }}
      >
        Resend
      </Button>
    );
  };
  function getData(e) {
    filterByText(attendeeList);
  }
  function filterByText(thisAttendeeList) {
    let filteredAttendeeList = JSON.parse(
      JSON.stringify(thisAttendeeList.attendees)
    );
    let searchString = searchTextRef.current.value;

    if (searchString.length > 0) {
      let thisString = searchString
        .toLowerCase()
        .replace(new RegExp("[^a-zA-Z0-9]", "g"), "");
      filteredAttendeeList = filteredAttendeeList.filter(function (n) {
        return n.attendeeString.includes(thisString);
      });
    }

    let newAttendeeList = {
      attendees: JSON.parse(JSON.stringify(thisAttendeeList.attendees)),
      filteredAttendees: [],
    };

    if (statusSearch == "all") {
      newAttendeeList.filteredAttendees = JSON.parse(
        JSON.stringify(filteredAttendeeList)
      );
      setAttendeeList(newAttendeeList);
      return;
    }
    filteredAttendeeList.map((attendee) => {
      if (statusSearch == "notEmailed") {
        if (!attendee.emailSent) {
          newAttendeeList.filteredAttendees.push(
            JSON.parse(JSON.stringify(attendee))
          );
        }
      } else if (statusSearch == "unread") {
        if (
          attendee.emailSent &&
          attendee.emailReadCount != null &&
          attendee.emailReadCount == 0
        ) {
          newAttendeeList.filteredAttendees.push(
            JSON.parse(JSON.stringify(attendee))
          );
        }
      } else {
        if (attendee.emailSent) {
          newAttendeeList.filteredAttendees.push(
            JSON.parse(JSON.stringify(attendee))
          );
        }
      }
    });
    setAttendeeList(newAttendeeList);
  }
  useEffect(() => {
    setSelectedRows(new Set(React.Key));
    filterByText(attendeeList);
  }, [statusSearch, refreshList]);

  function getEmailPreview(badgeNumber) {
    fetch(global.config.settings.wsURL + "admin/PreviewAttendeeCEEmails", {
      method: "GET",
      headers: {
        accept: "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
        badgeNumber: badgeNumber,
      },
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          setEmailText(data.email);
        }
      })
      .catch((error) => {
        props.setGlobalError(
          "There was a problem accessing the Internet. Please check your connection and try again."
        );

        console.error("Error:", error);
      });
  }

  function rowKeyGetter(row) {
    return row.badgeNumber;
  }
  const resendEmails = (e, badgeID) => {
    e.preventDefault();

    let v = [];

    v.push({ BadgeId: badgeID });

    fetch(global.config.settings.wsURL + "admin/EmailAttendeeCEs", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },
      body: JSON.stringify(v),
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);
          setLoading(false);
          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          //alert(data.message);
          setToastMessage(data.message);
          toggleShowToast();
          getAttendees();
        }
      })
      .catch((error) => {
        props.setGlobalError(
          "There was a problem accessing the Internet. Please check your connection and try again."
        );
        setLoading(false);
        console.error("Error:", error);
      });
  };
  const sendEmails = (e) => {
    e.preventDefault();
    if (selectedRows.size == 0) {
      setToastMessage("You haven't selected any attendees to email.");
      toggleShowToast();
      return;
    }
    //console.log(selectedRows);
    setLoading(true);
    let v = [];
    Array.from(selectedRows).map((item) => {
      v.push({ BadgeId: item });
    });
    fetch(global.config.settings.wsURL + "admin/EmailAttendeeCEs", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },
      body: JSON.stringify(v),
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);
          setLoading(false);
          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          //alert(data.message);
          setToastMessage(data.message);
          toggleShowToast();
          getAttendees();
        }
      })
      .catch((error) => {
        props.setGlobalError(
          "There was a problem accessing the Internet. Please check your connection and try again."
        );
        setLoading(false);
        console.error("Error:", error);
      });
  };
  function getAttendees() {
    fetch(global.config.settings.wsURL + "admin/GetAttendeeCEEmailStatuses", {
      method: "GET",
      headers: {
        accept: "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          let limitedAttendees = [];
          data.map((attendee, cnt) => {
            attendee.firstName =
              attendee.firstName === null ? "" : attendee.firstName;
            attendee.lastName =
              attendee.lastName === null ? "" : attendee.lastName;
            attendee.companyName =
              attendee.companyName === null ? "" : attendee.companyName;
            attendee.attendeeString = (
              attendee.firstName.toLowerCase() +
              attendee.lastName.toLowerCase() +
              attendee.companyName.toLowerCase()
            ).replace(new RegExp("[^a-zA-Z0-9]", "g"), "");

            attendee.checked = false;
          });
          let attendeesWithEmailAddress = [];

          data.map((attendee) => {
            if (attendee.email != null && attendee.email != "") {
              attendeesWithEmailAddress.push(attendee);
            }
          });

          let att = {
            attendees: attendeesWithEmailAddress,
            filteredAttendees: attendeesWithEmailAddress,
          };
          setAttendeeList(att);
          //filterByText(att)
          toggleRefreshList();

          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        props.setGlobalError(
          "There was a problem accessing the Internet. Please check your connection and try again."
        );

        console.error("Error:", error);
      });
  }

  const idFormatter = (rowData) => {
    switch (rowData.row.emailSent) {
      case true:
        return (
          <div style={{ height: "100%", color: "green" }}>
            {rowData.row.badgeNumber + " (Email Sent)"}
          </div>
        );
        break;
      case false:
        return (
          <div style={{ height: "100%", color: "blue" }}>
            {rowData.row.badgeNumber + " (Email Not Sent)"}
          </div>
        );
        break;
    }
  };
  useEffect(() => {
    getAttendees();
  }, []);
  const defaultColumnProperties = {
    height: 50,

    resizable: true,
  };

  const columns = [
    {
      key: "badgeNumber",
      name: "ID",
      width: 160,
      frozen: true,
      formatter: idFormatter,
    },
    { key: "firstName", name: "First Name" },
    { key: "lastName", name: "Last Name" },
    { key: "companyName", name: "Company" },

    { key: "preview", name: "", width: 160, formatter: previewFormatter },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));
  const sentColumns = [
    {
      key: "badgeNumber",
      name: "ID",
      width: 150,
      frozen: true,
      formatter: idFormatter,
    },
    { key: "firstName", name: "First Name" },
    { key: "lastName", width: 100, name: "Last Name" },
    { key: "companyName", width: 100, name: "Company" },
    { key: "emailReadCount", width: 20, name: "Reads" },
    { key: "preview", name: "", width: 100, formatter: previewFormatter },
    { key: "resend", name: "", width: 100, formatter: resendFormatter },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  const notSentColumns = [
    SelectColumn,
    {
      key: "badgeNumber",
      name: "ID",
      width: 160,
      frozen: true,
      formatter: idFormatter,
    },
    { key: "firstName", name: "First Name" },
    { key: "lastName", name: "Last Name" },
    { key: "companyName", name: "Company" },

    { key: "preview", name: "", width: 160, formatter: previewFormatter },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  const EmptyRowsRenderer = () => {
    const message = "No attendees to show";

    return (
      <div style={{ textAlign: "center" }}>
        Nothing to show
        <span lang="en" title="no data">
          No attendees
        </span>
      </div>
    );
  };

  return (
    <>
      <Container>
        <h2>Instructions</h2>
        <p>
          Use this tool to send an email with links to attendee's CE
          certificates. The certificates are uploaded on the setup for each
          session.
        </p>

        <Container style={{ maxWidth: "100%" }}>
          <Row>
            <Col></Col>
            <Col>
              {" "}
              <Toast
                style={{}}
                onClose={() => setShowToast(false)}
                show={showToast}
              >
                <Toast.Header>
                  <strong className="mr-auto">Status</strong>
                  <small>hide</small>
                </Toast.Header>
                <Toast.Body>{toastMessage}</Toast.Body>
              </Toast>
            </Col>
            <Col></Col>
          </Row>
          <Row>
            <Col md={3} style={{ textAlign: "left" }}>
              <label htmlFor="SearchBar">Filter List</label>
              <Form.Control
                placeholder="Search by company or name"
                aria-label="Search by company or name"
                autoComplete="new-password"
                id="SearchBar"
                aria-controls="AttendeeList"
                ref={searchTextRef}
                onChange={(e) => getData(e)}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6} sm={12}>
              <Tabs
                id="controlled-tab"
                activeKey={key}
                onSelect={(k) => {
                  setKey(k);
                  setStatusSearch(k);
                }}
              >
                <Tab eventKey="all" title="All">
                  {key === "all" && (
                    <ReactDataGrid
                      emptyRowsRenderer={EmptyRowsRenderer}
                      columns={columns}
                      selectedRows={selectedRows}
                      onSelectedRowsChange={setSelectedRows}
                      rowKeyGetter={rowKeyGetter}
                      rows={attendeeList.filteredAttendees}
                      rowHeight={50}
                      minHeight={600}
                    />
                  )}
                </Tab>
                <Tab eventKey="notEmailed" title="Not Emailed">
                  {key === "notEmailed" && (
                    <Container>
                      <Row>
                        <Col>
                          {" "}
                          <ReactDataGrid
                            emptyRowsRenderer={EmptyRowsRenderer}
                            columns={notSentColumns}
                            selectedRows={selectedRows}
                            onSelectedRowsChange={setSelectedRows}
                            rowKeyGetter={rowKeyGetter}
                            rows={attendeeList.filteredAttendees}
                            rowHeight={50}
                            minHeight={600}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{ textAlign: "center" }}>
                          <Button
                            disabled={isLoading}
                            onClick={(e) => (!isLoading ? sendEmails(e) : null)}
                            bsPrefix="btn-omni"
                            style={{ margin: "0", width: "70%" }}
                          >
                            {isLoading ? "Sending emails....." : "Send Emails"}
                          </Button>
                        </Col>
                      </Row>
                    </Container>
                  )}
                </Tab>
                <Tab eventKey="emailed" title="Sent Emails">
                  {key === "emailed" && (
                    <ReactDataGrid
                      emptyRowsRenderer={EmptyRowsRenderer}
                      columns={sentColumns}
                      selectedRows={selectedRows}
                      onSelectedRowsChange={setSelectedRows}
                      rowKeyGetter={rowKeyGetter}
                      rows={attendeeList.filteredAttendees}
                      rowHeight={50}
                      minHeight={600}
                    />
                  )}
                </Tab>
                <Tab eventKey="unread" title="Unread">
                  {key === "unread" && (
                    <Container>
                      <Row>
                        <Col>
                          {" "}
                          <ReactDataGrid
                            emptyRowsRenderer={EmptyRowsRenderer}
                            columns={notSentColumns}
                            selectedRows={selectedRows}
                            onSelectedRowsChange={setSelectedRows}
                            rowKeyGetter={rowKeyGetter}
                            rows={attendeeList.filteredAttendees}
                            rowHeight={50}
                            minHeight={600}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{ textAlign: "center" }}>
                          <Button
                            disabled={isLoading}
                            onClick={(e) => (!isLoading ? sendEmails(e) : null)}
                            bsPrefix="btn-omni"
                            style={{ margin: "0", width: "70%" }}
                          >
                            {isLoading ? "Sending emails....." : "Send Emails"}
                          </Button>
                        </Col>
                      </Row>
                    </Container>
                  )}
                </Tab>
              </Tabs>
            </Col>
            <Col xs={12} md={6}>
              <p dangerouslySetInnerHTML={{ __html: emailText }}></p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}></Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}
const StatusFilter = React.memo((props) => {
  const options = [
    {
      value: "all",
      label: "All",
    },
    {
      value: "notEmailed",
      label: "NotEmailed",
    },
    {
      value: "emailed",
      label: "Emailed",
    },
    {
      value: "unread",
      label: "unread",
    },
  ];
  function onChange(value) {
    props.setStatusSearch(value.value);
    props.statusSearchRef.current = value.value;
  }
  return (
    <Select defaultValue={[options[0]]} onChange={onChange} options={options} />
  );
});
export default EmailCertificateConfirmations;
