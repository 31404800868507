import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Form,
  Container,
  Row,
  Col,
  Modal,
  Alert,
} from "react-bootstrap";
import styles from "./modalEdit.module.css";
import moment from "moment";

function ModalEdit(props) {
  const handleClose = () => props.setShowModal(false);
  const [fieldValues, setFieldValues] = useState([]);
  const [attendeeSessions, setAttendeeSessions] = useState([]);
  const [itemQuantities, setItemQuantities] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [hasBadgeTypes, setHasBadgeTypes] = useState(false);
  const [infoMessage, setInfoMessage] = useState(null);
  const [exhibitorQuantityMessage, setExhibitorQuantityMessage] =
    useState(null);
  const [exhAlertVariant, setExhAlertVariant] = useState("info");
  const addAttendee = useCallback((e) => {
    e.preventDefault();
    let postData = [];

    for (const key in fieldValues) {
      postData.push({
        Key: key,
        Value: fieldValues[key],
      });
    }

    props.setRefreshBadges([
      {
        badgeNumber: props.editBadgeNumber,
        companyName: fieldValues["companyName"],
        firstName: fieldValues["firstName"],
        lastName: fieldValues["lastName"],
      },
    ]);

    fetch(global.config.settings.wsURL + "admin/AddAttendee", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },
      body: JSON.stringify(postData),
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (data.status != "success") {
            alert(data.message);
          } else {
            props.setEditBadgeNumber(data.message);
            updateAttendeeSessions(data.message);
            var conf = window.confirm(
              "Attendee was added successfully. Do you want to print and check them in?"
            );
            if (!conf) {
              return;
            } else {
              props.setBadgesToPrint([parseInt(data.message)]);
            }

            setFieldValues([]);
          }
        }
      })
      .catch((error) => {
        props.setGlobalError(
          "There was a problem accessing the Internet. Please check your connection and try again."
        );

        console.error("Error:", error);
      });
  });
  function updateAttendeeSessions(badgeNumber) {
    let sessData = [];

    for (const key in attendeeSessions) {
      sessData.push({
        SessionCode: key,
        IsAttending: attendeeSessions[key],
        Qty: itemQuantities[key],
      });
    }

    fetch(global.config.settings.wsURL + "admin/UpdateSessions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
        badgeNumber: badgeNumber,
      },
      body: JSON.stringify(sessData),
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          setAttendeeSessions([]);
          props.setEditBadgeNumber(null);
          props.setShowModal(false);
        }
      })
      .catch((error) => {
        props.setGlobalError(
          "There was a problem accessing the Internet. Please check your connection and try again."
        );

        console.error("Error:", error);
      });
  }
  useEffect(() => {
    let showEdit = false;

    props.fieldList.map((f) => {
      if (f.code === "attendeeType") {
        showEdit = true;
      }
    });
    setHasBadgeTypes(showEdit);
  }, [props.fieldList]);
  const updateAttendee = useCallback((e) => {
    e.preventDefault();
    let postData = [];

    for (const key in fieldValues) {
      console.log(postData);
      postData.push({
        Key: key,
        Value: fieldValues[key],
      });
    }

    props.setRefreshBadges([
      {
        badgeNumber: props.editBadgeNumber,
        companyName: fieldValues["companyName"],
        firstName: fieldValues["firstName"],
        lastName: fieldValues["lastName"],
        status: fieldValues["status"],
      },
    ]);

    fetch(global.config.settings.wsURL + "admin/UpdateAttendee", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
        badgeNumber: props.editBadgeNumber,
      },
      body: JSON.stringify(postData),
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (data.status != "success") {
            alert(data.message);
          } else {
            updateAttendeeSessions(props.editBadgeNumber);
            setFieldValues([]);
          }
        }
      });
  });
  const uncheckOrCheckSession = (code, checked) => {
    let vals = [];
    for (const key in attendeeSessions) {
      vals[key] = attendeeSessions[key];
    }
    vals[code] = checked;
    setAttendeeSessions(vals);
  };
  const updateSessionQty = (code, qtyInt) => {
    let vals = [];
    for (const key in itemQuantities) {
      vals[key] = itemQuantities[key];
    }

    vals[code] = parseInt(qtyInt, 10);
    setItemQuantities(vals);
  };
  const handleTextChange = useCallback(
    (e, fieldCode) => {
      e.preventDefault();
      let vals = [];
      for (const key in fieldValues) {
        vals[key] = fieldValues[key];
      }

      vals[fieldCode] = e.target.value;

      if (fieldCode === "exhibitorId") {
        vals["companyName"] = e.target.value;
        if (e.target.value != "") {
          showExhibitorInfo(e.target.value);
        } else {
          setExhAlertVariant("info");
          setExhibitorQuantityMessage(null);
        }
      }
      setFieldValues(vals);
    },
    [fieldValues]
  );

  useEffect(() => {
    if (props.editBadgeNumber != null) {
      setFieldValues(props.fieldValues);
      setAttendeeSessions(props.attendeeSessions);
      setItemQuantities(props.itemQuantities);
    }
  }, [props.fieldValues, props.attendeeSessions, props.itemQuantities]);

  useEffect(() => {
    //Get sessions
    fetch(global.config.settings.wsURL + "admin/GetSessions", {
      method: "GET",
      headers: {
        accept: "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          data = data.sort((a, b) => a.displayOrder - b.displayOrder);
          setSessions(data);
        }
      })
      .catch((error) => {
        props.setGlobalError(
          "There was a problem accessing the Internet. Please check your connection and try again."
        );

        console.error("Error:", error);
      });
  }, []);

  const showExhibitorInfo = (exhibitorID) => {
    console.log(exhibitorID);
    fetch(global.config.settings.wsURL + "admin/GetExhibitor", {
      method: "GET",
      headers: {
        accept: "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
        exhibitorId: exhibitorID,
      },
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          var message;

          if (data.freeRegistrants <= data.totalRegistered) {
            message = `${data.totalRegistered} of ${data.id}'s ${data.freeRegistrants} free  registrations have been used. All new registrations must be paid for.`;
            setExhAlertVariant("danger");
          } else {
            message = `${data.totalRegistered} of ${data.id}'s ${data.freeRegistrants} free registrations have been used.`;
            setExhAlertVariant("info");
          }
        }
        setExhibitorQuantityMessage(message);
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <Modal
      centered
      contentClassName={styles.modal}
      dialogClassName={styles.modal}
      onHide={handleClose}
      show={props.showModal}
    >
      <Modal.Header closeButton>
        <Row>
          <Col xs>
            <h3>
              Editing{" "}
              {fieldValues["lastName"] &&
                fieldValues["firstName"] &&
                fieldValues["firstName"] + " " + fieldValues["lastName"]}
            </h3>
            {exhibitorQuantityMessage && (
              <>
                <br />
                <Alert variant={exhAlertVariant}>
                  {exhibitorQuantityMessage}
                </Alert>
              </>
            )}
          </Col>
        </Row>

        <Row>
          <Col xs></Col>
        </Row>
      </Modal.Header>

      <Modal.Body className={styles.modalBodyStyle}>
        <Container>
          {hasBadgeTypes === true && (
            <Row>
              <Col md>
                <Row>
                  <Col xs>
                    <h4>Attendee Fields</h4>
                    <hr />
                  </Col>
                </Row>
                <Row>
                  <Col xs>
                    {" "}
                    <Form.Group controlId={"status"}>
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        controlid={"status"}
                        onChange={(e) => {
                          handleTextChange(e, "status");
                        }}
                        value={fieldValues["status"]}
                        as="select"
                      >
                        <option key="A" label="Not checked in">
                          A
                        </option>
                        <option key="V" label="Checked in">
                          V
                        </option>
                        <option key="CX" label="Cancelled">
                          CX
                        </option>
                        <option key="P" label="Problem">
                          P
                        </option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group controlId={"notes"}>
                  <Form.Label>Notes</Form.Label>
                  <Form.Control
                    value={fieldValues["notes"]}
                    onChange={(e) => handleTextChange(e, "notes")}
                    as="textarea"
                    rows={2}
                  />
                </Form.Group>
                {props.fieldList.map((field) => {
                  return (
                    <>
                      {field.fieldType === "textbox" && (
                        <Row key={field.code}>
                          <Col xs>
                            {" "}
                            <Form.Group controlId={field.code}>
                              <Form.Label>{field.text}</Form.Label>
                              <Form.Control
                                onChange={(e) => {
                                  handleTextChange(e, field.code);
                                }}
                                value={fieldValues[field.code]}
                                type="Text"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      )}
                      {field.fieldType === "dropdown" && (
                        <Row key={field.code}>
                          <Col xs>
                            {" "}
                            <Form.Group controlId={field.code}>
                              <Form.Label>{field.text}</Form.Label>
                              <Form.Control
                                controlid={field.code}
                                onChange={(e) => {
                                  handleTextChange(e, field.code);
                                }}
                                value={fieldValues[field.code]}
                                as="select"
                              >
                                <option label={"<--Select One-->"} key={-1}>
                                  {}
                                </option>
                                {field.comboValues.map((val) => {
                                  return field.code === "country" ||
                                    field.code === "stateProvince" ||
                                    field.code === "companyCountry" ||
                                    field.code === "companyStateProvince" ||
                                    field.code === "exhibitorId" ? (
                                    <option key={val.key} label={val.value}>
                                      {val.key}
                                    </option>
                                  ) : (
                                    <option key={val.key} label={val.key}>
                                      {val.key}
                                    </option>
                                  );
                                })}
                                ;
                              </Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                      )}
                    </>
                  );
                })}
              </Col>
              <Col md>
                <h4>Sessions</h4>
                <hr />
                {sessions.map((session) => {
                  return (
                    <>
                      <Row>
                        <Col>{attendeeSessions[session.code]}</Col>
                      </Row>
                      <Row key={session.code}>
                        <Col xs>
                          <Form.Group controlId={session.code}>
                            <Form.Check
                              type="checkbox"
                              key={session.code}
                              checked={attendeeSessions[session.code]}
                              onChange={(e) => {
                                uncheckOrCheckSession(
                                  session.code,
                                  e.target.checked
                                );
                                if (e.target.checked) {
                                  updateSessionQty(session.code, 1);
                                } else {
                                  updateSessionQty(session.code, 0);
                                }
                              }}
                              label={session.name}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      {session.enableQty && (
                        <Row key={session.code + "qty-row"}>
                          <Col>
                            <Form.Group controlId={session.code + "qty"}>
                              <Form.Label>{"Qty"}</Form.Label>
                              <Form.Control
                                type="text"
                                onChange={(e) => {
                                  let qtyInt = 0;
                                  if (e.target.value != "") {
                                    qtyInt = parseInt(e.target.value, 10);
                                  } else {
                                    qtyInt = 0;
                                  }
                                  if (!Number.isInteger(qtyInt)) {
                                    uncheckOrCheckSession(session.code, false);
                                    return;
                                  }
                                  if (qtyInt <= 0) {
                                    uncheckOrCheckSession(session.code, false);
                                  } else {
                                    uncheckOrCheckSession(session.code, true);
                                  }
                                  let vals = [];
                                  for (const key in itemQuantities) {
                                    vals[key] = itemQuantities[key];
                                  }

                                  vals[session.code] = parseInt(qtyInt, 10);
                                  setItemQuantities(vals);
                                }}
                                value={itemQuantities[session.code]}
                              />
                            </Form.Group>
                          </Col>
                          <Col></Col>
                        </Row>
                      )}
                      <Row key={session.code + "time"}>
                        <Col>
                          {" "}
                          (
                          {moment(session.beginTime).format(
                            "MM/DD/YYYY h:mm a"
                          )}{" "}
                          -{" "}
                          {moment(session.endTime).format("MM/DD/YYYY h:mm a")})
                        </Col>
                      </Row>
                      <Row key={session.code + "signedup"}>
                        <Col className={styles.progressHeader}>
                          Signed Up ({session.seatsUsed} of {session.seatsLeft})
                        </Col>
                      </Row>
                      <Row key={session.code + "scannedIn"}>
                        <Col className={styles.progressHeader}>
                          Scanned in ({session.scannedIn} of {session.seatsUsed}
                          )
                        </Col>
                      </Row>
                      <Row key={session.code + "notCheckedIn"}>
                        <Col className={styles.progressHeader}>
                          Signed up but not checked in to the event (
                          {session.signedUpNotCheckedIn} of {session.seatsUsed})
                          <hr />
                        </Col>
                      </Row>
                    </>
                  );
                })}
              </Col>
            </Row>
          )}

          {hasBadgeTypes === false && (
            <>
              <Alert variant={"danger"}>
                <h3>There are no badge types defined.</h3>
                <h4>
                  Before adding attendees, badge types must be added. To add a
                  badge type, click the 'Event Setup' button at the top and then
                  click the 'Badge Type' tab.
                </h4>
              </Alert>
            </>
          )}
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Button
          onClick={(e) => {
            if (props.editBadgeNumber) {
              updateAttendee(e);
            } else {
              addAttendee(e);
            }
          }}
        >
          Save
        </Button>
        <Button
          onClick={() => {
            setAttendeeSessions([]);
            setFieldValues([]);
            props.setEditBadgeNumber(null);
            props.setShowModal(false);
          }}
        >
          Close
        </Button>
        {props.editBadgeNumber && (
          <Button
            onClick={(e) => {
              e.preventDefault();
              setInfoMessage(
                `Copying ${fieldValues["firstName"]} ${fieldValues["lastName"]}`
              );
              props.setEditBadgeNumber(null);
            }}
          >
            Copy Attendee
          </Button>
        )}
        {infoMessage && <Alert variant="info">{infoMessage}</Alert>}
      </Modal.Footer>
    </Modal>
  );
}

export default React.memo(ModalEdit);
