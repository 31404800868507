import React, { useState, useEffect } from "react";
import { Container, Row, Col, Nav, Navbar } from "react-bootstrap";

import Reports from "../reports/reports";

import Setup from "../setup/setup";
//import Logo from '../../assets/img/Logo.png';
import ImportAttendees from "../importAttendees/importAttendees";
import Emails from "../emailConfirmations/emails";
import Certificates from "../certificates/certificates";
import { useNavigate } from "react-router-dom";

function Navigation(props) {
  const navigate = useNavigate();

  const handleSubmit = (newPath) => {
    navigate(newPath);
  };

  const [showReports, setShowReports] = useState(false);
  const [showSetup, setShowSetup] = useState(false);
  const [showAttendeeImport, setShowAttendeeImport] = useState(false);
  const [showAttendeeEmails, setShowAttendeeEmails] = useState(false);
  const [showCertificates, setShowCertificates] = useState(false);
  const [checkinStats, setCheckinStats] = useState({
    cancelled: 0,
    checkedIn: 0,
    totalAttendees: 0,
    notCheckedIn: 0,
  });

  const logout = (e) => {
    e.preventDefault();
    localStorage.setItem("adsfdfsasdfdfsa", null);
    localStorage.setItem("adsfdfsasdfdfsashowcode", null);
    props.setUser(null);
    window.location.href = "/" + props.user.customerOrganizationID;
  };
  function GetCheckInStats() {
    fetch(global.config.settings.wsURL + "admin/AttendeeCheckInCount", {
      method: "GET",
      headers: {
        accept: "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          props.setLocalError(null);
          setCheckinStats(data);
        }
      })
      .catch((error) => {
        props.setLocalError(
          "Attendee checkins - Network exception: Check your network if this message does not go away: " +
            error
        );

        console.error("Error:", error);
      });
  }
  useEffect(() => {
    GetCheckInStats();

    var stats = setInterval(GetCheckInStats, 7000);
    return function cleanupInterval() {
      clearInterval(stats);
    };
  }, []);

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        className="color-nav"
        bg="dark"
        variant="dark"
      >
        <Navbar.Brand> &nbsp; OMNIEVENT®</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            {props.isAdmin && (
              <>
                <Nav.Link
                  onClick={(e) => {
                    e.preventDefault();
                    setShowReports(true);
                  }}
                >
                  Reporting
                </Nav.Link>
                <Nav.Link
                  onClick={(e) => {
                    e.preventDefault();
                    let path = "./ScanUtilitiesMain/";
                    handleSubmit(path);
                  }}
                >
                  Scanning
                </Nav.Link>
                <Nav.Link
                  onClick={(e) => {
                    e.preventDefault();
                    setShowSetup(true);
                  }}
                >
                  Setup
                </Nav.Link>
                <Nav.Link
                  onClick={(e) => {
                    e.preventDefault();
                    setShowAttendeeImport(true);
                  }}
                >
                  Attendee Import
                </Nav.Link>
                <Nav.Link
                  onClick={(e) => {
                    e.preventDefault();
                    setShowAttendeeEmails(true);
                  }}
                >
                  Emails
                </Nav.Link>
                <Nav.Link
                  onClick={(e) => {
                    e.preventDefault();
                    setShowCertificates(true);
                  }}
                >
                  Certificates
                </Nav.Link>
              </>
            )}
          </Nav>
          <Nav>
            <Nav.Link onClick={logout}>Logout</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      {showReports && (
        <Reports
          user={props.user}
          setGlobalError={props.setGlobalError}
          attendeeList={props.attendeeList}
          showCode={props.showCode}
          setShowModal={setShowReports}
          showModal={showReports}
        />
      )}
      {showSetup && (
        <Setup
          user={props.user}
          setRefreshRegistrationTypes={props.setRefreshRegistrationTypes}
          setGlobalError={props.setGlobalError}
          setRefreshFields={props.setRefreshFields}
          showCode={props.showCode}
          setShowModal={setShowSetup}
          showModal={showSetup}
        />
      )}
      {showAttendeeImport && (
        <ImportAttendees
          setGlobalError={props.setGlobalError}
          user={props.user}
          showCode={props.showCode}
          setShowModal={setShowAttendeeImport}
          showModal={showAttendeeImport}
        />
      )}
      {showAttendeeEmails && (
        <Emails
          setGlobalError={props.setGlobalError}
          customerID={props.user.customerOrganizationID}
          user={props.user}
          showCode={props.showCode}
          setShowModal={setShowAttendeeEmails}
          showModal={showAttendeeEmails}
        />
      )}
      {showCertificates && (
        <Certificates
          setGlobalError={props.setGlobalError}
          customerID={props.user.customerOrganizationID}
          user={props.user}
          showCode={props.showCode}
          setShowModal={setShowCertificates}
          showModal={showCertificates}
        />
      )}
      <Container style={{ marginBottom: 5, backgroundColor: "white" }}>
        <Row>
          <Col style={{ paddingTop: "15px" }} md={12}>
            <h3> {props.showName}</h3>
            <h5>
              {props.showCode}{" "}
              {props.isAdmin && (
                <>
                  - Checked in - {checkinStats.checkedIn} of{" "}
                  {checkinStats.totalAttendees} ({checkinStats.cancelled}{" "}
                  cancellations)
                </>
              )}
            </h5>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default React.memo(Navigation);
