import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {  Button, Form, FormControl, Container, Row, Col, Table, Modal, Alert, Card } from 'react-bootstrap';
import ReactDataGrid, {SelectColumn } from 'react-data-grid'; 
import Styles from './users.module.css';

function EventsModal(props) {
    const handleClose = () => {
        setShowModal(false); 
        props.setEditGroupEventID(null)
    }
    const [showModal, setShowModal] = useState(true);
    const [selectedRows, setSelectedRows] = useState(new Set(React.Key));
    const [showList, setShowList] = useState([])
    function save(e) {
        e.preventDefault();
let bod = []

for (let showCode of selectedRows.keys()) {
    bod.push({
        userGroupName: props.editGroupUserID,
        customerOrganizationID: props.user.customerOrganizationID,
        showCode: showCode

    });
  } 
        fetch(global.config.settings.wsURL + 'admin/SaveGroupShows',
        {
            method: "POST",
            headers: { 
                'Content-Type': 'application/json',
                authorization: props.user.token,
                showCode: props.showCode, 
                userGroupID: props.editGroupEventID
            },
            body: JSON.stringify(bod)
        }
    )
        .then(function (response) {
            if (!response.ok) {
                console.log("Status " + response.status);

                return null;
            }
            return response.json();
        })
        .then(data => { 
            if (data) {
              
                if (data.status != 'success') {
                    alert('There was an error saving these users.')
                } else {
                    props.setEditGroupEventID(null);
                }
            }
        }).catch(error => {
            props.setGlobalError("There was a problem accessing the Internet. Please check your connection and try again.");
            props.setEditGroupEventID(null);
            console.error('Error:', error);
          });
    }
    useEffect(() => {
         
        fetch(global.config.settings.wsURL + 'admin/GetShows',
            {
                method: "GET",
                headers: {
                    accept: "application/json",
                    authorization: props.user.token 
                }
            }
            )   
            .then(function (response) {
                if (!response.ok) {
                    console.log("Status " + response.status);

                    return null;
                }
                return response.json();
            })
            .then(data => {
                
                if (data) { 
                    console.log(data);
                    setShowList(JSON.parse(JSON.stringify(data)));
                    getDefaultShows();
                    
                }
            }).catch(error => {
                props.setGlobalError("There was a problem accessing the Internet. Please check your connection and try again.");
                props.setEditUsername(null);
                console.error('Error:', error);
              });
     
}, [] );
function getDefaultShows() {
        console.log(props.userGroupList);
        props.userGroupList.map((group) => {
            if (group.name === props.editGroupEventID) {
                const thisSet = new Set([]);
                group.shows.map((show) => {
                    thisSet.add(show.showCode);
                    console.log(show.showCode);
                })
            
                setSelectedRows(thisSet);
        
        }
    })
};
 
    const defaultColumnProperties = {
        height: 50,
        
        resizable: true,
      };

      const columns = [
        SelectColumn,
    {
    key: 'showCode',
    name: 'Code ',
    width: 160, 
    frozen: true,
     

    }, 
    { key: 'name', name: 'Name', },
         
    
        ].map((c) => ({ ...c, ...defaultColumnProperties }));
        
        function rowKeyGetter(row) { 
            return row.showCode;
            }      
      const EmptyRowsRenderer = () => {
          const message = "No users to show";
      
          return (
              <div style={{ textAlign: 'center' }}>Nothing to show <span lang="en" title="no data">No users</span></div>
          );
        };
    return (

        <Modal contentClassName={Styles.modal}   dialogClassName={Styles.modal} onHide={handleClose} show={showModal}> 
        <Modal.Header closeButton>
Editing events of the {props.editGroupEventID} group


        </Modal.Header>
            
        <Modal.Body style={{padding: "0px"}}  >
            <Container>
                <Row><Col>  
                        <ReactDataGrid
                            emptyRowsRenderer={EmptyRowsRenderer}
                            columns={columns} 
                            selectedRows={selectedRows}
                            onSelectedRowsChange={setSelectedRows}
                            rowKeyGetter={rowKeyGetter}
                            rows={showList}
                            rowHeight={50}
                            minHeight={600} 

                    />
                </Col></Row>
                </Container>
            </Modal.Body>
            <Modal.Footer style={{pading: "0px"}}>
        <Button
                    onClick={(e) => save(e) }
                >Save</Button>
             <Button
                    onClick={() => handleClose() }
                >Close</Button>
        </Modal.Footer>
        </Modal>
    )
}

export default EventsModal;