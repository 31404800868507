import React, { useState, useEffect, useRef, useMemo } from 'react';
import {  ProgressBar, Container, Row, Col, FormControl, Button } from 'react-bootstrap'; 
import styles from './reports.module.css'; 
import { Line } from "react-chartjs-2";
import Downloadable from './downloadable';
import moment from 'moment';
import ReactDataGrid, {SelectColumn}  from 'react-data-grid';
function Sessions(props) {
 
    const [sessions, setSessions] = useState([]);
    const [sessionAttendeeList, setSessionAttendeeList] = useState({ attendees: [],
        filteredAttendees: []});
    const [currentSessionID, setCurrentSessionID] = useState(null);
    const [selectedRows, setSelectedRows] = useState(new Set(React.Key));
    const searchTextRef = useRef('');

    const currentSessionIDRef = useRef();

    const AddOrRemoveCheckins= (e, isRemoving) => {
            const ids=Array.from(selectedRows);
            var newAttList = []
            sessionAttendeeList.filteredAttendees.map((att) => {
                if (isRemoving) {
                    att.scannedIn = null;
                    att.scannedOut = null;
                } else {
                    
                    att.scannedIn = moment().toISOString();
                }
                att.sessionCode = currentSessionID;
                if (ids.includes(att.badgeNumber)) {newAttList.push(JSON.parse(JSON.stringify(att)));}
            })
            if (newAttList.length == 0) {return; }
            fetch(global.config.settings.wsURL + 'admin/UpdateSessionCheckin',
            {
                method: "POST",
                headers: { 
                    'Content-Type': 'application/json',
                    authorization: props.user.token,
                    showCode: props.showCode, 
                },
                body: JSON.stringify(newAttList)
            }
        )
            .then(function (response) {
                if (!response.ok) {
                    console.log("Status " + response.status);
        
                    return null;
                }
                return response.json();
            })
            .then(data => { 
                if (data) {
                    
                    GetAttendees();
                }
            }).catch(error => {
                props.setGlobalError("There was a problem accessing the Internet. Please check your connection and try again.");
            
                console.error('Error:', error);
            });
             
        
        }
    

    
    const EmptyRowsRenderer = () => {
        const message = "No results. Click on a session to see the list of attendees.";
    
        return (
            <div style={{ textAlign: 'center' }}><span lang="en" title="no data">{message}</span></div>
        );
      };
    useEffect(() => {
        setSelectedRows(new Set(React.Key));
        currentSessionIDRef.current = currentSessionID;
        GetAttendees();
        
    }, [currentSessionID]);
    function GetSessions() {
        fetch(global.config.settings.wsURL + 'admin/GetSessions',
        {
            method: "GET",
            headers: {
                accept: "application/json",
                authorization: props.user.token,
                showCode: props.showCode 
            }
        }
        )
        .then(function (response) {
            if (!response.ok) {
                console.log("Status " + response.status);

                return null;
            }
            return response.json();
        })
        .then(data => {
            if (data) {
                
                setSessions(data); 
            }
        }).catch(error => {
            props.setGlobalError("There was a problem accessing the Internet. Please check your connection and try again.");
          
            console.error('Error:', error);
          }); 
    
    }

    function GetAttendees() {
        fetch(global.config.settings.wsURL + 'admin/GetAttendeesAttendingSessions',
        {
            method: "GET",
            headers: {
                accept: "application/json",
                authorization: props.user.token,
                showCode: props.showCode,
                sessionCode: currentSessionIDRef.current
            }
        }
        )
        .then(function (response) {
            if (!response.ok) {
                console.log("Status " + response.status);

                return null;
            }
            return response.json();
        })
        .then(data => {
            if (data) {
                data.map((m) => {
                    if (m.scannedIn != null) {
                        m.scannedIn = moment(m.scannedIn).format('MM/DD/YYYY h:mm:ss a');
                    }
                    if (m.scannedOut != null) {
                        m.scannedOut = moment(m.scannedOut).format('MM/DD/YYYY h:mm:ss a');
                    }
                    var attendeeString = '';
                    if (m.firstName) {attendeeString += m.firstName.toLowerCase();}
                    if (m.lastName) {attendeeString += m.lastName.toLowerCase();}
                    if (m.companyName) {attendeeString += m.companyName.toLowerCase();}
                    m.attendeeString = attendeeString.replace(new RegExp('[^a-zA-Z0-9]', 'g'), '');
                    //m.attendeeString = (m.firstName.toLowerCase() + m.lastName.toLowerCase() +
                    //m.companyName.toLowerCase()).replace(new RegExp('[^a-zA-Z0-9]', 'g'), '');
                })
                let newList = {
                    attendees: data,
                    filteredAttendees: data,
                }
                 
                filterByText(newList);
                
            }
        }).catch(error => {
            props.setGlobalError("There was a problem accessing the Internet. Please check your connection and try again.");
          
            console.error('Error:', error);
          }); 
    
    }
    function getData(e) {
         
        filterByText(sessionAttendeeList);
    }
    function filterByText( thisAttendeeList) {
         
        let newAttendeeList = JSON.parse(JSON.stringify(thisAttendeeList.attendees));
 


        let searchString = searchTextRef.current.value;
          if ( searchString.length > 1) {
            let thisString = searchString.toLowerCase().replace(new RegExp('[^a-zA-Z0-9]', 'g'), ''); 
             
            newAttendeeList = thisAttendeeList.attendees.filter(function(n) 
            { 
                return n.attendeeString.includes(thisString) }
            ); 
        } 

     
        setSessionAttendeeList({
            attendees: thisAttendeeList.attendees,
            filteredAttendees: newAttendeeList
        })
        
    }



    useEffect(() => {
        GetSessions(); 
        var sess = setInterval(GetSessions, 10000); 
        var attending = setInterval(GetAttendees, 10000);
        return function cleanupInterval() { 
            clearInterval(sess);
            clearInterval(attending);
        }
    
    },[]);

    const defaultColumnProperties = {
        height: 50,
        
        resizable: true,
      };

      function rowKeyGetter(row) { 
        return row.badgeNumber;
      }

    const columns = [
        SelectColumn,
        {
        key: 'badgeNumber',
        name: 'ID',
        width: 160, 
        frozen: true,
      
    
        }, 
        { key: 'firstName', name: 'First Name', },
        { key: 'lastName', name: 'Last Name', },
        { key: 'companyName', name: 'Company',  },
        { key: 'scannedIn', name: 'Scanned In', },
        { key: 'scannedOut', name: 'Scanned Out'}
    
        ].map((c) => ({ ...c, ...defaultColumnProperties }));



    return(

        <Container style={{ maxWidth: "100%"}}>

            <Row>
                <Col md={6}>
                    <Container>
                    <Row>
                    <   Col className={styles.graphHeader} ><h3>Sessions</h3><h5>Click a session to retrieve the list of attendees</h5></Col>
                    </Row> 
                    <div  style={{height: "50vh", overflowY: "auto", overflowX: "hidden"}}>
                    {sessions.map((session) => { 
                        return (
                            <div onClick={()=> { 
                            
                                setCurrentSessionID(session.code);
                            }} key={session.code} >
                                <Row><Col><hr /><h5>{session.name}<br /> ({moment(session.beginTime).format('MM/DD/YYYY h:mm:ss a')} - {moment(session.endTime).format('MM/DD/YYYY h:mm:ss a')})</h5></Col></Row>
                                <Row><Col  className={styles.progressHeader}>Signed Up ({session.seatsUsed} of {session.seatsLeft})</Col></Row>
                                <Row><Col><ProgressBar variant="success" min={0} max={session.seatsLeft} now={session.seatsUsed} /></Col></Row>
                                <Row><Col className={styles.progressHeader}>Scanned in ({session.scannedIn} of {session.seatsUsed})</Col></Row>
                                <Row><Col><ProgressBar min={0} variant="warning" max={session.seatsUsed} now={session.scannedIn} /></Col></Row>
                                <Row><Col className={styles.progressHeader}>Signed up but not checked in to the event ({session.signedUpNotCheckedIn} of {session.seatsUsed})</Col></Row>
                                <Row><Col><ProgressBar min={0} max={session.seatsUsed} now={session.signedUpNotCheckedIn} /></Col></Row>     
                            </div>
                        )
                    } 
                    ) 
                    }
                    </div>
        </Container>
                </Col>
                <Col md={6}>
                    <Container>
                        <Row>
                            <Col className={styles.graphHeader}  >
                                <h3>Results for: {currentSessionID}</h3>
                            </Col>
                        </Row>
                        <Row><Col>
                        <FormControl  
                                    placeholder="Search by name or company"
                                            aria-label="Name or company search"
                                            aria-describedby="Name or company search"
                                            ref={searchTextRef}
                                            onChange={(e) => getData(e)
                                        }
                                    />
                        </Col></Row>
                        <Row>
                            <Col>
                                <ReactDataGrid
                                    emptyRowsRenderer={EmptyRowsRenderer}
                                    columns={columns}  
                                    rows={sessionAttendeeList.filteredAttendees}
                                    rowHeight={50}
                                    minHeight={600}  
                                    selectedRows={selectedRows}
                                    onSelectedRowsChange={setSelectedRows}
                                    rowKeyGetter={rowKeyGetter}
                                />
                            </Col>
                        </Row>
                        <Row><Col></Col><Col style={{textAlign: "right", marginTop: "10px"}}>
                            <Button variant="warning"   block onClick={(e) =>{
                                AddOrRemoveCheckins(e, false);
                             
                            }}>SCAN-IN SELECTED ATTENDEES</Button></Col></Row>
                            <Row><Col></Col><Col style={{textAlign: "right", marginTop: "10px"}}>
                            <Button  variant="danger" block  onClick={(e) =>{
                                AddOrRemoveCheckins(e, true);
                             
                            }}>REMOVE SCANS</Button></Col></Row>
                    </Container>

                </Col>
                
            </Row>
        </Container>


    )
}
export default React.memo(Sessions);
