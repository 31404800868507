import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import Select, { components } from "react-select";

import ReactDataGrid, {
  SelectColumn,
  Column,
  RowsChangeData,
} from "react-data-grid";
import DeleteModal from "../deleteModal/deleteModal";
import RemoveCheckinModal from "../removeCheckinModal/removeCheckinModal";
function AttendeeList(props) {
  const [attendeeList, setAttendeeList] = useState({
    attendees: [],
    filteredAttendees: [],
  });
  const [selectedRows, setSelectedRows] = useState(new Set(React.Key));
  const [sortByRows, setSortByRows] = useState(["lastName", "firstName"]);
  const [sortDirection, setSortDirection] = useState(null);
  const [attendeesToDelete, setAttendeesToDelete] = useState([]);
  const [attendeesToRemoveCheckins, setAttendeesToRemoveCheckins] = useState(
    []
  );

  const sortDirectionRef = useRef(sortDirection);
  const sortByRowsRef = useRef(sortByRows);
  const searchTextRef = useRef("");
  const badgeTypeRef = useRef("all");
  const [badgeType, setBadgeType] = useState("all");
  const [statusSearch, setStatusSearch] = useState("all");
  const [selectedRowCount, setSelectedRowCount] = useState("0");
  const [resultCount, setResultCount] = useState("0");
  const statusSearchRef = useRef(statusSearch);

  const gridRef = useRef();

  const defaultColumnProperties = {
    height: 50,

    resizable: true,
  };

  const editFormatter = (rowData) => {
    return (
      <Button
        style={{ width: "90%" }}
        size="sm"
        bsPrefix="btn-omni"
        block
        onClick={(e) => props.getData(e, rowData.row.badgeNumber)}
      >
        Edit
      </Button>
    );
  };
  const printFormatter = (rowData) => {
    switch (rowData.row.status.toUpperCase()) {
      case "A":
        return (
          <Button
            bsPrefix="btn-omni"
            style={{ width: "95%" }}
            size="sm"
            block
            onClick={(e) => {
              e.preventDefault();
              props.setBadgesToPrint([rowData.row.badgeNumber]);
            }}
          >
            Print/Check-in
          </Button>
        );

      case "O":
        return (
          <Button
            bsPrefix="btn-omni"
            style={{ width: "95%" }}
            size="sm"
            block
            onClick={(e) => {
              e.preventDefault();
              props.setBadgesToPrint([rowData.row.badgeNumber]);
            }}
          >
            Reprint
          </Button>
        );

      case "V":
        return (
          <Button
            bsPrefix="btn-omni"
            style={{ width: "95%" }}
            size="sm"
            block
            onClick={(e) => {
              e.preventDefault();
              props.setBadgesToPrint([rowData.row.badgeNumber]);
            }}
          >
            Reprint
          </Button>
        );

      case "CX":
        return (
          <Button
            bsPrefix="btn-omni"
            style={{ width: "100%" }}
            size="sm"
            block
            onClick={(e) => {
              e.preventDefault();
              props.setBadgesToPrint([rowData.row.badgeNumber]);
            }}
          >
            Print/Check-in
          </Button>
        );

      case "P":
        return (
          <Button
            bsPrefix="btn-omni"
            style={{ width: "95%" }}
            size="sm"
            block
            onClick={(e) => {
              e.preventDefault();
              props.setBadgesToPrint([rowData.row.badgeNumber]);
            }}
          >
            Print/Check-in
          </Button>
        );

      default:
        return (
          <Button
            bsPrefix="btn-omni"
            style={{ width: "95%" }}
            size="sm"
            block
            onClick={(e) => {
              e.preventDefault();
              props.setBadgesToPrint([rowData.row.badgeNumber]);
            }}
          >
            Print/Check-in
          </Button>
        );
    }
  };
  const idFormatter = (rowData) => {
    switch (rowData.row.status.toUpperCase()) {
      case "A":
        return (
          <div style={{ height: "100%", color: "green" }}>
            {rowData.row.badgeNumber + " (Not printed)"}
          </div>
        );

      case "O":
        return (
          <div style={{ height: "100%", color: "blue" }}>
            {rowData.row.badgeNumber + " (Printed)"}
          </div>
        );

      case "V":
        return (
          <div style={{ height: "100%", color: "blue" }}>
            {rowData.row.badgeNumber + " (Printed)"}
          </div>
        );

      case "CX":
        return (
          <div style={{ height: "100%", color: "red" }}>
            {rowData.row.badgeNumber + " (Cancelled)"}
          </div>
        );

      case "P":
        return (
          <div style={{ height: "100%", color: "red" }}>
            {rowData.row.badgeNumber + " (Problem)"}
          </div>
        );

      default:
        return (
          <div style={{ height: "100%", color: "blue" }}>
            {rowData.row.badgeNumber + "(no status defined)"}
          </div>
        );
    }
  };

  const columns = [
    SelectColumn,
    {
      key: "badgeNumber",
      name: "ID",
      width: 160,
      frozen: true,
      formatter: idFormatter,
    },
    { key: "firstName", name: "First Name" },
    { key: "lastName", name: "Last Name" },
    { key: "companyName", name: "Company" },

    { key: "edit", name: "", width: 160, formatter: editFormatter },
    { key: "print", name: "", width: 160, formatter: printFormatter },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  const EmptyRowsRenderer = () => {
    return (
      <div style={{ textAlign: "center" }}>
        Nothing to show{" "}
        <span lang="en" title="no data">
          No attendees
        </span>
      </div>
    );
  };

  useEffect(() => {
    if (props.refreshAllBadges) {
      getAttendees();
      props.setRefreshAllBadges(false);
    }
  }, [props.refreshAllBadges]);

  useEffect(() => {
    if (props.refreshBadges != null) {
      let newList = JSON.parse(JSON.stringify(attendeeList));
      newList.attendees.map((attendee) => {
        props.refreshBadges.map((updatedAttendee) => {
          if (updatedAttendee.badgeNumber == attendee.badgeNumber) {
            attendee.status = updatedAttendee.status;
            attendee.firstName = updatedAttendee.firstName;
            attendee.lastName = updatedAttendee.lastName;
            attendee.companyName = updatedAttendee.companyName;
            attendee.checked = false;
          }
        });
      });
      newList.filteredAttendees.map((attendee) => {
        props.refreshBadges.map((updatedAttendee) => {
          if (updatedAttendee.badgeNumber == attendee.badgeNumber) {
            attendee.status = updatedAttendee.status;
            attendee.firstName = updatedAttendee.firstName;
            attendee.lastName = updatedAttendee.lastName;
            attendee.companyName = updatedAttendee.companyName;
            //attendee.checked = false;
          }
        });
      });

      setAttendeeList(newList);
    }
  }, [props.refreshBadges]);
  useEffect(() => {
    setSelectedRowCount(selectedRows.size);
  }, [selectedRows]);

  useEffect(() => {
    getAttendees();
    var getAtts = setInterval(getAttendees, 7000);

    return function cleanupInterval() {
      clearInterval(getAtts);
    };
  }, []);
  function getAttendees() {
    fetch(global.config.settings.wsURL + "admin/GetAttendees", {
      method: "GET",
      headers: {
        accept: "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);
          props.setLocalError(
            "Error getting attendee list: " + response.status
          );
          return null;
        }

        return response.json();
      })
      .then((data) => {
        if (data) {
          let limitedAttendees = [];
          data.map((attendee, cnt) => {
            attendee.firstName =
              attendee.firstName === null ? "" : attendee.firstName;
            attendee.lastName =
              attendee.lastName === null ? "" : attendee.lastName;
            attendee.companyName =
              attendee.companyName === null ? "" : attendee.companyName;
            attendee.attendeeString = (
              attendee.firstName.toLowerCase() +
              attendee.lastName.toLowerCase() +
              attendee.companyName.toLowerCase()
            ).replace(new RegExp("[^a-zA-Z0-9]", "g"), "");
            if (attendee.status.toLowerCase() === "v") attendee.color = "blue";
            if (attendee.status.toLowerCase() === "o") attendee.color = "blue";
            if (attendee.status.toLowerCase() === "cx") attendee.color = "red";
            attendee.checked = false;
          });
          props.setLocalError(null);
          let att = { attendees: data, filteredAttendees: data };
          props.setAttendeeList(att);
          filterByText(att);
        }
      })
      .catch((error) => {
        props.setLocalError(
          "Attendee List - Network exception: Check your network if this message does not go away: " +
            error
        );
        console.error("Error in Attendee List:", error);
      });
  }
  function getData(e) {
    //setSelectedRows(new Set(React.Key));
    //setSelectedRows(new Set(React.Key));
    filterByText(attendeeList);
    //gridRef.current.selectCell({ rowIdx: -1, idx: -1 });
    //gridRef.current.navigate(null);
  }
  function filterByText(thisAttendeeList) {
    let newAttendeeList = JSON.parse(
      JSON.stringify(thisAttendeeList.attendees)
    );
    if (badgeTypeRef.current != "all") {
      newAttendeeList = newAttendeeList.filter(function (n) {
        return n.type === badgeTypeRef.current;
      });
    }
    if (statusSearchRef.current != "all") {
      if (statusSearchRef.current === "printed") {
        newAttendeeList = newAttendeeList.filter(function (n) {
          return n.status.toLowerCase() === "v";
        });
      } else {
        newAttendeeList = newAttendeeList.filter(function (n) {
          return n.status.toLowerCase() != "v";
        });
      }
    }
    let searchString = searchTextRef.current.value;

    if (searchString.length > 0) {
      let thisString = searchString
        .toLowerCase()
        .replace(new RegExp("[^a-zA-Z0-9]", "g"), "");

      newAttendeeList = newAttendeeList.filter(function (n) {
        return n.attendeeString.includes(thisString);
      });
    }
    console.log("sort by rows " + sortByRowsRef.current);
    if (sortByRowsRef.current.length == 0) {
      newAttendeeList = newAttendeeList.sort((a, b) => {
        return Number(a.badgeNumber) - Number(b.badgeNumber);
      });
    }
    if (sortByRowsRef.current.length > 0) {
      newAttendeeList = newAttendeeList.sort((a, b) => {
        return a[sortByRowsRef.current[0]].localeCompare(
          b[sortByRowsRef.current[0]]
        );
      });
    }
    if (sortByRowsRef.current.length > 1) {
      newAttendeeList = newAttendeeList.sort((a, b) => {
        if (
          a[sortByRowsRef.current[0]].localeCompare(
            b[sortByRowsRef.current[0]]
          ) === 0
        ) {
          return a[sortByRowsRef.current[1]].localeCompare(
            b[sortByRowsRef.current[1]]
          );
        } else {
          return 0;
        }
      });
    }
    if (sortByRowsRef.current.length > 2) {
      newAttendeeList = newAttendeeList.sort((a, b) => {
        if (
          a[sortByRowsRef.current[0]].localeCompare(
            b[sortByRowsRef.current[0]]
          ) === 0 &&
          a[sortByRowsRef.current[1]].localeCompare(
            b[sortByRowsRef.current[1]]
          ) === 0
        ) {
          return a[sortByRowsRef.current[2]].localeCompare(
            b[sortByRowsRef.current[2]]
          );
        } else {
          return 0;
        }
      });
    }
    if (sortDirectionRef.current === "desc") {
      newAttendeeList.reverse();
    }

    setAttendeeList({
      attendees: thisAttendeeList.attendees,
      filteredAttendees: newAttendeeList,
    });
    setResultCount(newAttendeeList.length);
    //setSelectedRows(new Set(React.Key));
  }
  function rowKeyGetter(row) {
    return row.badgeNumber;
  }

  useEffect(() => {
    setSelectedRows(new Set(React.Key));
    sortDirectionRef.current = sortDirection;
    filterByText(attendeeList);
  }, [sortByRows, sortDirection, badgeType, statusSearch]);

  return (
    <>
      {attendeesToDelete.length > 0 && (
        <DeleteModal
          showCode={props.showCode}
          user={props.user}
          setGlobalError={props.setGlobalError}
          setAttendeesToDelete={setAttendeesToDelete}
          getAttendees={getAttendees}
          attendeesToDelete={attendeesToDelete}
        />
      )}
      {attendeesToRemoveCheckins.length > 0 && (
        <RemoveCheckinModal
          showCode={props.showCode}
          user={props.user}
          setGlobalError={props.setGlobalError}
          setAttendeesToRemoveCheckins={setAttendeesToRemoveCheckins}
          getAttendees={getAttendees}
          attendeesToRemoveCheckins={attendeesToRemoveCheckins}
        />
      )}
      <hr />
      <Container style={{ maxWidth: 3900 }}>
        <Row>
          <Col md={3} style={{ textAlign: "left" }}>
            <label htmlFor="SearchBar">Filter List</label>
            <Form.Control
              placeholder="Search by company or name"
              aria-label="Search by company or name"
              id="SearchBar"
              aria-controls="AttendeeList"
              ref={searchTextRef}
              autoComplete="off"
              spellCheck="false"
              onChange={(e) => getData(e)}
            />
          </Col>
          <Col md={3} style={{ textAlign: "left" }}>
            <label htmlFor="SortBy">Sort by columns</label>
            <SortBy
              setSortByRows={setSortByRows}
              sortByRowsRef={sortByRowsRef}
            />
          </Col>
          <Col md={2} style={{ textAlign: "left" }}>
            <label htmlFor="SortDirection">Sort Direction</label>
            <SortDirection setSortDirection={setSortDirection} />
          </Col>
          <Col md={2} style={{ textAlign: "left" }}>
            <label htmlFor="BadgeType">Badge Type</label>
            <AttTypeFilter
              setBadgeType={setBadgeType}
              badgeTypeRef={badgeTypeRef}
              registrationTypes={props.registrationTypes}
            />
          </Col>
          <Col md={2} style={{ textAlign: "left" }}>
            <label htmlFor="StatusFilter">Status</label>{" "}
            <StatusFilter
              id="StatusFilter"
              setStatusSearch={setStatusSearch}
              statusSearchRef={statusSearchRef}
            />
          </Col>
        </Row>

        <Row style={{ paddingTop: "1em" }}>
          <Col
            style={{ textAlign: "left", verticalAlign: "bottom" }}
            sm={0}
            lg={3}
          ></Col>

          <Col sm={6} lg={3}>
            <Button
              bsPrefix="btn-omni"
              style={{ margin: "0", width: "70%" }}
              onClick={(e) => props.getData(e, null)}
            >
              Add Attendee
            </Button>
          </Col>
          <Col sm={6} lg={3}>
            <Button
              style={{ width: "70%" }}
              bsPrefix="btn-omni"
              onClick={(e) => {
                e.preventDefault();
                props.setBadgesToPrint(Array.from(selectedRows));
              }}
            >
              Print/check-in selected{" "}
            </Button>
          </Col>
          <Col sm={0} lg={3}></Col>
        </Row>

        <Row>
          <Col></Col>{" "}
        </Row>
        <Row>
          {" "}
          <Col
            style={{ textAlign: "left", verticalAlign: "bottom" }}
            sm={0}
            lg={4}
          >
            {selectedRowCount} of {resultCount} rows selected
          </Col>{" "}
          <Col lg={4}> </Col>
          <Col lg={4}></Col>
        </Row>

        {attendeeList.filteredAttendees.length > 0 && (
          <>
            <Row>
              <Col>
                <ReactDataGrid
                  emptyRowsRenderer={EmptyRowsRenderer}
                  columns={columns}
                  selectedRows={selectedRows}
                  ref={gridRef}
                  onSelectedRowsChange={setSelectedRows}
                  rowKeyGetter={rowKeyGetter}
                  rows={attendeeList.filteredAttendees}
                  rowHeight={50}
                  minHeight={600}
                  id="AttendeeList"
                  aria-label="Filtered Attendee List"
                  style={{
                    "-webkit-overflow-scrolling": "auto",
                    overflowX: "scroll",
                  }}
                />
              </Col>
            </Row>
          </>
        )}
        {props.isAdmin && (
          <>
            <Row>
              <Col style={{ textAlign: "right", marginTop: "10px" }}>
                <Button
                  variant="danger"
                  onClick={(e) => {
                    e.preventDefault();
                    var newList = [];
                    const badgeArray = Array.from(selectedRows);
                    attendeeList.filteredAttendees.map((a) => {
                      if (badgeArray.includes(a.badgeNumber)) {
                        newList.push(JSON.parse(JSON.stringify(a)));
                      }
                    });

                    if (newList.length > 0) {
                      setAttendeesToRemoveCheckins(newList);
                    } else {
                      setAttendeesToRemoveCheckins([]);
                    }
                  }}
                >
                  REMOVE ATTENDEE CHECKINS
                </Button>
              </Col>
            </Row>

            <Row>
              <Col style={{ textAlign: "right", marginTop: "10px" }}>
                <Button
                  variant="danger"
                  onClick={(e) => {
                    e.preventDefault();
                    var newList = [];
                    const badgeArray = Array.from(selectedRows);
                    attendeeList.filteredAttendees.map((a) => {
                      if (badgeArray.includes(a.badgeNumber)) {
                        newList.push(JSON.parse(JSON.stringify(a)));
                      }
                    });
                    if (newList.length > 0) {
                      setAttendeesToDelete(newList);
                    } else {
                      setAttendeesToDelete([]);
                    }
                  }}
                >
                  DELETE SELECTED ATTENDEES
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  );
}

export default React.memo(AttendeeList);

const AttTypeFilter = React.memo((props) => {
  const options = [
    {
      value: "all",
      label: "Any",
    },
  ];
  props.registrationTypes.map((t) => {
    options.push({
      value: t.code,
      label: t.name,
    });
  });
  function onChange(value) {
    props.setBadgeType(value.value);
    props.badgeTypeRef.current = value.value;
  }
  return (
    <Select defaultValue={[options[0]]} onChange={onChange} options={options} />
  );
});

const SortDirection = React.memo((props) => {
  const options = [
    {
      value: "asc",
      label: "Ascending",
    },
    {
      value: "desc",
      label: "Descending",
    },
  ];
  function onChange(value) {
    props.setSortDirection(value.value);
  }
  return (
    <Select defaultValue={[options[0]]} onChange={onChange} options={options} />
  );
});

const StatusFilter = React.memo((props) => {
  const options = [
    {
      value: "all",
      label: "All",
    },
    {
      value: "notPrinted",
      label: "Not Printed",
    },
    {
      value: "printed",
      label: "Printed",
    },
  ];
  function onChange(value) {
    props.setStatusSearch(value.value);
    props.statusSearchRef.current = value.value;
  }
  return (
    <Select defaultValue={[options[0]]} onChange={onChange} options={options} />
  );
});

const SortBy = React.memo((props) => {
  const [open, setOpen] = useState(false);
  const options = [
    { value: "lastName", label: "Last Name" },
    { value: "firstName", label: "First Name" },
    { value: "companyName", label: "Company" },
  ];

  function onChange(value, { action, removedValue }) {
    switch (action) {
      case "remove-value":
      case "pop-value":
        if (removedValue.isFixed) {
          return;
        }
        break;
      case "clear":
        break;
    }
    let sortValues = [];
    value.map((val) => sortValues.push(val.value));

    props.setSortByRows(sortValues);
    props.sortByRowsRef.current = sortValues;
  }
  return (
    <Select
      defaultValue={[options[0], options[1]]}
      id={"SortBy"}
      isMulti
      onChange={onChange}
      options={options}
    />
  );
});

const SearchBar = React.memo((props) => {
  return (
    <Form.Control
      placeholder="Search by company or name"
      aria-label="Search by company or name"
      aria-describedby="Search by company or name"
      id="SearchBar"
      onChange={(e) => props.getData(e)}
    />
  );
}, []);
