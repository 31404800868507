import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Styles from "./login.module.css";
import {
  Button,
  FormControl,
  Container,
  Row,
  Col,
  Alert,
} from "react-bootstrap";
import Toast from "react-bootstrap/Toast";

import ToastBody from "react-bootstrap/ToastBody";
import AnimatedLogo from "../../assets/media/AnimatedLogo.mp4";
import ToastHeader from "react-bootstrap/ToastHeader";

function getCellActions(column, row) {
  alert(column.key);
  if (column.key === "title") {
    return [
      {
        icon: <button>asdf</button>,
        callback: () => {
          alert("Deleting " + row.firstName);
        },
      },
      {
        icon: "glyphicon glyphicon-link",
        actions: [
          {
            text: "Campaign Linking",
            callback: () => {
              alert("Navigating to camapign linking");
            },
          },
        ],
      },
    ];
  }
}

function Login(props) {
  const [errorMsg, setErrorMsg] = useState(null);
  const [showError, setShowError] = useState(false);
  const [customerID, setCustomerID] = useState(props.CustomerID);
  const userNameRef = useRef("");
  const pwRef = useRef("");

  const [alertVisible, setAlertVisible] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true);

    setTimeout(function () {
      setAlertVisible(false);
    }, 2000);
  };

  useEffect(() => {
    if (
      localStorage.getItem("adsfdfsasdfdfsaCUstomerID") != null &&
      props.CustomerID == "home"
    ) {
      window.location.href =
        "/" + localStorage.getItem("adsfdfsasdfdfsaCUstomerID");
    }
  }, []);

  function verify(e) {
    e.preventDefault();

    fetch(global.config.settings.wsURL + "admin/login", {
      method: "GET",
      headers: {
        accept: "application/json",
        customerID: customerID,
        password: pwRef.current,
        userName: userNameRef.current,
      },
    })
      .then(function (response) {
        if (!response.ok) {
          setErrorMsg("There was an error logging in");
          setShowError(true);
          return null;
        }

        return response.json();
      })
      .then((data) => {
        if (data) {
          props.setUser(data);
        }
      })
      .catch((error) => {
        props.setGlobalError(
          "There was a problem accessing the Internet. Please check your connection and try again."
        );

        console.error("Error:", error);
      });
  }

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        verify(event);
        // callMyFunction();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col md={3}></Col>
          <Col md={6} sm={12}>
            <video
              width="100%"
              muted
              autoPlay
              style={{
                pointerEvents: "none",
              }}
              playsInline
            >
              <source src={AnimatedLogo} type="video/mp4" />
            </video>
          </Col>
          <Col md={3}></Col>
        </Row>

        <Row>
          <Col md={4}></Col>
          <Col md={4} sm={12}>
            <FormControl
              className={Styles.marginArea}
              placeholder="User Name"
              id="User"
              aria-label="User Name"
              onChange={(e) => {
                userNameRef.current = e.target.value.toLowerCase();
                //setUserName(e.target.value.toLowerCase());
              }}
            />
          </Col>
          <Col md={4}></Col>
        </Row>

        <Row>
          <Col md={4}></Col>
          <Col md={4} sm={12}>
            <FormControl
              className={Styles.marginArea}
              type="Password"
              placeholder="Password"
              aria-label="Password"
              onChange={(e) => {
                pwRef.current = e.target.value;
                //setPW(e.target.value);
              }}
            />
          </Col>
          <Col md={4}></Col>
        </Row>

        <Row>
          <Col md={4}></Col>
          <Col md={4} sm={12}>
            <Button
              bsPrefix="btn-omni"
              className={Styles.marginArea}
              size="md"
              onClick={(e) => verify(e)}
              block
            >
              {" "}
              Login{" "}
            </Button>
          </Col>
          <Col md={4}></Col>
        </Row>
        {showError === true && (
          <Row>
            <Col></Col>
            <Col>
              <Alert
                key="Alert"
                style={{ width: "100%" }}
                aria-label="Error Msg"
                className={Styles.marginArea}
                size="md"
                variant="danger"
              >
                {" "}
                {errorMsg}{" "}
              </Alert>
            </Col>
            <Col></Col>
          </Row>
        )}
      </Container>
    </>
  );
}
export default Login;
