import React, {useState, useEffect, useRef} from 'react';
import {  Button,  Modal, Alert, Container, Row, Col, Card, Form  } from 'react-bootstrap'; 
import "bootstrap/dist/css/bootstrap.min.css";
import BeepSound from '../../assets/media/Beep.mp3';
import ErrorSound from '../../assets/media/Error.mp3';
import useSound from 'use-sound';
import moment from 'moment';

function SessionScanPC(props) {
    const [playBeep] = useSound(BeepSound);
    const [playError] = useSound(ErrorSound); 
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [showError, setShowError] = useState(false);
    const [showQRScanner, setShowQRScanner] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');    
    const searchTextRef = useRef('');
    const handleClose = () => props.setShowModal(false);
    
    const handleOkVisible = () => {  
        playBeep();
        setShowSuccess(true);
         
        setTimeout(function() {
            setShowSuccess(false);
            setShowQRScanner(true);
            searchTextRef.current.focus();
        }, 2000);
    }
    const handleErrorVisible = () => {  
        setShowError(true);
        playError();
         
        setTimeout(function() {
            setShowError(false);
            setShowQRScanner(true);
            searchTextRef.current.focus();
        }, 2000);
    } 
    const handleScan = (scannedData) => {
        
         
         
        if (scannedData) {
            console.log(scannedData);
            setShowQRScanner(false);
          fetch (global.config.settings.wsURL + 'admin/SessionScan',
          {
              method: "POST",
              headers: {
                  "content": "application/json",
                  "customerOrganizationID": props.user.customerOrganizationID,
                  "showCode": props.showCode,
                  "badgeID": scannedData,
                  "sessionCode": props.sessionID,
                  "scanTime": moment().toISOString(true) //sends in pc time, not utc

              }
          }
          ).then(
              function(response) {
                  if (!response.ok) {
                    setErrorMessage("Error: Attendee not found!");
                    handleErrorVisible();
                      console.log("Status " + response.status);
                  } else {
                      return (response.json());
                  }
                   
              }
              

          ).then
          (
              data => {
                
                console.log(data);
                if (data) {
                    if (data.status === "error") {
                        setErrorMessage( data.message);
                        handleErrorVisible();
                    } else {

                        setSuccessMessage( data.message);
                        handleOkVisible();
                    }
         
              }
            }
          ).catch( error => {
            console.log(error)
                setErrorMessage("Error connecting to the server.");
                handleErrorVisible();
              
            }
          ) 
           
        }  
      }
    const  handleError = (err) => {
        console.error(err)
        
      }

      useEffect(() => { 
        searchTextRef.current.focus();
        const listener = event => {
            searchTextRef.current.focus();
          if (event.code === "Enter" || event.code === "NumpadEnter") {
            
            if (searchTextRef.current.value != '') {
                handleScan(searchTextRef.current.value);

            }
             
          }
        };
        document.addEventListener("keydown", listener);
        return () => {
          document.removeEventListener("keydown", listener);
        };
      }, []);

    return (
        <Container>
            <Row><Col md={1}></Col><Col md={10}>
                <Card className="text-center">
            <Card.Header><h3>Session Scanning for - {props.sessionID}</h3><br /><br />Center the attendee's barcode  
                beneath the barcode scanner until until it is read.
            </Card.Header>
            <Card.Body>
                <Card.Title style={{"border": "1px"}}>
                    {showQRScanner &&
                        <Form.Control  
                        placeholder="Scan your badge"
                                aria-label="Search by company or name"
                                autoComplete="new-password"
                                id="BarcodeNumber"
                                 
                                ref={searchTextRef} 
                        />
                    }
                        <Alert show={showSuccess} variant={"success"}><h1>{successMessage}</h1></Alert>
            <Alert show={showError} variant={"danger"}><h1>{errorMessage}</h1></Alert>
                </Card.Title>
                <Card.Text  className="text-center">
        
                </Card.Text>
                
            </Card.Body>
            <Card.Footer className="text-muted">
        
            </Card.Footer>
            </Card> 
            </Col><Col md={1}></Col>
        </Row>
    </Container>
    )
}

export default SessionScanPC;