import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Button, Spinner, Modal, Alert } from "react-bootstrap";
import styles from "./badgeModal.module.css";
import printJS from "print-js";
import moment from "moment";

function BadgeModal(props) {
  const handleClose = () => {
    setPdfData(null);
    props.setBadgesToPrint([]);
    props.setRefreshAllBadges(true);
    setShowModal(false);
  };

  const [showModal, setShowModal] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const [errMsg, setErrMsg] = useState(null);
  const [loadingMsg, setLoadingMsg] = useState(null);

  const printBadge = (e) => {
    e.preventDefault();
    printJS({ printable: pdfData, type: "pdf", showModal: false });
  };

  useEffect(() => {
    let offset = moment().utcOffset();
    setLoadingMsg("Loading badges...Please wait");
    let v = [];
    props.badgesToPrint.map((item) => {
      v.push({ BadgeId: item });
    });

    if (props.badgesToPrint.length > 0) {
      fetch(global.config.settings.wsURL + "admin/badges", {
        method: "PATCH",
        headers: {
          accept: "*/*",
          authorization: props.user.token,
          showCode: props.showCode,
          contentType: "application/json",
          "Content-Type": "application/json",
          //badgeNumbers: JSON.stringify(props.badgesToPrint),
          scanTime: moment().add(offset, "minutes").toISOString(true),
        },
        body: JSON.stringify(v),
      })
        .then(function (response) {
          if (!response.ok) {
            console.log("Status " + response.status);
            setLoadingMsg("Error loading badges");
            return null;
          }
          return response.blob();
        })
        .then((data) => {
          setLoadingMsg(null);
          if (data) {
            const url = window.URL.createObjectURL(
              new Blob([data], { type: "application/pdf" })
            );

            setPdfData(url);
            setErrMsg(null);
          } else {
            setErrMsg("No badge template defined for this attendee.");
          }
        })
        .catch((error) => {
          props.setGlobalError(
            "There was a problem accessing the Internet. Please check your connection and try again."
          );

          console.error("Error:", error);
        });
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [props.badgesToPrint]);

  return (
    <Modal
      contentClassName={styles.modal}
      dialogClassName={styles.modal}
      onHide={handleClose}
      show={showModal}
    >
      <Modal.Header closeButton>
        {loadingMsg && (
          <div style={{ textAlign: "center", width: "100%" }}>
            <h2>{loadingMsg}</h2>
            <Spinner animation="border" role="status"></Spinner>
          </div>
        )}
      </Modal.Header>

      <Modal.Body style={{ padding: "0px" }}>
        <div style={{ height: "100%", width: "100%", position: "absolute" }}>
          {pdfData != null && (
            <embed
              id="pdf"
              src={pdfData}
              type="application/pdf"
              width="100%"
              height="100%"
            />
          )}
          {errMsg && (
            <Alert variant={"danger"}>
              <h4>{errMsg}</h4>
              <p>There was an issue displaying the badge.</p>
              <p>The badge template is either not defined or corrupted.</p>
              <p>
                Make sure that the badge template for this badge type has been
                defined.
              </p>
              <p>
                Badge templates are set by going to the 'Event Setup' button and
                clicking the 'Badge Type'.
              </p>
            </Alert>
          )}
        </div>
      </Modal.Body>
      {errMsg == null && (
        <Modal.Footer>
          <Button
            style={{ width: "100%" }}
            bsPrefix="btn-omni"
            type="button"
            id="printButton"
            onClick={(e) => printBadge(e)}
          >
            {" "}
            Print Badge
          </Button>
        </Modal.Footer>
      )}
      <Modal.Footer style={{ pading: "0px" }}>
        {" "}
        <Button
          onClick={(e) => {
            e.preventDefault();
            handleClose();
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default React.memo(BadgeModal);
