import React from 'react';
import {  ProgressBar, Container, Row, Col, Button } from 'react-bootstrap'; 
import styles from './reports.module.css'; 


function Downloadable(props) {
    function getAllAttendeeData(e) { 
        e.preventDefault();
     
        fetch(global.config.settings.wsURL + 'Admin/GetAllAttendeeData',
            {
                method: "GET",
                headers: {
                accept: "*/*",
    
                    authorization: props.user.token,
                    showCode: props.showCode 
    
    
                }
            }
        )
        .then((response) => response.blob())
        .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `Attendees.xlsx`,
            );
    
            // Append to html link element page
            document.body.appendChild(link);
    
            // Start download
            link.click();
    
            // Clean up and remove the link
            link.parentNode.removeChild(link);
        }).catch(error => {
            props.setGlobalError("There was a problem accessing the Internet. Please check your connection and try again.");
          
            console.error('Error:', error);
          }); 
    }
    function getAllSessionAttendance(e) { 
        e.preventDefault();
     
        fetch(global.config.settings.wsURL + 'Admin/GetAllSessionAttendance',
            {
                method: "GET",
                headers: {
                accept: "*/*",
    
                    authorization: props.user.token,
                    showCode: props.showCode 
    
    
                }
            }
        )
        .then((response) => response.blob())
        .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `Attendees.xlsx`,
            );
    
            // Append to html link element page
            document.body.appendChild(link);
    
            // Start download
            link.click();
    
            // Clean up and remove the link
            link.parentNode.removeChild(link);
        }); 
    }


    return (
        <Container>
            <Row><Col style={{textAlign:"center", paddingTop: "25px"}}><h5>Downloads</h5></Col></Row>
        <Row><Col><Button   onClick={(e)=> getAllAttendeeData(e)} style={{width: "100%"}}  bsPrefix="btn-omni" bs size="md" >
            Download all attendee data
        </Button></Col></Row>
        <Row><Col><Button   onClick={(e)=> getAllSessionAttendance(e)} style={{width: "100%"}}  bsPrefix="btn-omni" size="md" >
            Download all session attendance
        </Button></Col></Row>
        </Container>
    )
}

export default React.memo(Downloadable);