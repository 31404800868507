import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Container,
  Col,
  Row,
  Alert,
  Modal,
} from "react-bootstrap";
import Styles from "./createShowModal.module.css";

function CreateShowModal(props) {
  const handleClose = () => props.setShowAddShowModal(false);
  const [show, setShow] = useState({
    showCode: "",
    customerOrganizationID: 0,
    showName: "",
    showStartDate: "2020-01-01T00:00:00",
    showEndDate: "2080-01-01T00:00:00",
    registrationStartDate: "2020-01-01T00:00:00",
    registrationEndDate: "2080-01-01T00:00:00",
  });
  const [errorMsg, setErrorMsg] = useState(null);
  function handleSave(e) {
    e.preventDefault();
    fetch(global.config.settings.wsURL + "admin/AddShow", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: props.user.token,
        showCode: show.showCode,
      },
      body: JSON.stringify(show),
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (data.status === "success") {
            alert(data.message);
            props.setShowAddShowModal(false);
          } else {
            setErrorMsg(data.message);
          }
        }
      });
  }
  return (
    <Modal onHide={handleClose} show={props.setShowAddShowModal}>
      <Modal.Header>Create a new event</Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col>
              <Container>
                <Row>
                  <Col>
                    <Form.Group controlId="formCode">
                      <Form.Label>Event Code</Form.Label>
                      <Form.Control
                        value={show.showCode}
                        type="text"
                        onChange={(e) => {
                          let tempShow = JSON.parse(JSON.stringify(show));
                          tempShow.showCode = e.target.value;
                          setShow(tempShow);
                        }}
                      />
                      <Form.Text className="text-muted">
                        Unique identifier for this event. May only contain
                        letters and numbers
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="formName">
                      <Form.Label>Event Name</Form.Label>
                      <Form.Control
                        value={show.showName}
                        onChange={(e) => {
                          let tempShow = JSON.parse(JSON.stringify(show));
                          tempShow.showName = e.target.value;
                          setShow(tempShow);
                        }}
                        type="text"
                      />
                      <Form.Text className="text-muted">
                        Name that will be displayed for this event.
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      bsPrefix="btn-omni"
                      style={{ width: "100%" }}
                      onClick={(e) => handleSave(e)}
                      block
                    >
                      Add this event
                    </Button>
                  </Col>
                </Row>
                {errorMsg != null && (
                  <Row>
                    <Col>
                      <Alert
                        key="Alert"
                        style={{ width: "100%" }}
                        className={Styles.marginArea}
                        size="md"
                        variant="danger"
                      >
                        {" "}
                        {errorMsg}{" "}
                      </Alert>
                    </Col>
                  </Row>
                )}
              </Container>
            </Col>{" "}
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        {" "}
        <Button
          bsPrefix="btn-omni"
          onClick={() => {
            props.setShowAddShowModal(false);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default React.memo(CreateShowModal);
