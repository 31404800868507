import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Form,
  FormControl,
  Container,
  Row,
  Col,
  Collapse,
  Table,
  Accordion,
  Card,
  AccordianContext,
  useContext,
} from "react-bootstrap";
import Select, { components } from "react-select";
import styles from "./users.module.css";
import GroupUserModal from "./groupUserModal";
import EventsModal from "./eventsModal";
import EditGroupModal from "./editGroupModal";
import ReactDataGrid, { SelectColumn } from "react-data-grid";
import EditUser from "./editUser";
import { ContextAwareToggle } from "../../utilities/accordion.js";
import ChangeAccessCodeModal from "./changeAccessCodeModal";

function Users(props) {
  const [userGroupList, setUserGroupList] = useState([]);
  const [editGroupUserID, setEditGroupUserID] = useState(null);
  const [editGroupEventID, setEditGroupEventID] = useState(null);
  const [editGroupID, setEditGroupID] = useState(null);
  const [userList, setUserList] = useState([]);
  const [editUsername, setEditUsername] = useState(null);
  const [editPasswordUsername, setEditPasswordUsername] = useState(null);
  const defaultColumnProperties = {
    height: 50,

    resizable: true,
  };

  const editFormatter = (rowData) => {
    return (
      <>
        {rowData.row.name != "Administrator" && (
          <Button
            style={{ width: "90%" }}
            size="sm"
            bsPrefix="btn-omni"
            block
            onClick={(e) => {
              e.preventDefault();
              setEditGroupID(rowData.row.name);
            }}
          >
            Edit
          </Button>
        )}
        {rowData.row.name == "Administrator" && <div>Cannot be modified</div>}
      </>
    );
  };
  const editShowsFormatter = (rowData) => {
    return (
      <>
        {rowData.row.name != "Administrator" && (
          <Button
            style={{ width: "90%" }}
            size="sm"
            bsPrefix="btn-omni"
            block
            onClick={(e) => {
              e.preventDefault();

              setEditGroupEventID(rowData.row.name);
            }}
          >
            Edit Events
          </Button>
        )}
        {rowData.row.name == "Administrator" && (
          <div>Can access all events</div>
        )}
      </>
    );
  };

  const editUsersFormatter = (rowData) => {
    return (
      <Button
        style={{ width: "90%" }}
        size="sm"
        bsPrefix="btn-omni"
        block
        onClick={(e) => {
          e.preventDefault();
          setEditGroupUserID(rowData.row.name);
        }}
      >
        Edit Users
      </Button>
    );
  };

  const editUserFormatter = (rowData) => {
    return (
      <>
        {rowData.row.username != "Admin" && (
          <Button
            style={{ width: "90%" }}
            size="sm"
            bsPrefix="btn-omni"
            block
            onClick={(e) => {
              e.preventDefault();
              setEditUsername(rowData.row.username);
            }}
          >
            Edit User
          </Button>
        )}
        {rowData.row.username == "Admin" && <div>Cannot be modified</div>}
      </>
    );
  };

  const editAccessCodeFormatter = (rowData) => {
    return (
      <>
        {rowData.row.username != "Admin" && (
          <Button
            style={{ width: "90%" }}
            size="sm"
            bsPrefix="btn-omni"
            block
            onClick={(e) => {
              e.preventDefault();
              setEditPasswordUsername(rowData.row.username);
            }}
          >
            Edit Access Code
          </Button>
        )}
      </>
    );
  };

  const columns = [
    { key: "edit", name: "", width: 160, formatter: editFormatter },

    { key: "name", name: "Group Name" },
    { key: "description", name: "Description" },

    { key: "editShow", name: "", width: 160, formatter: editShowsFormatter },
    { key: "editUser", name: "", width: 160, formatter: editUsersFormatter },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  const userGridColumns = [
    { key: "edit", name: "", width: 160, formatter: editUserFormatter },
    { key: "username", name: "Username" },
    { key: "firstName", name: "Last Name" },
    { key: "lastName", name: "Last Name" },
    { key: "editPW", name: "", formatter: editAccessCodeFormatter },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  const EmptyUserRowsRenderer = () => {
    const message = "No results to show";

    return (
      <div style={{ textAlign: "center" }}>
        Only administrators are allowed to edit users.
      </div>
    );
  };
  const EmptyGroupRowsRenderer = () => {
    const message = "No results to show";

    return (
      <div style={{ textAlign: "center" }}>
        Only administrators are allowed to edit groups.
      </div>
    );
  };
  useEffect(() => {
    fetch(global.config.settings.wsURL + "admin/GetUserGroups", {
      method: "GET",
      headers: {
        accept: "application/json",
        authorization: props.user.token,
        username: props.user.username,
      },
    })
      .then(function (response) {
        if (!response.ok) {
          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          setUserGroupList(JSON.parse(JSON.stringify(data)));
        }
      });

    fetch(global.config.settings.wsURL + "admin/GetUsers", {
      method: "GET",
      headers: {
        accept: "application/json",
        authorization: props.user.token,
        username: props.user.username,
      },
    })
      .then(function (response) {
        if (!response.ok) {
          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          setUserList(JSON.parse(JSON.stringify(data)));
        }
      })
      .catch((error) => {
        props.setGlobalError(
          "There was a problem accessing the Internet. Please check your connection and try again."
        );

        console.error("Error:", error);
      });
  }, [
    editGroupUserID,
    editGroupEventID,
    editGroupID,
    editUsername,
    editPasswordUsername,
  ]);

  return (
    <>
      {editGroupID && (
        <EditGroupModal
          setGlobalError={props.setGlobalError}
          editGroupID={editGroupID}
          setEditGroupID={setEditGroupID}
          userGroupList={userGroupList}
          user={props.user}
        />
      )}
      {editGroupUserID && (
        <GroupUserModal
          setGlobalError={props.setGlobalError}
          setEditGroupUserID={setEditGroupUserID}
          user={props.user}
          userGroupList={userGroupList}
          editGroupUserID={editGroupUserID}
        />
      )}
      {editGroupEventID && (
        <EventsModal
          setGlobalError={props.setGlobalError}
          setEditGroupEventID={setEditGroupEventID}
          user={props.user}
          userGroupList={userGroupList}
          editGroupEventID={editGroupEventID}
        />
      )}

      {editUsername && (
        <EditUser
          setGlobalError={props.setGlobalError}
          editUsername={editUsername}
          setEditUsername={setEditUsername}
          user={props.user}
          userList={userList}
        />
      )}

      {editPasswordUsername && (
        <ChangeAccessCodeModal
          setGlobalError={props.setGlobalError}
          editPasswordUsername={editPasswordUsername}
          setEditPasswordUsername={setEditPasswordUsername}
          user={props.user}
          userList={userList}
        />
      )}

      <Container>
        <Row>
          <Col>
            <h2>User Management</h2>
            <p style={{ textAlign: "left" }}>
              <p>
                User management in this system is composed of two sections.
                Groups and Users.
              </p>
              <p>
                Groups are given access to specific shows. <br /> By default,
                every organization has an 'Administrator' group. This group
                cannot be modified.
                <br />
                Groups can also be added by the user and given access to
                individual shows. <br />
                This is useful in the scenario where an organization has a large
                number of events and the central show manager delegates shows to
                regional locations (i.e. east coast group manages all events on
                the east coast). <br />
                Many organizations will not need to worry about setting up
                groups.
              </p>

              <p>
                Users are assigned to specific groups. By default, all users are
                assigned to the 'Administrator' group when created.
                <br />
                To assign a new user to different group, after creation, click
                'Edit User' button and select the new group(s).
                <br />
                Access codes rather than passwords are used to control access to
                the system. This is to facilitate the fast creation of onsite
                users.
              </p>
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <Accordion>
              <Card>
                <Card.Header style={{ textAlign: "left" }}>
                  <ContextAwareToggle eventKey="0">
                    Manage Users
                  </ContextAwareToggle>
                </Card.Header>

                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <Button
                      variant="primary"
                      bsPrefix="btn-omni"
                      onClick={(e) => {
                        e.preventDefault();
                        setEditUsername("oqiroqofjeoqfejio");
                      }}
                      block
                    >
                      Add a user
                    </Button>
                    <ReactDataGrid
                      emptyRowsRenderer={EmptyUserRowsRenderer}
                      columns={userGridColumns}
                      rows={userList}
                      rowHeight={50}
                      minHeight={600}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header style={{ textAlign: "left" }}>
                  <ContextAwareToggle eventKey="1">
                    Manage Groups
                  </ContextAwareToggle>
                </Card.Header>

                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    {" "}
                    <Button
                      variant="primary"
                      bsPrefix="btn-omni"
                      onClick={(e) => {
                        e.preventDefault();
                        setEditGroupID("oqiroqofjeoqfejio");
                      }}
                      block
                    >
                      Add a New Group
                    </Button>
                    <ReactDataGrid
                      emptyRowsRenderer={EmptyGroupRowsRenderer}
                      columns={columns}
                      rows={userGroupList}
                      rowHeight={50}
                      minHeight={600}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Users;
