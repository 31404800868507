import React, { useState, useRef, useEffect } from "react";

import ReactQuill, { Quill, Mixin, Toolbar } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./emailTemplates.css";
import { Row, Col, Form, Button } from "react-bootstrap";

function EmailCertificateTemplates(props) {
  const [editorText, setEditorText] = useState("");
  const [displayText, setDisplayText] = useState("");
  const [emailSubject, setEmailSubject] = useState("");

  useEffect(() => {
    fetch(global.config.settings.wsURL + "admin/GetCertificateEmail", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (data.body) {
            setEditorText(data.body);
            setDisplayText(data.body);
          }
          if (data.subject) setEmailSubject(data.subject);
        }
      })
      .catch((error) => {
        props.setGlobalError(
          "There was a problem accessing the Internet. Please check your connection and try again."
        );

        console.error("Error:", error);
      });
  }, []);
  function saveEmail(e) {
    e.preventDefault();
    let thisEmail = {
      customerOrganizationID: props.customerID,
      showCode: props.showCode,
      iD: "",
      subject: emailSubject,
      body: displayText,
    };
    fetch(global.config.settings.wsURL + "admin/SaveCertificateEmail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },
      body: JSON.stringify(thisEmail),
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (data.status != "success") {
            alert(data.message);
          } else {
            alert(data.message);
          }
        }
      })
      .catch((error) => {
        props.setGlobalError(
          "There was a problem accessing the Internet. Please check your connection and try again."
        );

        console.error("Error:", error);
      });
  }
  const Size = ReactQuill.Quill.import("attributors/style/size");

  ReactQuill.Quill.register(Size, true);
  // configure Quill to use inline styles so the email's format properly
  var DirectionAttribute = Quill.import("attributors/attribute/direction");
  Quill.register(DirectionAttribute, true);

  var AlignClass = Quill.import("attributors/class/align");
  Quill.register(AlignClass, true);

  var BackgroundClass = Quill.import("attributors/class/background");
  Quill.register(BackgroundClass, true);

  var ColorClass = Quill.import("attributors/class/color");
  Quill.register(ColorClass, true);

  var DirectionClass = Quill.import("attributors/class/direction");
  Quill.register(DirectionClass, true);

  var FontClass = Quill.import("attributors/class/font");
  Quill.register(FontClass, true);

  var SizeClass = Quill.import("attributors/class/size");
  Quill.register(SizeClass, true);

  var AlignStyle = Quill.import("attributors/style/align");
  Quill.register(AlignStyle, true);

  var BackgroundStyle = Quill.import("attributors/style/background");
  Quill.register(BackgroundStyle, true);

  var ColorStyle = Quill.import("attributors/style/color");
  Quill.register(ColorStyle, true);

  var DirectionStyle = Quill.import("attributors/style/direction");
  Quill.register(DirectionStyle, true);

  var FontStyle = Quill.import("attributors/style/font");
  Quill.register(FontStyle, true);

  const Font = ReactQuill.Quill.import("formats/font"); // <<<< ReactQuill exports it
  Font.whitelist = ["mirza", "roboto"]; // allow ONLY these fonts and the default
  ReactQuill.Quill.register(Font, true);

  var SizeStyle = Quill.import("attributors/style/size");
  SizeStyle.whitelist = [
    "9px",
    "10px",
    "11px",
    "12px",
    "14px",
    "16px",
    "18px",
    "20px",
    "22px",
    "24px",
    "26px",
    "28px",
  ];
  Quill.register(SizeStyle, true);

  const quillRef = useRef();
  useEffect(() => {
    console.log(quillRef);
  }, []);

  const quillModules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: Font.whitelist }],
      [{ size: SizeStyle.whitelist }],

      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  const quillFormatters = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "indent",
    "link",
  ];

  return (
    <>
      <Row>
        <Col xs={12} md={12}>
          <br />
          <h2>Certificate Email Template Instructions</h2>
          Below is the certificate email template.
          <br /> Valid tokens for the pdf template are: &lt;--FirstName--&gt;,
          &lt;--LastName--&gt; &lt;--CECredits--&gt; in the body.
          <br />
          Be sure click the Save Email button when finished editing. A preview
          is shown on the right side of the screen.
          <br />
          <br />
        </Col>
      </Row>
      <Row key={"subject"}>
        <Col xs={12} md={6}>
          {" "}
          <Form.Group controlId={"subject"}>
            <Form.Label>{"Subject Line"}</Form.Label>
            <Form.Control
              onChange={(e) => {
                setEmailSubject(e.target.value);
              }}
              value={emailSubject}
              type="Text"
            />
          </Form.Group>
        </Col>

        <Col xs={12} style={{ textAlign: "center", paddingTop: "25px" }} md={6}>
          <Button
            bsPrefix="btn-omni"
            style={{ margin: "0", width: "70%" }}
            onClick={(e) => saveEmail(e)}
          >
            Save Email
          </Button>
        </Col>
      </Row>
      <Row style={{ height: "50vh", overflow: "none", border: "50px" }}>
        <Col xs={12} md={6}>
          <ReactQuill
            ref={quillRef}
            theme={"snow"}
            onChange={(e) => {
              setEditorText(e);
              var find = "<p><br></p>";
              var re = new RegExp(find, "g");
              var txt = e.replace(re, "<br>");

              find = "<p>";
              re = new RegExp(find, "g");
              txt = txt.replace(re, "");

              find = "</p>";
              var re = new RegExp(find, "g");
              txt = txt.replace(re, "<br>");

              setDisplayText(txt);
              console.log(e);

              console.log(txt);
            }}
            modules={quillModules}
            formats={quillFormatters}
            value={editorText}
            placeholder={"Email Body Goes Here..."}
            style={{ height: "100%" }}
          />
        </Col>
        <Col xs={12} md={6}>
          <table width="100%" align="center">
            <tr>
              <td>
                <table
                  align="center"
                  border="5"
                  cellpadding="0"
                  cellspacing="0"
                  width="600px"
                >
                  <tbody>
                    <tr>
                      <td>
                        <table
                          align="center"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                        >
                          <tbody>
                            <tr>
                              <td align="center">
                                <img
                                  src={
                                    global.config.settings.wsURL +
                                    "public/GetImage/" +
                                    props.customerID +
                                    "/" +
                                    props.showCode +
                                    "/emaillogo.png"
                                  }
                                  alt="Logo"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>&nbsp;</td>
                            </tr>
                          </tbody>
                        </table>

                        <table align="center" width="95%">
                          <tbody>
                            <tr>
                              <td align="left"></td>
                            </tr>
                          </tbody>
                        </table>
                        <table align="center" width="95%">
                          <tbody>
                            <tr>
                              <td align="left">
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: displayText,
                                  }}
                                ></p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </table>
        </Col>
      </Row>
    </>
  );
}

export default React.memo(EmailCertificateTemplates);
