import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./reports.module.css";
import { Doughnut } from "react-chartjs-2";
import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
} from "chart.js";

import Select from "react-select";
import ReactDataGrid, { SelectColumn } from "react-data-grid";
import moment from "moment";
ChartJS.register(ArcElement, CategoryScale, LinearScale);
function Attendance(props) {
  const [open, setOpen] = useState(false);
  const [dateOptions, setDateOptions] = useState([]);
  const [selectedDateOptions, setSelectedDateOptions] = useState([]);
  const [dailyCheckins, setDailyCheckins] = useState({ labels: [], data: [] });
  const [filteredAttendeeList, setFilteredAttendeeList] = useState([]);
  const [selectedRows, setSelectedRows] = useState(new Set(React.Key));

  const dateSelectRef = useRef();
  function rowKeyGetter(row) {
    return row.badgeNumber;
  }
  const EmptyRowsRenderer = () => {
    const message = "No attendees to show";

    return (
      <div style={{ textAlign: "center" }}>
        <span lang="en" title="no data">
          No matching attendees
        </span>
      </div>
    );
  };
  useEffect(() => {
    let newDays = [];

    dailyCheckins.labels.map((daily) => {
      newDays.push({
        value: daily,
        label: daily,
      });
    });

    setDateOptions(newDays);
    if (selectedDateOptions.length === 0) {
      setSelectedDateOptions(newDays);
    }
  }, [dailyCheckins]);
  function onChange(value, { action, removedValue }) {
    switch (action) {
      case "remove-value":
        var options = [];
        setSelectedDateOptions(value);
      case "pop-value":
        if (removedValue.isFixed) {
          return;
        }
        break;
      case "select-option":
        setSelectedDateOptions(value);
      case "clear":
        //console.log('inside of clear')
        //setSelectedDateOptions([]);
        //value = colourOptions.filter(v => v.isFixed);
        break;
    }
    let sortValues = [];
    //value.map((val) => sortValues.push(val.value));
  }

  var template = {
    labels: dailyCheckins.labels,
    datasets: [
      {
        label: "My First dataset",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: dailyCheckins.data,
        backgroundColor: [
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(255, 99, 132, 0.2)",

          "rgba(255, 138, 71, 0.5)",
          "rgba(81, 138, 71, 0.5)",
          "rgba(81, 138, 190, 0.5)",
          "rgba(20, 138, 21, 0.6)",
          "rgba(20, 138, 21, 0.3)",
          "rgba(255, 0, 0, 0.6)",
          "rgba(0, 0, 0, 0.6)",
          "rgba(0, 192, 0, 0.6)",
          "rgba(0, 192, 154, 0.6)",
          "rgba(0, 0, 231, 0.7)",
          "rgba(178, 0, 0, 0.7)",
          "rgba(178, 0, 149, 0.5)",
          "rgba(130, 0, 149, 0.5)",
          "rgba(130, 137, 149, 0.5)",
          "rgba(130, 137, 0, 0.5)",
          "rgba(13, 137, 0, 0.5)",
          "rgba(141, 11, 0, 0.5)",
          "rgba(141, 11, 204, 0.5)",
          "rgba(141, 11, 0, 0.5)",
          "rgba(141, 11, 0, 1)",
        ],
        borderColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(255, 99, 132, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const [checkInByDays, setCheckInByDays] = useState(template);
  const defaultColumnProperties = {
    height: 50,

    resizable: true,
  };

  const dateFormatter = (rowData) => {
    let verDate = Date.parse(rowData.row.verifiedDate);

    if (verDate) {
      return moment(rowData.row.verifiedDate).format("MM-DD-YYYY h:mm:ss a");
    } else {
      return <></>;
    }
  };

  const columns = [
    {
      key: "badgeNumber",
      name: "ID",
      width: 160,
      frozen: true,
    },
    { key: "firstName", name: "First Name" },
    { key: "lastName", name: "Last Name" },
    { key: "verifiedDate", name: "Date", formatter: dateFormatter, width: 170 },
    { key: "companyName", name: "Company" },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  function getReport() {
    fetch(global.config.settings.wsURL + "admin/GetDailyCheckinSummary", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          var labels = [];
          var values = [];
          data.map((item) => {
            labels.push(item.date);
            values.push(item.total);
          });
          setDailyCheckins({
            labels: labels,
            data: values,
          });
        }
      })
      .catch((error) => {
        props.setGlobalError(
          "There was a problem accessing the Internet. Please check your connection and try again."
        );

        console.error("Error:", error);
      });
  }

  useEffect(() => {
    getReport();
  }, []);

  const [clickedDataset, setClickedDataset] = useState("");
  const [clickedElement, setClickedElement] = useState("");
  const [clickedElements, setClickedElements] = useState("");
  const getDatasetAtEvent = (dataset) => {
    if (!dataset.length) return;
    //const datasetIndex = dataset[0].datasetIndex;
    //setClickedDataset(template.datasets[datasetIndex].label);
  };

  const getElementAtEvent = (element) => {
    if (!element.length) return;

    const { _datasetIndex, _index } = element[0];
    setClickedDataset(_datasetIndex);

    setSelectedDateOptions([
      {
        value: dailyCheckins.labels[_index],
        label: dailyCheckins.labels[_index],
      },
    ]);
    setClickedElement(
      `${dailyCheckins.labels[_index]} - ${dailyCheckins.data[_index]}`
    );
  };

  const filterAttendees = () => {
    let list = JSON.parse(JSON.stringify(props.attendeeList));
    let newFilteredList = [];
    selectedDateOptions.map((date) => {
      let selDate = date.label;
      if (selDate === "Not Checked In") {
        selDate = "01-01-0001";
      }

      list.attendees.map((attendee) => {
        let verDate = Date.parse(attendee.verifiedDate);

        let compareDate = moment(verDate).format("MM-DD-YYYY");

        if (compareDate == selDate) {
          newFilteredList.push(attendee);
        }
      });
    });

    setFilteredAttendeeList(newFilteredList);
  };
  useEffect(() => {
    filterAttendees();
  }, [props.attendeeList, selectedDateOptions]);

  const getElementsAtEvent = (elements) => {
    if (!elements.length) return;

    setClickedElements(elements.length);
  };

  return (
    <Container style={{ maxWidth: "100%" }}>
      <Row>
        <Col>
          <Container>
            <Row>
              <Col className={styles.graphHeader}>
                <h3>Check In By Days</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ width: "90%" }}>
                  <Doughnut
                    data={template}
                    width={200}
                    getDatasetAtEvent={getDatasetAtEvent}
                    getElementAtEvent={getElementAtEvent}
                    getElementsAtEvent={getElementsAtEvent}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
        <Col>
          <Container>
            <Row>
              <Col className={styles.graphHeader}>
                <h3>List</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <label htmlFor="DayList">Filter by days</label>
              </Col>
            </Row>
            <Row>
              <Col>
                {dateOptions.length > 0 && (
                  <Select
                    ref={dateSelectRef}
                    defaultValue={[...dateOptions]}
                    id={"DayList"}
                    isMulti
                    onChange={onChange}
                    value={selectedDateOptions}
                    options={dateOptions}
                    clearable={false}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <ReactDataGrid
                  emptyRowsRenderer={EmptyRowsRenderer}
                  columns={columns}
                  rows={filteredAttendeeList}
                  rowHeight={50}
                  minHeight={600}
                  selectedRows={selectedRows}
                  onSelectedRowsChange={setSelectedRows}
                  rowKeyGetter={rowKeyGetter}
                />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Row></Row>
    </Container>
  );
}

export default React.memo(Attendance);
