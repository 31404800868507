import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  ProgressBar,
  Container,
  Row,
  Col,
  Table,
  Modal,
  Card,
} from "react-bootstrap";
import styles from "./reports.module.css";
import { Line, Doughnut } from "react-chartjs-2";
import Downloadable from "./downloadable";
import moment from "moment";

function Summary(props) {
  const [sessions, setSessions] = useState([]);
  const [checkinStats, setCheckinStats] = useState({
    cancelled: 0,
    checkedIn: 0,
    totalAttendees: 0,
    notCheckedIn: 0,
  });

  const sessionsRef = useRef(sessions);

  function GetSessions() {
    fetch(global.config.settings.wsURL + "admin/GetSessions", {
      method: "GET",
      headers: {
        accept: "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          setSessions(data);
        }
      })
      .catch((error) => {
        props.setGlobalError(
          "There was a problem accessing the Internet. Please check your connection and try again."
        );

        console.error("Error:", error);
      });
  }

  function GetCheckInStats() {
    fetch(global.config.settings.wsURL + "admin/AttendeeCheckInCount", {
      method: "GET",
      headers: {
        accept: "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          setCheckinStats(data);
        }
      })
      .catch((error) => {
        props.setGlobalError(
          "There was a problem accessing the Internet. Please check your connection and try again."
        );

        console.error("Error:", error);
      });
  }

  useEffect(() => {
    GetSessions();
    GetCheckInStats();
    var sess = setInterval(GetSessions, 5000);
    var stats = setInterval(GetCheckInStats, 5000);
    return function cleanupInterval() {
      clearInterval(stats);
      clearInterval(sess);
    };
  }, []);

  var data = {
    labels: [
      "Checked In (" + checkinStats.checkedIn + ")",
      "Not Checked In (" + checkinStats.notCheckedIn + ")",
      "Cancelled (" + checkinStats.cancelled + ")",
    ],
    datasets: [
      {
        label: "Basic",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [
          checkinStats.checkedIn,
          checkinStats.notCheckedIn,
          checkinStats.cancelled,
        ],
        backgroundColor: [
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(255, 99, 132, 0.2)",
        ],
        borderColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(255, 99, 132, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  return (
    <Container>
      <Row>
        <Col style={{ padding: "20px" }} xm={6}>
          <Container>
            <Row>
              <Col className={styles.graphHeader}>
                <h3>
                  Check-ins ({checkinStats.checkedIn} of{" "}
                  {checkinStats.totalAttendees})
                </h3>
              </Col>
            </Row>
            <Row>
              <Col className={styles.graphHeader}>
                <h5>Cancellations ({checkinStats.cancelled})</h5>
              </Col>
            </Row>
            <Row>
              <Col></Col>
              <Col xs={8}>
                <div style={{ textAlign: "center" }}>
                  <Doughnut data={data} />
                </div>
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Col>
                <Downloadable
                  setGlobalError={props.setGlobalError}
                  user={props.user}
                  showCode={props.showCode}
                />
              </Col>
            </Row>
          </Container>
        </Col>
        <Col style={{ padding: "20px" }} xm={6}>
          <Container>
            <Row>
              <Col className={styles.graphHeader}>
                <h3>Sessions</h3>
              </Col>
            </Row>

            {sessions.map((session) => {
              return (
                <div key={session.code}>
                  <Row>
                    <Col>
                      <hr />
                      <h5>
                        {session.name}
                        <br /> (
                        {moment(session.beginTime).format(
                          "MM/DD/YYYY h:mm:ss a"
                        )}{" "}
                        -{" "}
                        {moment(session.endTime).format("MM/DD/YYYY h:mm:ss a")}
                        )
                      </h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col className={styles.progressHeader}>
                      Signed Up ({session.seatsUsed} of {session.seatsLeft})
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ProgressBar
                        variant="success"
                        min={0}
                        max={session.seatsLeft}
                        now={session.seatsUsed}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className={styles.progressHeader}>
                      Scanned in ({session.scannedIn} of {session.seatsUsed})
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ProgressBar
                        min={0}
                        variant="warning"
                        max={session.seatsLeft}
                        now={session.scannedIn}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className={styles.progressHeader}>
                      Signed up but not checked in to the event (
                      {session.signedUpNotCheckedIn} of {session.seatsUsed})
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ProgressBar
                        min={0}
                        max={session.seatsUsed}
                        now={session.signedUpNotCheckedIn}
                      />
                    </Col>
                  </Row>
                </div>
              );
            })}
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

export default React.memo(Summary);
