import React, { useState, useEffect, useCallback, useMemo } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Styles from "./home.module.css";

import {
  Button,
  Form,
  FormControl,
  Container,
  Row,
  Col,
  Table,
  Modal,
  Card,
  Alert,
} from "react-bootstrap";
import Navigation from "../navigation/navigation";
import AttendeeList from "../attendeeList/attendeeList";
import ModalEdit from "../modalEdit/modalEdit";
import BadgeModal from "../badgeModal/badgeModal";

function Home(props) {
  const [showModal, setShowModal] = useState(false);

  const [editBadgeNumber, setEditBadgeNumber] = useState(null);
  const [fieldList, setFieldList] = useState([]);
  const [fieldValues, setFieldValues] = useState([]);
  const [attendeeSessions, setAttendeeSessions] = useState([]);
  const [itemQuantities, setItemQuantites] = useState([]);
  const [refreshBadges, setRefreshBadges] = useState(null);
  const [refreshAllBadges, setRefreshAllBadges] = useState(false);
  const [badgesToPrint, setBadgesToPrint] = useState([]);
  const [refreshFields, setRefreshFields] = useState(true);
  const [attendeeList, setAttendeeList] = useState({
    attendees: [],
    filteredAttendees: [],
  });
  const [localError, setLocalError] = useState("");
  const [refreshRegistrationTypes, setRefreshRegistrationTypes] =
    useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [registrationTypes, setRegistrationTypes] = useState([]);
  useEffect(() => {
    if (refreshRegistrationTypes) {
      getRegistrationType();
    }
  }, [refreshRegistrationTypes]);
  function getRegistrationType() {
    fetch(global.config.settings.wsURL + "admin/GetRegistrationTypes", {
      method: "GET",
      headers: {
        accept: "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          setRegistrationTypes(data);
          if (refreshRegistrationTypes) {
            setRefreshRegistrationTypes(false);
          }
        }
      })
      .catch((error) => {
        setLocalError(
          "Registration Types - Network exception: Check your network if this message does not go away: " +
            error
        );
        console.error("Error:", error);
      });
  }

  useEffect(() => {
    fetch(global.config.settings.wsURL + "admin/VerifyAdmin", {
      method: "GET",
      headers: {
        accept: "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
        username: props.user.username,
      },
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);
          console.log(response);
          return null;
        }

        return response.json();
      })
      .then((data) => {
        if (data) {
          setIsAdmin(data.isAdmin);
        }
      })
      .catch((error) => {
        props.setGlobalError(
          "There was a problem accessing the Internet. Please check your connection and try again."
        );

        console.error("Error:", error);
      });
  }, []);

  const getData = useCallback((e, badgeID) => {
    e.preventDefault();
    //setRefreshFields(true);

    if (badgeID) {
      fetch(global.config.settings.wsURL + "admin/GetAttendeesFields", {
        method: "GET",
        headers: {
          accept: "application/json",
          authorization: props.user.token,
          showCode: props.showCode,
          badgeNumber: badgeID,
        },
      })
        .then(function (response) {
          if (!response.ok) {
            console.log("Status " + response.status);

            return null;
          }
          return response.json();
        })
        .then((data) => {
          if (data) {
            let items = [];

            data.map((item) => {
              items[item.key] = item.value;
            });

            fieldList.map((field) => {
              if (!items[field.code]) {
                items[field.code] = "";
              }
            });

            setFieldValues(items);
            setEditBadgeNumber(badgeID);
            setShowModal(true);
            console.log(data);
          }
        })
        .catch((error) => {
          props.setGlobalError(
            "There was a problem accessing the Internet. Please check your connection and try again."
          );

          console.error("Error:", error);
        });

      fetch(global.config.settings.wsURL + "admin/GetAttendeeSessions", {
        method: "GET",
        headers: {
          accept: "application/json",
          authorization: props.user.token,
          showCode: props.showCode,
          badgeNumber: badgeID,
        },
      })
        .then(function (response) {
          if (!response.ok) {
            console.log("Status " + response.status);

            return null;
          }
          return response.json();
        })
        .then((data) => {
          if (data) {
            //setFieldValues(data);
            let items = [];
            let quantities = [];
            data.map((sess) => {
              items[sess.sessionCode] = sess.isAttending;
              quantities[sess.sessionCode] = sess.qty;
            });
            console.log(data);
            setAttendeeSessions(items);
            setItemQuantites(quantities);
          }
        })
        .catch((error) => {
          props.setGlobalError(
            "Error Getting Sessions - Network exception: Check your network if this message does not go away: " +
              error
          );

          console.error("Error:", error);
        });
    } else {
      setEditBadgeNumber(null);
      setShowModal(true);
    }
  }, []);

  useEffect(() => {
    if (refreshFields) {
      getFields();
      setRefreshFields(false);
    }
  }, [refreshFields]);

  function getFields() {
    //Get fields to display
    fetch(global.config.settings.wsURL + "admin/GetDisplayFields", {
      method: "GET",
      headers: {
        accept: "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          setFieldList(data);
        }
      })
      .catch((error) => {
        setLocalError(
          "Field List - Network exception: Check your network if this message does not go away: " +
            error
        );

        console.error("Error:", error);
      });
  }
  useEffect(() => {
    var getf = setInterval(getFields, 30000);
    var getrType = setInterval(getRegistrationType, 60000);
    return function cleanupInterval() {
      clearInterval(getf);
      clearInterval(getrType);
    };
  }, []);
  return (
    <>
      <Navigation
        setGlobalError={props.setGlobalError}
        setLocalError={setLocalError}
        showName={props.showName}
        setRefreshRegistrationTypes={setRefreshRegistrationTypes}
        isAdmin={isAdmin}
        setUser={props.setUser}
        attendeeList={attendeeList}
        setRefreshFields={setRefreshFields}
        showCode={props.showCode}
        user={props.user}
      />
      {localError && <Alert variant={"danger"}>Error: {localError}</Alert>}
      <AttendeeList
        isAdmin={isAdmin}
        fieldList={fieldList}
        setGlobalError={props.setGlobalError}
        registrationTypes={registrationTypes}
        setAttendeeList={setAttendeeList}
        refreshAllBadges={refreshAllBadges}
        setRefreshAllBadges={setRefreshAllBadges}
        setBadgesToPrint={setBadgesToPrint}
        showCode={props.showCode}
        refreshBadges={refreshBadges}
        getData={getData}
        user={props.user}
        setLocalError={setLocalError}
      />

      {showModal && (
        <ModalEdit
          setGlobalError={props.setGlobalError}
          setEditBadgeNumber={setEditBadgeNumber}
          attendeeSessions={attendeeSessions}
          setRefreshBadges={setRefreshBadges}
          showCode={props.showCode}
          itemQuantities={itemQuantities}
          user={props.user}
          fieldValues={fieldValues}
          showModal={showModal}
          setBadgesToPrint={setBadgesToPrint}
          setShowModal={setShowModal}
          fieldList={fieldList}
          editBadgeNumber={editBadgeNumber}
        />
      )}
      {badgesToPrint.length > 0 && (
        <BadgeModal
          setGlobalError={props.setGlobalError}
          setRefreshAllBadges={setRefreshAllBadges}
          user={props.user}
          showCode={props.showCode}
          setBadgesToPrint={setBadgesToPrint}
          badgesToPrint={badgesToPrint}
        />
      )}
    </>
  );
}
export default React.memo(Home);
