import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import {  Button,   Container, Row, Col, Table,   Form } from 'react-bootstrap'; 


function CustomFieldSetup(props){



    const [displayFields, setDisplayFields] = useState([])
    const [newCode, setNewCode] = useState();
    const [newFieldLabel, setNewFieldLabel] = useState('');
    const [newFieldDropDown, setNewFieldDropDown] = useState(false);
    const displayFieldsRef = useRef(displayFields);

    const [newAnswers, setNewAnswers] = useState([]);
    const [newComboFieldCode, setNewComboFieldCode] = useState(null);
    const [newComboItemCode, setNewComboItemCode] = useState(null);
    const [newComboItemText, setNewComboItemText] = useState(null);

 
    function deleteComboField(e, fieldCode, comboValueCode){
        e.preventDefault(); 
        let newDisplayFields = [];
        var res = window.confirm("This will remove any attendee answers for this combo field.  Continue? ");
        if (res == false) {
            return;
        }
        displayFields.map((f) =>
            {
                if (f.code!=fieldCode) {
                    newDisplayFields.push(f);

                } else {
                
                    let copiedItem = {
                        customFieldType: f.customFieldType,
                        customerOrganizationID: f.customerOrganizationID,
                        description: f.description,
                        editDisplayMask: f.editDisplayMask,
                        maxLength: f.maxLength,
                        prepopulateText: f.prepopulateText,
                        regType: f.regType,
                        required: f.required,
                        showCode: f.showCode,
                        title: f.title,
                        comboValues: []
                    }
                    f.comboValues.map((comboValue) => {
                        if (comboValue.ansCode != comboValueCode) {
                            copiedItem.comboValues.push(JSON.parse(JSON.stringify(comboValue)));
                        }
                    });
                    newDisplayFields.push(copiedItem);
                }
            }
        )
        setDisplayFields(newDisplayFields);
        displayFieldsRef.current = newDisplayFields;

     
        fetch(global.config.settings.wsURL + 'admin/DeleteCustomFieldComboValue',
        {
            method: "DELETE",
            headers: { 
                'Content-Type': 'application/json',
                authorization: props.user.token,
                showCode: props.showCode, 
                fieldCode: fieldCode,
                comboCode: comboValueCode
            }
             
        }
        )
        .then(function (response) {
            if (!response.ok) {
                console.log("Status " + response.status);

                return null;
            }
            return response.json();
        })
        .then(data => { 
            if (data) {
                alert(data.message); 
            }
        })

       
    }   
    function deleteField(e, fieldCode){
        e.preventDefault(); 
        let newDisplayFields = [];
        var res = window.confirm("This will remove any attendee answers for this field.  Continue? ");
        if (res == false) {
            return;
        }
        displayFields.map((f) =>
            {
                if (f.code!=fieldCode) {
                    newDisplayFields.push(f);

                }
            }
        )
        setDisplayFields(newDisplayFields);
        displayFieldsRef.current = newDisplayFields;

     
        fetch(global.config.settings.wsURL + 'admin/DeleteCustomField',
        {
            method: "DELETE",
            headers: { 
                'Content-Type': 'application/json',
                authorization: props.user.token,
                showCode: props.showCode, 
                fieldCode: fieldCode
            }
             
        }
        )
        .then(function (response) {
            if (!response.ok) {
                console.log("Status " + response.status);

                return null;
            }
            return response.json();
        })
        .then(data => { 
            if (data) {
                alert(data.message); 
            }
        })
    }
    function handleAnswerSave(e, fieldCode){
        let currentAnswers = [];
        let ans = {
            answerCode: newComboItemCode,
            answerText: newComboItemText,
            fieldCode: newComboFieldCode
        }

 
            if (ans.answerCode=='') {return;}
            if (ans.fieldCode == fieldCode) {
                    fetch(global.config.settings.wsURL + 'admin/SaveCustomFieldsComboAnswer',
                    {
                        method: "POST",
                        headers: { 
                            'Content-Type': 'application/json',
                            authorization: props.user.token,
                            showCode: props.showCode, 
                            fieldCode: fieldCode,
                            answerCode: ans.answerCode,
                            answerText: ans.answerText
                        },
                       
                    }
                )
                .then(function (response) {
                    if (!response.ok) {
                        console.log("Status " + response.status);
        
                        return null;
                    }
                    return response.json();
                })
                .then(data => { 
                    if (data) {
                        alert(data.message); 
                        props.setRefreshFields(true);
                    }
                })

                let newAns = JSON.parse(JSON.stringify(ans));
                newAns.answerText = "";
                newAns.answerCode = "";
                currentAnswers.push(newAns);
                let fields = JSON.parse(JSON.stringify(displayFields));
                fields.map((field) => {
                    if (field.code == fieldCode) {
                        if (!field.comboValues) {field.comboValues = [];}
                        let alreadyExists = false;
                        field.comboValues.map((comboField) => {
                             if (comboField.ansCode.toLowerCase()===ans.answerCode.toLowerCase()) {
                                 comboField.answerText = ans.answerText;
                                 alreadyExists = true;
                             }
                        });
                        if (alreadyExists == false) {
                            field.comboValues.push({
                                ansCode: ans.answerCode,
                                answerText: ans.answerText 
                            })
                        }
                    }
                })
                setDisplayFields(fields);
            } else {
                currentAnswers.push(JSON.parse(JSON.stringify(ans)));
            }
 
            setNewComboFieldCode(null);
            setNewComboItemCode(null);
            setNewComboItemText(null);
        setNewAnswers(currentAnswers);
    }
    function handleSave(e, fieldCode) { 
    
        e.preventDefault(); 
        let displayField;
        displayFieldsRef.current.map((f) =>
            {
                if (f.code==fieldCode) {
                    displayField=f;

                }
            }
        )
     
        fetch(global.config.settings.wsURL + 'admin/UpdateCustomField',
        {
            method: "POST",
            headers: { 
                'Content-Type': 'application/json',
                authorization: props.user.token,
                showCode: props.showCode, 
            },
            body: JSON.stringify(displayField)
        }
    )
        .then(function (response) {
            if (!response.ok) {
                console.log("Status " + response.status);

                return null;
            }
            return response.json();
        })
        .then(data => { 
            if (data) {
                alert(data.message); 
                props.setRefreshFields(true);
            }
        })
    }
    
    useEffect(() => {
        
    }, [newComboFieldCode]);
    
    useEffect(() => {
        fetch(global.config.settings.wsURL + 'admin/GetCustomFields',
        {
            method: "GET",
            headers: {
                accept: "application/json",
                authorization: props.user.token,
                showCode: props.showCode,
                
            }
        }
        )
        .then(function (response) {
            if (!response.ok) {
                console.log("Status " + response.status);
    
                return null;
            }
            return response.json();
        })
        .then(data => {
            if (data) {  
                 setDisplayFields(data);
                  console.log(data);
            }
        }) 
    }, []);
    return (
<>
       <h5>New field</h5>
       <Table striped bordered hover>
            <thead>
            <tr>
                 
                <th>Field code</th>
                <th>Field label</th>
                <th></th>
            </tr>
            <tr>
                 
                <td>
                    <Form.Group as={Col}     controlId="newFieldCode"> 
                        <Form.Control value={newCode} onChange={(e)=> setNewCode(e.target.value)} />
                        <Form.Text  muted>
                                This is the field's unique ID.  It must be between 5 and 20 characters, and contain only letters and numbers (no spaces).
                        </Form.Text>
                </Form.Group>
              </td>
              <td>
                  <Form.Group as={Col} controlId="newFieldLabel">
                    <Form.Control value={newFieldLabel} onChange={(e)=> setNewFieldLabel(e.target.value)} />
                    <Form.Text  muted>
                        This is the label for this field that will be displayed when importing, exporting and modifying attendee data.
                    </Form.Text>
                 </Form.Group></td>
                 <td>
                  <Form.Group as={Col} controlId="newFieldDropDown">
                    <Form.Check value={newFieldDropDown} label={"Use Dropdown"} onChange={(e)=> setNewFieldDropDown(e.target.checked)} />
                    <Form.Text  muted>
                        If checked, this will cause the field to be a dropdown box. Dropdown values may be added once this field is saved.
                    </Form.Text>
                 </Form.Group></td>

                <td><Button block onClick={(e) => {
                    let fieldType = 0;
                    if (newFieldDropDown) {fieldType=3}
                    let newField={
                        code: newCode + '-CUSTOM',
                        customFieldType: fieldType,
                        description: newFieldLabel, 
                        editDisplayMask: null,
                        maxLength: 250,
                        prepopulateText: null,
                        regType: null,
                        required: 1, 
                    }
                    let fields = JSON.parse(JSON.stringify(displayFields));
                    fields.push(newField);
                    
                    setDisplayFields(fields);
                    
                displayFieldsRef.current=fields;
              

                    handleSave(e, newField.code);
                }}
                             
                            >Add this field</Button></td>
            </tr>            
            </thead>
            <tbody>

            </tbody>
            </Table>
<h5>Current fields</h5>

        <Table striped bordered hover>
            <thead>
            <tr>
                 
                <th>Field code</th>
                <th>Field label</th>
                <th>Type</th>
                <th>Remove</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {displayFields.map((field) => {
                return (
                    <tr key={field.code}>
                        <td>{field.code}</td><td>
                        <Form.Group as={Col} controlId="email">
             
            <Form.Control defaultValue={field.description} 
            onChange={(e) => {
                let fields = JSON.parse(JSON.stringify(displayFields));
                fields.map((f) => {
                    if (f.code==field.code) {
                         
                        f.description = e.target.value;

                    }
                   
                })
                
                setDisplayFields(fields);
                displayFieldsRef.current = fields;
            }
               
                }
            
            
            />
                </Form.Group>
             
                            
                            </td>
                            {field.customFieldType=="0" ? 
                            <td>Text</td>
                         
                          :  
                            <td><h5>Dropdown</h5>
                                <table>
                                    <th>Code</th>
                                    <th>Answer</th>
                                    <th></th>
                                  
                                    {field.comboValues && field.comboValues.map((cd) => {
                                        return (
                                        <tr><td>{cd.ansCode }</td><td>{cd.answerText}</td><td>
                                        <Button block
                            
                                        onClick={(e) => deleteComboField(e, field.code, cd.ansCode)}
                                        >Delete</Button></td>
                                            
                                             </tr> )
                                    })}
                                    {newComboFieldCode === field.code &&
                                    <>
                                    <tr><td><Form.Group  as={Col} controlId="newCode">
                                    <Form.Control  placeholder="New Code" value={newComboItemCode}
                                        onChange={(e) => { 
                                            setNewComboItemCode(e.target.value);
                                            let found = false;
                                            let currentNewAnswers = JSON.parse(JSON.stringify(newAnswers));
                                            currentNewAnswers.map((ans) => {
                                                
                                                if (ans.fieldCode == field.code) {
                                                    found = true;
                                                    ans.fieldCode = field.code;
                                                    ans.answerCode = e.target.value;
                                                    ans.answerText = ans.answerText;
                                                }    
                                            });
                                            if (found == false) {
                                                currentNewAnswers.push(
                                                        {
                                                            "fieldCode": field.code,
                                                            "answerCode": e.target.value,
                                                            "answerText": ""
                                                        }
                                                    )
                                            }
                                            setNewAnswers(currentNewAnswers);
                                             
                                        }
                                        
                                            }
                                        
                                        
                                        />
                                            </Form.Group>
                                                
                                        </td><td><Form.Group as={Col} controlId="newAnswer">
                                    <Form.Control   value={newComboItemText} placeholder="Answer Text"

                                            onChange={(e) => {
                                                setNewComboItemText(e.target.value);
                                                let found = false;
                                                let currentNewAnswers = JSON.parse(JSON.stringify(newAnswers));
                                                currentNewAnswers.map((ans) => {
                                                    
                                                    if (ans.fieldCode == field.code) {
                                                        found = true;
                                                        ans.fieldCode = field.code;
                                                        ans.answerCode = ans.answerCode;
                                                        ans.answerText = e.target.value;
                                                    }   
                                                    
                                                        
                                                });
                                                if (found == false) {
                                                    currentNewAnswers.push(
                                                            {
                                                                "fieldCode": field.code,
                                                                "answerCode": "",
                                                                "answerText": e.target.value
                                                            }
                                                        )
                                                }
                                                setNewAnswers(currentNewAnswers);
                                                
                                            }

                                                }
                                        
                                        />
                                            </Form.Group></td>
                                            <td><Button block
                            
                            onClick={(e) => handleAnswerSave(e, field.code)}
                            >Save</Button></td> 
                                         
                                        
                                        </tr>
                                        <tr><td colSpan={3} ><Button block
                            
                                        onClick={(e) => {
                                            
                                                setNewComboFieldCode(null);
                                                setNewComboItemCode(null);
                                                setNewComboItemText(null);
                                            }
                                        }
                                        >Cancel</Button></td></tr>

                                    </>
                            }

                                    {newComboFieldCode != field.code &&
                                    <> 
                                        <tr><td colSpan={3} ><Button block 
                                            onClick={(e) => setNewComboFieldCode(field.code)}
                                            >New Combo Value</Button></td></tr>
                                    </>
                                    }
                                </table>

                            </td>
                        }
                            
                            <td><Button block
                            
                            onClick={(e) => handleSave(e, field.code)}
                            >Update</Button></td><td><Button block
                            
                            onClick={(e) => deleteField(e, field.code)}
                            >Delete</Button></td>
                    </tr>
                )
            }
            )
            }
        </tbody>
        </Table> 
        </>  
    )
}
export default React.memo(CustomFieldSetup);