import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  Container,
  Row,
  Col,
  Modal,
  Tabs,
  Tab,
} from "react-bootstrap";
import styles from "./certificates.module.css";
import EmailCertificateConfirmations from "./emailCertificateConfirmations";
import EmailCertificateTemplates from "./emailCertificateTemplates";
function Certificates(props) {
  const handleClose = () => props.setShowModal(false);

  const [key, setKey] = useState("emailCertificateTemplates");

  return (
    <Modal
      centered
      contentClassName={styles.modal}
      dialogClassName={styles.modal}
      onHide={handleClose}
      show={props.showModal}
    >
      <Modal.Header closeButton>
        <h3>Emails</h3>
      </Modal.Header>

      <Modal.Body className={styles.modalBodyStyle}>
        <Tabs id="controlled-tab" activeKey={key} onSelect={(k) => setKey(k)}>
          <Tab
            eventKey="emailCertificateTemplates"
            title="Certificate Email Template"
          >
            {key === "emailCertificateTemplates" && (
              <EmailCertificateTemplates
                setGlobalError={props.setGlobalError}
                user={props.user}
                customerID={props.customerID}
                showCode={props.showCode}
              />
            )}
          </Tab>
          <Tab
            eventKey="emailCertificateConfirmations"
            title="Certificate Email Confirmations"
          >
            {key === "emailCertificateConfirmations" && (
              <EmailCertificateConfirmations
                setGlobalError={props.setGlobalError}
                user={props.user}
                customerID={props.customerID}
                showCode={props.showCode}
              />
            )}
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button
          bsPrefix="btn-omni"
          onClick={() => {
            props.setShowModal(false);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default React.memo(Certificates);
