import React, { useState, useEffect, useRef } from "react";
import { Button, Container, Row, Col, Table, Form } from "react-bootstrap";
import Styles from "./showSetup.module.css";

function ShowSetup(props) {
  const [show, setShow] = useState({
    showCode: "",
    customerOrganizationID: "",
    showName: "",
  });

  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [images, setImages] = useState([]);
  const [fileMessage, setFileMessage] = useState("Select an image");
  const [uploadSuccess, setUploadSuccess] = useState("");

  const imagesRef = useRef([]);

  const changeHandler = (event) => {
    const name = event.target.files[0].name.toLowerCase();
    if (
      name.includes(".gif") === false &&
      name.includes(".jpg") === false &&
      name.includes(".jpeg") === false &&
      name.includes(".png") === false &&
      name.includes(".svg") === false &&
      name.includes(".tif") === false
    ) {
      setSelectedFile(null);
      setFileMessage(null);
      setIsFilePicked(false);

      alert("Invalid file type. Only images may be uploaded.");
      return;
    }
    setSelectedFile(event.target.files[0]);
    setFileMessage(event.target.files[0].name);
    setIsFilePicked(true);
  };

  const submitHandler = (e) => {
    e.preventDefault(); //prevent the form from submitting

    const formData = new FormData();

    formData.append("File", selectedFile);

    fetch(global.config.settings.wsURL + "admin/UploadImage", {
      method: "POST",
      headers: {
        authorization: props.user.token,
        showCode: props.showCode,
      },
      body: formData,
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          initializeImages();
          alert(data.message);
          if (data.status === "success") {
            setUploadSuccess("Image uploaded");
          }
          setFileMessage("Select an image");
          setSelectedFile(null);
          setFileMessage(null);
          setIsFilePicked(false);
        }
      });
  };

  function deleteImage(e, name) {
    e.preventDefault();
    fetch(global.config.settings.wsURL + "admin/DeleteImage", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
        name: name,
      },
      body: JSON.stringify(show),
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          initializeImages();

          alert(data.message);
        }
      });
  }

  function handleSave(e) {
    e.preventDefault();
    console.log(show);
    fetch(global.config.settings.wsURL + "admin/UpdateShow", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },

      body: JSON.stringify(show),
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);
          alert(response.message);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          alert(data.message);
        }
      });
  }
  useEffect(() => {
    fetch(global.config.settings.wsURL + "admin/GetShow", {
      method: "GET",
      headers: {
        accept: "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          setShow(data);
        }
      });
  }, []);

  useEffect(() => {
    initializeImages();
  }, []);

  async function initializeImages() {
    const response = await fetch(
      global.config.settings.wsURL + "admin/GetImages",
      {
        method: "GET",
        headers: {
          accept: "application/json",
          authorization: props.user.token,
          showCode: props.showCode,
        },
      }
    );

    const data = await response.json();

    const promises = await Promise.all(
      data.map(async (img) => {
        const imageBlob = await fetch(
          global.config.settings.wsURL + "admin/GetImage",
          {
            method: "GET",
            headers: {
              accept: "*/*",
              authorization: props.user.token,
              showCode: props.showCode,
              name: img.name,
            },
          }
        );
        const blob = await imageBlob.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        img.url = url;
      })
    );
    setImages(data);
  }

  return (
    <Container>
      <Row>
        <Col>
          <Container>
            <Row>
              <Col>
                <Form.Group controlId="eventCode">
                  <Form.Label>Event Code</Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    placeHolder={props.showCode}
                  />
                  <Form.Text className="text-muted">
                    Unique identifier for this event
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="eventName">
                  <Form.Label>Event Name</Form.Label>
                  <Form.Control
                    value={show.showName}
                    onChange={(e) => {
                      let tempShow = JSON.parse(JSON.stringify(show));
                      tempShow.showName = e.target.value;
                      setShow(tempShow);
                    }}
                    type="text"
                  />
                  <Form.Text className="text-muted">
                    Name that is displayed for this event.
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="isHidden">
                  <Form.Label>Hide Show?</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      let tempShow = JSON.parse(JSON.stringify(show));
                      let val = e.target.value === "true" ? true : false;
                      tempShow.isTestShow = val;
                      console.log(e.target.value);
                      setShow(tempShow);
                    }}
                    value={show.isTestShow}
                    as="select"
                  >
                    <option label={"Yes"}>true</option>
                    <option label={"No"}>false</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button onClick={(e) => handleSave(e)} block>
                  Save
                </Button>
              </Col>
            </Row>
          </Container>
        </Col>
        <Col>
          <Container>
            <Row>
              <Col>
                <h5>Images and Logos</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form>
                  <Form.Control type="file" onChange={changeHandler} />
                  <Form.Label data-browse="Browse">{fileMessage}</Form.Label>
                  <Form.Control.Feedback type="valid">
                    {uploadSuccess}
                  </Form.Control.Feedback>

                  <Form.Group>
                    {selectedFile && (
                      <>
                        <Button
                          variant="info"
                          style={{
                            marginTop: "5px",
                            marginBottom: "5px",
                          }}
                          onClick={(e) => submitHandler(e)}
                        >
                          Upload
                        </Button>
                        <p>File name: {selectedFile.name}</p>
                        <p>File type: {selectedFile.type}</p>
                        <p>Size in bytes: {selectedFile.size}</p>
                      </>
                    )}
                  </Form.Group>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table>
                  <thead>
                    <td></td>
                    <td>name</td>
                    <td></td>
                  </thead>
                  <tbody>
                    {images.map((image) => {
                      return (
                        <tr key={image.name}>
                          <td>
                            <img className={Styles.gridImage} src={image.url} />
                          </td>
                          <td>{image.name}</td>
                          <td>
                            <Button onClick={(e) => deleteImage(e, image.name)}>
                              Delete
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Container>
        </Col>{" "}
      </Row>
    </Container>
  );
}

export default React.memo(ShowSetup);
