import React, {useEffect, useState} from 'react';
import {  Button, Form, Container, Col, Row,  Alert, Modal } from 'react-bootstrap';
import Styles from './deleteModal.module.css';
import ReactDataGrid, {SelectColumn } from 'react-data-grid';

function DeleteModal(props) {
const handleDelete = (e) => {
    e.preventDefault();
    console.log(props.attendeesToDelete);
    console.log('here');
    fetch(global.config.settings.wsURL + 'admin/DeleteAttendees',
    {
        method: "POST",
        headers: { 
            'Content-Type': 'application/json',
            authorization: props.user.token,
            showCode: props.showCode, 
        },
        body: JSON.stringify(props.attendeesToDelete)
    }
)
    .then(function (response) {
        if (!response.ok) {
            console.log("Status " + response.status);

            return null;
        }
        return response.json();
    })
    .then(data => { 
        if (data) {
             
            props.setAttendeesToDelete([]); 
            props.getAttendees();
        }
    }).catch(error => {
        props.setGlobalError("There was a problem accessing the Internet. Please check your connection and try again.");
      
        console.error('Error:', error);
      });
      
    //props.setRefreshAllBadges(true);
 
}
const handleClose = () => { 
    props.setAttendeesToDelete([]); 
};
const defaultColumnProperties = {
    height: 50,
    
    resizable: true,
  };
 
const columns = [
 
{
key: 'badgeNumber',
name: 'ID',
width: 160, 
frozen: true,  
}, 
{ key: 'firstName', name: 'First Name', },
  { key: 'lastName', name: 'Last Name', },
  { key: 'companyName', name: 'Company',  },
 
].map((c) => ({ ...c, ...defaultColumnProperties }));
 
const EmptyRowsRenderer = () => {
  const message = "No attendees to show";

  return (
      <div style={{ textAlign: 'center' }}>Nothing to show <span lang="en" title="no data">No attendees</span></div>
  );
};

const [errorMsg, setErrMsg] = useState(null);
    return (
        <Modal onHide={handleClose} show={true}> 
        
        <Modal.Header>Confirm deletion </Modal.Header>
    <Modal.Body   >
    <Container>
        <Row><Col><ReactDataGrid
         emptyRowsRenderer={EmptyRowsRenderer}
      columns={columns} 
    
      rows={props.attendeesToDelete}
      rowHeight={50}
      minHeight={600} 
      
    /></Col></Row>
    <Row><Col><Button variant="warning"  style={{width: "100%", marginTop: "5px"}}
                onClick={() => { 
                      
                    props.setAttendeesToDelete([]) }
    
                }
            >Cancel</Button>
            </Col></Row>
            <Row><Col><Button variant style={{width: "100%", marginTop: "35px"}} variant="danger"  onClick={(e) => handleDelete(e)} block>Confirm deletion (cannot be undone)</Button></Col></Row>
            {errorMsg != null &&
            <Row><Col>
                 <Alert key='Alert' style={{width: "100%"}} className={Styles.marginArea} size="md" variant="danger"> {errorMsg} </Alert>         
            </Col></Row>
            }
       </Container>     
        </Modal.Body>
        <Modal.Footer> 
    </Modal.Footer>   
    </Modal>
    )
}

export default React.memo(DeleteModal);