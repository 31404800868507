import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {  Button, ButtonGroup, Container, Row, Col, Modal, Tabs, Tab } from 'react-bootstrap'; 
import styles from './reports.module.css'; 
import Summary from './summary';
import Attendance from './attendance';
import Sessions from './sessions';
import DailyCheckin from './dailyCheckin';
function Reports(props) {
    const handleClose = () => props.setShowModal(false);
    const [currentReport, setCurrentReport] = useState("summary");
    const [key, setKey] = useState('home');
    return (
        <Modal centered contentClassName={styles.modal}  dialogClassName={styles.modal} onHide={handleClose} show={props.showModal}>
                     <Modal.Header closeButton><h3>Reports</h3></Modal.Header>
            
        <Modal.Body className={styles.modalBodyStyle}>
        <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
    >
      <Tab eventKey="home" title="Home">
          {key==="home" &&
            <Summary setGlobalError={props.setGlobalError} user={props.user} showCode={props.showCode} />
            }
      </Tab>
      <Tab eventKey="attendance"   title="Attendance">
      {key==="attendance" &&
          <Attendance setGlobalError={props.setGlobalError} user={props.user} attendeeList={props.attendeeList} showCode={props.showCode} />
          }
      </Tab>
      <Tab eventKey="dailyCheckin"   title="Daily Checkins">
      {key==="dailyCheckin" &&
          <DailyCheckin setGlobalError={props.setGlobalError} user={props.user} attendeeList={props.attendeeList} showCode={props.showCode} />
          }
      </Tab>      
      <Tab eventKey="sessions" title="Sessions" >
      <Sessions setGlobalError={props.setGlobalError} user={props.user} attendeeList={props.attendeeList} showCode={props.showCode} />
      </Tab>
            
    </Tabs>
 
        </Modal.Body>
        <Modal.Footer><Button bsPrefix="btn-omni"
                    onClick={() => {  
                        props.setShowModal(false) 
                        
                    }}
                >Close</Button>
        </Modal.Footer>            
       </Modal>
    )
}

export default React.memo(Reports);