import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Container,
  Col,
  Row,
  Alert,
  Modal,
} from "react-bootstrap";
import Styles from "./customerSettingsModal.module.css";

export default function CustomerSettingsModal(props) {
  const handleClose = () => props.setShowSettingsModal(false);
  const [kioskCode, setKioskCode] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);
  const [showError, setShowError] = useState("");
  const body = {
    iD: props.user.customerOrganizationID,
    organizationName: companyName,
    kioskCode: kioskCode,
    address1: "",
    address2: "",
    city: "",
    state: "",
    province: "",
    postalCode: "",
    country: "",

    shows: [],
  };
  useEffect(() => {
    fetch(global.config.settings.wsURL + "admin/GetCustomer", {
      method: "GET",
      headers: {
        accept: "application/json",
        authorization: props.user.token,
      },
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);
          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          setCompanyName(data.organizationName);
          setKioskCode(data.kioskCode);
        }
      })
      .catch((error) => {
        props.setGlobalError(
          "There was a problem accessing the Internet. Please check your connection and try again."
        );

        console.error("Error:", error);
      });
  }, []);
  function handleSave(e) {
    if (companyName == "" || kioskCode == "") {
      setShowError(true);
      setErrorMsg("Fill out all fields");
      return;
    }
    if (e) {
      e.preventDefault();
    }
    const body = {
      iD: 0,
      organizationName: companyName,
      kioskCode: kioskCode,
      address1: "",
      address2: "",
      city: "",
      state: "",
      province: "",
      postalCode: "",
      country: "",

      shows: [],
    };

    fetch(global.config.settings.wsURL + "Admin/UpdateCustomer", {
      method: "POST",
      headers: {
        accept: "application/json",
        authorization: props.user.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (!response.ok) {
          setErrorMsg(
            "Ooops.  There was an Error.  Check your information and resubmit."
          );
          setShowError(true);
          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data.status === "success") {
          //setErrorMsg(companyName + " was updated ");
          handleClose(null);
        } else {
          setErrorMsg(data.message);
          setShowError(true);
        }
      })
      .catch((error) => {
        errorMsg(
          "There was a problem accessing the Internet. Please check your connection and try again."
        );
        setErrorMsg(true);
        console.error("Error:", error);
      });
  }
  return (
    <Modal onHide={handleClose} show={props.showSettingsModal}>
      <Modal.Header>Event Company Settings</Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col>
              <Container>
                <Row>
                  <Col>
                    <Form.Group controlId="formCompanyName">
                      <Form.Label>Company Name</Form.Label>
                      <Form.Control
                        value={companyName}
                        type="text"
                        onChange={(e) => {
                          setCompanyName(e.target.value);
                        }}
                      />
                      <Form.Text className="text-muted">
                        Name of the event company
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="kioskCode">
                      <Form.Label>Kiosk Code</Form.Label>
                      <Form.Control
                        value={kioskCode}
                        onChange={(e) => {
                          setKioskCode(e.target.value);
                        }}
                        type="text"
                      />
                      <Form.Text className="text-muted">
                        Code that will be used to access events through the
                        scanning kiosk.
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      bsPrefix="btn-omni"
                      style={{ width: "100%" }}
                      onClick={(e) => handleSave(e)}
                      block
                    >
                      Update Information
                    </Button>
                  </Col>
                </Row>
                {errorMsg != null && (
                  <Row>
                    <Col>
                      <Alert
                        key="Alert"
                        style={{ width: "100%" }}
                        className={Styles.marginArea}
                        size="md"
                        variant="danger"
                      >
                        {" "}
                        {errorMsg}{" "}
                      </Alert>
                    </Col>
                  </Row>
                )}
              </Container>
            </Col>{" "}
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        {" "}
        <Button
          bsPrefix="btn-omni"
          onClick={() => {
            props.setShowSettingsModal(false);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
