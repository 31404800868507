import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {  Button, Form, FormControl, Container, Row, Col, Table, Modal, Alert, Card } from 'react-bootstrap';
 
import Styles from './users.module.css';

function EditGroupModal(props) {
    const [showModal, setShowModal] = useState(true);
    const [name, setName] = useState('');
    const [disableName, setDisableName] = useState(true);
    const [description, setDescription] = useState('');
    const [onsiteOnly, setOnsiteOnly] = useState(false);
function save(e) {
    e.preventDefault();



    var bod = {
        customerOrganizationID: props.user.customerOrganizationID,
        name: name,
        description: description,
        onsiteOnly: onsiteOnly,
        shows: null,
        users: null
    }
    fetch(global.config.settings.wsURL + 'admin/SaveGroup',
        {
            method: "POST",
            headers: { 
                'Content-Type': 'application/json',
                authorization: props.user.token,
                showCode: props.showCode, 
                
            },
            body: JSON.stringify(bod)
        }
    )
        .then(function (response) {
            if (!response.ok) {
                console.log("Status " + response.status);

                return null;
            }
            return response.json();
        })
        .then(data => { 
            if (data) {
              
                if (data.status != 'success') {
                    alert('There was an error saving these users.')
                } else {
                    props.setEditGroupID(null);
                }
            }
        }).catch(error => {
            props.setGlobalError("There was a problem accessing the Internet. Please check your connection and try again.");
            props.setEditGroupID(null);
            console.error('Error:', error);
          });
}

const handleClose = () => {
    setShowModal(false); 
    props.setEditGroupID(null)
}


useEffect(() => {
    if (props.editGroupID == 'oqiroqofjeoqfejio') {
        setDisableName(false);
    } else {
        props.userGroupList.map((group) => {
            if (group.name === props.editGroupID) {
                setName(group.name);
                setDescription(group.description);
                setOnsiteOnly(group.onsiteOnly);
            }
        })
    }
}, [])


    return(
        <Modal   onHide={handleClose} show={showModal}> 
        <Modal.Header closeButton>
            Group Edit 
        </Modal.Header>
            
        <Modal.Body style={{padding: "0px"}}  >
            <Container>
                <Row>
                    <Col>
                        <Form.Group controlId={"name"}>
                            <Form.Label>Name</Form.Label>
                            <Form.Control disabled={disableName}  onChange={(e)=>setName(e.target.value)}
                                value={name} type="Text"    /> 
                            </Form.Group>
                    </Col>
                </Row>
                <Row><Col>  
                <Form.Group controlId={"description"} > 
                    <Form.Label>Description</Form.Label>
                                <Form.Control
                                 value={description} 
                                onChange={(e)=>setDescription(e.target.value)}
                                as="textarea"   rows={4} />
                            </Form.Group>
                </Col></Row>
<Row>
    <Col>
    <Form.Group controlId={"Onsite Only"} >
                <Form.Check type="checkbox"   checked={onsiteOnly}
                    onChange={((e) =>  setOnsiteOnly(e.target.checked) 
                        )
                    }
                label={"Onsite Only - limits access so that a user can only print and edit attendees"} />
            </Form.Group>    
    </Col>
</Row>
                </Container>
            </Modal.Body>
            <Modal.Footer style={{pading: "0px"}}>
        <Button
                    onClick={(e) => save(e) }
                >Save</Button>
             <Button
                    onClick={() => handleClose() }
                >Close</Button>
        </Modal.Footer>
        </Modal>
    )  
}

export default EditGroupModal;