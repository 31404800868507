import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import {
  Button,
  Alert,
  Container,
  Row,
  Col,
  Modal,
  Tabs,
  Tab,
  Form,
} from "react-bootstrap";
import Styles from "./importAttendees.module.css";

function ImportAttendees(props) {
  const handleClose = () => props.setShowModal(false);
  const [isLoading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [fileMessage, setFileMessage] = useState("Select an excel file");
  const [uploadSuccess, setUploadSuccess] = useState("");
  const [customDisplayFields, setCustomDisplayFields] = useState([]);
  const [displayFields, setDisplayFields] = useState(null);
  const [registrationTypes, setRegistrationTypes] = useState([]);
  const [uploadError, setUploadError] = useState("");
  const [isInvalid, setIsInvalid] = useState(false);
  const [searchedRegistrationTypes, setSearchedRegistrationTypes] =
    useState(false);
  const [searchedDisplayFields, setSearchedDisplayFields] = useState(false);
  const [searchedCustomDisplayFields, setSearchedCustomDisplayFields] =
    useState(false);
  const fileInputRef = useRef(null);
  const changeHandler = (event) => {
    const name = event.target.files[0].name.toLowerCase();
    if (name.includes(".xlsx") === false) {
      setSelectedFile(null);
      setFileMessage(null);
      setIsFilePicked(false);

      alert(
        "Invalid file type.  Imported files must be in the Excel (xlsx) format."
      );
      return;
    }
    setSelectedFile(event.target.files[0]);
    setFileMessage(event.target.files[0].name);
    setIsFilePicked(true);
  };

  const submitHandler = (e) => {
    e.preventDefault(); //prevent the form from submitting

    const formData = new FormData();

    formData.append("File", selectedFile);
    setLoading(true);
    fetch(global.config.settings.wsURL + "admin/ImportAttendees", {
      method: "POST",
      headers: {
        authorization: props.user.token,
        showCode: props.showCode,
      },
      body: formData,
    })
      .then(function (response) {
        fileInputRef.current.value = "";
        setSelectedFile(null);
        setLoading(false);
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          alert(data.message);
          if (data.status === "success") {
            setUploadSuccess("Records were mported successfully.");
            setUploadError("");
            setIsInvalid(false);
          }
          if (data.status === "error") {
            setUploadSuccess("");
            let str = data.message.replace(/(\r\n|\n|\r)/g, "<br />");
            setUploadError(str);
            setIsInvalid(true);
          }
          setFileMessage("Select an excel file");
          setSelectedFile(null);

          setIsFilePicked(false);

          setFileMessage("Select an excel file");
        }
      });
  };
  function getRegistrationType() {
    //Get sessions
    fetch(global.config.settings.wsURL + "admin/GetRegistrationTypes", {
      method: "GET",
      headers: {
        accept: "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          setRegistrationTypes(data);
          setSearchedRegistrationTypes(true);
        }
      });
  }
  useEffect(() => {
    fetch(global.config.settings.wsURL + "admin/GetCustomFields", {
      method: "GET",
      headers: {
        accept: "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          setCustomDisplayFields(data);
          setSearchedCustomDisplayFields(true);
          console.log(data);
        }
      });
  }, []);

  useEffect(() => {
    getRegistrationType();
  }, []);
  function getTemplate(e) {
    e.preventDefault();

    fetch(global.config.settings.wsURL + "Admin/GetAttendeeImportTemplate", {
      method: "GET",
      headers: {
        accept: "*/*",

        authorization: props.user.token,
        showCode: props.showCode,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `AttendeeImportTemplate.xlsx`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        props.setGlobalError(
          "There was a problem accessing the Internet. Please check your connection and try again."
        );

        console.error("Error:", error);
      });
  }

  useEffect(() => {
    fetch(global.config.settings.wsURL + "admin/GetShowDisplayFields", {
      method: "GET",
      headers: {
        accept: "application/json",
        authorization: props.user.token,
        showCode: props.showCode,
      },
    })
      .then(function (response) {
        if (!response.ok) {
          console.log("Status " + response.status);

          return null;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          setDisplayFields(data);
          setSearchedDisplayFields(true);
        }
      })
      .catch((error) => {
        props.setGlobalError(
          "There was a problem accessing the Internet. Please check your connection and try again."
        );

        console.error("Error:", error);
      });
  }, []);

  return (
    <Modal
      centered
      contentClassName={Styles.modal}
      dialogClassName={Styles.modal}
      onHide={handleClose}
      show={props.showModal}
    >
      <Modal.Header>
        <h3>Attendee Import</h3>
      </Modal.Header>
      {searchedRegistrationTypes == true &&
        searchedDisplayFields == true &&
        searchedCustomDisplayFields == true && (
          <>
            <Modal.Body className={Styles.modalBodyStyle}>
              <Container>
                <h2>Import instructions</h2>
                {(displayFields != null || customDisplayFields.length > 0) &&
                  registrationTypes.length > 0 && (
                    <>
                      <h2>
                        When pasting values into the excel import file, paste
                        using 'Paste Special and then Values' as customer fields
                        can sometimes contain unexpected characters or values.
                      </h2>
                      <p>Begin by downloading the excel template.</p>
                      <p>Be sure to save the file when downloading.</p>
                      <p>
                        <Button
                          onClick={(e) => getTemplate(e)}
                          variant="primary"
                          size="md"
                        >
                          Download template
                        </Button>
                      </p>
                      <p>
                        For each attendee, create a row in the template. Make
                        sure that you have set up the fields and registration
                        types for your event in the Event Setup section.
                      </p>
                      <p>
                        Once the spreadsheet has been completed, you may import
                        the sessions by uploading the spreadsheet using the
                        'upload' button below.
                        <br />
                        The system will alert you to any errors.
                      </p>
                      <Container
                        style={{
                          marginLeft: "0px",
                          marginRight: "0px",
                          paddingLeft: "0px",
                        }}
                      >
                        <Row>
                          <Col xs={12} md={4}>
                            <Form>
                              <Form.Control
                                type="file"
                                ref={fileInputRef}
                                isInvalid={isInvalid}
                                onChange={changeHandler}
                                isValidd={!isInvalid}
                              />
                              <Form.Label data-browse="Browse">
                                {fileMessage}
                              </Form.Label>
                              <Form.Control.Feedback type="valid">
                                {uploadSuccess}
                              </Form.Control.Feedback>
                              <Form.Control.Feedback
                                dangerouslySetInnerHTML={{
                                  __html: uploadError,
                                }}
                                type="invalid"
                              />
                              <Form.Group>
                                {selectedFile && (
                                  <>
                                    <Button
                                      variant="info"
                                      style={{
                                        marginTop: "5px",
                                        marginBottom: "5px",
                                      }}
                                      disabled={isLoading}
                                      onClick={(e) =>
                                        !isLoading ? submitHandler(e) : null
                                      }
                                    >
                                      {isLoading
                                        ? "Uploading attendees....."
                                        : "Upload"}
                                    </Button>
                                    <p>File name: {selectedFile.name}</p>
                                    <p>File type: {selectedFile.type}</p>
                                    <p>Size in bytes: {selectedFile.size}</p>
                                  </>
                                )}
                              </Form.Group>
                            </Form>
                          </Col>
                          <Col></Col>
                          <Col></Col>
                        </Row>
                      </Container>
                      Instructions for each field are listed below:
                      <ul>
                        {displayFields.firstName == 1 && (
                          <li>First Name - Up to 250 characters</li>
                        )}
                        {displayFields.lastName == 1 && (
                          <li>Last Name - Up to 250 characters</li>
                        )}
                        {displayFields.email == 1 && (
                          <li>Email - Up to 250 characters</li>
                        )}
                        {displayFields.companyName == 1 && (
                          <li>Company Name - Up to 250 characters</li>
                        )}
                        {displayFields.companyAddress1 == 1 && (
                          <li>Company Address 1 - Up to 250 characters</li>
                        )}
                        {displayFields.companyAddress2 == 1 && (
                          <li>Company Address 2 - Up to 250 characters</li>
                        )}
                        {displayFields.companyCity == 1 && (
                          <li>Company City - Up to 250 characters</li>
                        )}
                        {displayFields.companyStateProvince == 1 && (
                          <li>
                            Company State/Province - Must be the 2 character ISO
                            abbreviation(i.e. VA for Virginia)
                          </li>
                        )}
                        {displayFields.companyPostalCode == 1 && (
                          <li>
                            Company Postal Code - Must be less than 25
                            characters
                          </li>
                        )}
                        {displayFields.companyCountry == 1 && (
                          <li>
                            Company Country - Must be the 2 character ISO
                            abbreviation(i.e. US for United States)
                          </li>
                        )}
                        {displayFields.address1 == 1 && (
                          <li>Address 1 - Up to 250 characters</li>
                        )}
                        {displayFields.address2 == 1 && (
                          <li>Address 2 - Up to 250 characters</li>
                        )}
                        {displayFields.city == 1 && (
                          <li>City - Up to 250 characters</li>
                        )}
                        {displayFields.stateProvince == 1 && (
                          <li>
                            State/Province - Must be the 2 character ISO
                            abbreviation(i.e. VA for Virginia)
                          </li>
                        )}
                        {displayFields.postalCode == 1 && (
                          <li>Postal Code - Must be less than 25 characters</li>
                        )}
                        {displayFields.country == 1 && (
                          <li>
                            Country - Must be the 2 character ISO
                            abbreviation(i.e. US for United States)
                          </li>
                        )}
                        <li>
                          Status - Use 'A' for those that are attendending and
                          have no issues. Use 'P' for attendees that have issues
                          with their registration (for instance an outstanding
                          balance). For cancelled attendees, 'CX' should be
                          used.{" "}
                        </li>
                        <li>
                          Badge type - possible values:
                          <ul>
                            {registrationTypes.map((registrationType) => {
                              return (
                                <li key={registrationType.code}>
                                  {registrationType.code}
                                </li>
                              );
                            })}
                          </ul>
                        </li>

                        <li>
                          Notes - up to 500 characters. Most often used to
                          identify the reason that an attendee's status has been
                          set to a problem record.
                        </li>
                        {customDisplayFields.map((field) => {
                          if (
                            field.description.toUpperCase() != "&NBSP;" &&
                            field.customFieldType == 0
                          )
                            return (
                              <li key={field.code}>
                                {field.description} - up to 500 characters long
                              </li>
                            );

                          if (field.customFieldType == 3)
                            return (
                              <li key={field.code}>
                                {field.description} - possible values:
                                <ul>
                                  {field.comboValues.map((comboValue) => {
                                    return (
                                      <li key={comboValue.ansCode}>
                                        {comboValue.ansCode}{" "}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </li>
                            );
                        })}
                      </ul>
                    </>
                  )}

                {displayFields == null && customDisplayFields.length === 0 && (
                  <Alert variant={"danger"}>
                    <h4>
                      Currently, you do not have any fields defined for this
                      event.
                    </h4>
                    <p>
                      In order to import attendees, there must be attendee
                      fields defined.
                    </p>
                    <p>
                      To add fields, click the 'Event Setup' button on your
                      dashboard and then click the 'Standard Fields' or the
                      'Custom Fields' tab.
                    </p>
                  </Alert>
                )}

                {registrationTypes.length === 0 && (
                  <Alert variant={"danger"}>
                    <h4>
                      Currently, you do not have any badge types defined for
                      this event.
                    </h4>
                    <p>
                      In order to import attendees, badge types must be added.
                    </p>
                    <p>
                      To add a badge type, click the 'Event Setup' button on
                      your dashboard and then click the 'Badge Type' tab.
                    </p>
                  </Alert>
                )}
              </Container>
            </Modal.Body>
          </>
        )}
      <Modal.Footer>
        <Button
          onClick={() => {
            props.setShowModal(false);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default React.memo(ImportAttendees);
